<template>
  <div class="d-flex flex-column">
    <div>
      <div
        :style="{ 'background-color': validationBadgeType.color }"
        class="badge badge-success text-white mr-2"
      >
        <div
          class="px-2 text-uppercase font-weight-bold"
          style="padding-block: 1px; font-size: 1em;"
        >
          {{ validationBadgeType.name }}
        </div>
      </div>
    </div>

    <div
      class="title-style row mx-0 my-2"
      style="align-items: center"
    >
      <div>
        <i class="fas fa-circle-info" />
        {{ $t('general.details') }}
      </div>
    </div>

    <div
      class="mb-1"
      style="font-size: 1em"
    >
      <div
        v-for="(value, prop) in ekpProps"
        :key="prop"
      >
        <div v-if="value">
          <span class="props-text">
            {{ prop }} :
          </span>
          <span>
            {{ value }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="validationErrors.length">
      <hr>
      <div
        class="justify-content-between title-style row mx-0"
        style="align-items: center"
      >
        <div>
          <i class="fas fa-triangle-exclamation" />
          {{ $t('validation.validationErrors') }}
        </div>
        <div
          class="dropdown-style"
          style="font-size: 1.5em; opacity: 0.7"
          @click="openValidation = !openValidation"
        >
          <i
            :class="openValidation ? 'ion ion-ios-arrow-up' : 'ion ion-ios-arrow-down'"
          />
        </div>
      </div>
      <div
        v-if="openValidation"
        class="warning-box px-3 py-2"
      >
        <div
          v-for="error in validationErrors"
          :key="error"
        >
          <div
            class="d-flex row px-2"
          >
            <li
              class="col-1 px-0"
              style="text-align: right"
            />
            <div
              class="col-11 px-0"
              style="font-size: 0.9em"
            >
              {{ error }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="checklist">
      <hr>
      <div
        class="justify-content-between title-style row mx-0"
        style="align-items: center"
      >
        <div>
          <i class="fas fa-cog" />
          {{ $t('form.backLabel') }}
        </div>
        <div
          class="dropdown-style"
          style="font-size: 1.2em; opacity: 0.7"
          @click="openForm"
        >
          <i class="ion ion-md-create" />
        </div>
      </div>
    </div>
    <div
      class="mb-1 mt-2 checklist"
    >
      <BFormRadioGroup
        v-model="selectedLabel"
        button-variant="outline-success"
        style="display: inline-grid"
      >
        <template v-for="label in labelProps">
          <BFormRadio
            :key="label.componentMsn"
            :value="label.componentMsn"
            class="mt-3"
            disabled
          >
            <span>
              <div class="checkbox-text">
                {{ label.componentMsn }}
              </div>
              <div class="checkbox-info-text">
                {{ label.description }}
              </div>
            </span>
          </BFormRadio>
        </template>
      </BFormRadioGroup>
      <div
        class="my-3"
      >
        <BFormCheckbox
          v-model="preprintedUfi"
          disabled
        >
          {{ $t('form.preprintedUfi') }}
        </BFormCheckbox>
      </div>
    </div>
    <LModal
      :show.sync="form"
    >
      <div class="px-3 py-2">
        <div class="modal-title">
          <span>
            <i class="fas fa-cog" />
            {{ $t('form.backLabel') }}
          </span>
        </div>
        <div
          class="mb-1 mt-2"
        >
          <BFormRadioGroup
            v-model="localSelectedLabel"
            button-variant="outline-success"
            style="display: inline-grid"
          >
            <template v-for="label in labelProps">
              <BFormRadio
                :key="label.componentMsn"
                :value="label.componentMsn"
                class="mt-3"
              >
                <span>
                  <div class="checkbox-text">
                    {{ label.componentMsn }}
                  </div>
                  <div class="checkbox-info-text">
                    {{ label.description }}
                  </div>
                </span>
              </BFormRadio>
            </template>
          </BFormRadioGroup>
          <div
            class="my-3"
          >
            <BFormCheckbox
              v-model="localPreprintedUfi"
            >
              {{ $t('form.preprintedUfi') }}
            </BFormCheckbox>
          </div>
        </div>
        <div class="pt-2 text-center">
          <button
            class="actions-button btn btn-secondary btn-sm mx-2"
            type="button"
            @click.prevent="closeForm"
          >
            <i
              class="ion ion-md-close pr-2"
            />
            {{ $t('general.cancel') }}
          </button>

          <button
            class="actions-button btn btn-primary btn-sm"
            type="submit"
            :disabled="pending"
            @click="sendSetBomPropertiesRequest"
          >
            <BSpinner
              v-if="pending"
              variant="light"
              small
              class="mr-1"
            />
            <i
              v-else
              class="ion ion-md-checkmark pr-2"
            />
            {{ $t('general.save') }}
          </button>
        </div>
      </div>
    </LModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    selectedEkp: Object,
  },
  data: () => ({
    form: false,
    selectedLabel: null,
    pending: false,
    preprintedUfi: null,
    openValidation: false,
    validationErrors: [],
    localSelectedLabel: null,
    localPreprintedUfi: null,
  }),
  computed: {
    ...mapGetters([
      'validBadges',
    ]),
    ekpProps() {
      return this.selectedEkp?.bomOverview || [];
    },
    labelProps() {
      return this.selectedEkp?.labelOptions || [];
    },
    validationBadgeType() {
      const isValid = this.selectedEkp?.bomOverview?.isValid ? 'VALID' : 'INVALID';

      return this.validBadges.find(badge => badge.type === isValid);
    },
  },
  watch: {
    ekpProps() {
      this.selectedLabel = this.labelProps.find(p => p.isPrintingLabel === true)?.componentMsn;
      this.$emit('msnCode', this.selectedLabel || '');
      this.$emit('bomNumber', this.ekpProps?.bomNumber || '');
    },
    selectedEkp() {
      this.preprintedUfi = this.selectedEkp.isPreprintedUfiPresent;
      this.checkEkpValidation();
    },
  },
  methods: {
    ...mapActions([
      'setBomProperties',
      'validateBom',
    ]),
    openForm() {
      this.form = true;
      this.localSelectedLabel = this.selectedLabel;
      this.localPreprintedUfi = this.preprintedUfi;
    },
    closeForm() {
      this.form = false;
      this.localSelectedLabel = null;
      this.localPreprintedUfi = null;
    },
    checkEkpValidation() {
      this.validateBom({
        params: {
          bomNumber: this.ekpProps?.bomNumber,
        },
      })
        .then(({ data }) => {
          this.validationErrors = data.validationErrors || [];
        });
    },
    sendSetBomPropertiesRequest() {
      this.pending = true;
      this.setBomProperties({
        params: {
          bomNumber: this.ekpProps?.bomNumber,
        },
        data: {
          backLabelComponentMsn: this.localSelectedLabel,
          isPreprintedUfiPresent: this.localPreprintedUfi,
        },
      })
        .then(() => {
          this.form = false;
          this.$emit('msnCode', this.localSelectedLabel);
          this.$bvToast.toast(this.$t('form.labelUpdated'), {
            title: this.$t('general.success'),
            variant: 'success',
            autoHideDelay: 2000,
          });
          this.selectedLabel = this.localSelectedLabel;
          this.preprintedUfi = this.localPreprintedUfi;
        })
        .catch(({ response: { data } }) => {
          this.$bvToast.toast(data.detail || this.$t('general.error'), {
            title: this.$t('general.error'),
            variant: 'danger',
            autoHideDelay: 4000,
          });
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.selectedLabel = this.labelProps.find(p => p.isPrintingLabel === true)?.componentMsn;
    this.preprintedUfi = this.selectedEkp.isPreprintedUfiPresent;
    this.$emit('bomNumber', this.ekpProps?.bomNumber || '');
    this.$emit('msnCode', this.selectedLabel);
    this.checkEkpValidation();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .edit {
    cursor: pointer;
    padding-left: 15px;
    &:hover {
      text-shadow: 0 0 5px rgba(#333, 0.6);
    }
  }

  .warning-box {
    padding-block: 5px;
    margin: 10px 0px;
    border-radius: 7px;
    background-color: #FFEFEE;
    color: #D70819;
    flex-flow: row;
  }

  .dropdown-style {
    align-self: center;
    font-size: 0.65em;
    &:hover {
      cursor: pointer;
    }
  }

  .modal-title {
    font-size: 1.5em;
    font-weight: 500;
  }

  .title-style {
    font-size: 1.35em;
    font-weight: 500;
  }

  .props-text {
    text-transform: capitalize;
    opacity: 0.9;
    font-weight: 600 !important;
  }

  .checklist {
    opacity: 0.7;
  }

  .checkbox-text {
    font-size: 1em;
    font-weight: 475 !important;
  }

  .checkbox-info-text {
    font-size: 0.6em;
    font-weight: 500 !important;
  }

</style>
