<template>
  <div class="layout d-flex flex-column">
    <Header
      system="goodin"
      :auth-url="authUrl"
      :hub-url="hubUrl"
    />
    <div class="d-flex flex-grow-1">
      <Sidebar :page="$route.path" />
      <div
        class="flex-grow-1 container-fluid px-4"
        :style="'background-color: #f4f4f4'"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import getOidcSettings from '@/api/oidc';
import Header from '@/layout/navbar/Header';
import Sidebar from '@/layout/Sidebar';

export default {
  data: () => ({
    authUrl: `${getOidcSettings().authority}/api`,
  }),
  components: {
    Header,
    Sidebar,
  },
  computed: {
    ...mapGetters(['hubUrl']),
  },
  methods: {
    ...mapActions([]),
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>

.layout {
  min-height: 100vh;
}

</style>
