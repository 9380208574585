<template>
  <div
    class="history-box"
  >
    <div
      class="order-history"
      :class="openHistory ? 'open-details' : 'none-details'"
    >
      <div class="d-flex justify-content-between">
        <div
          class="col-3"
          style="align-self: center"
        >
          <div
            :style="{ 'background-color': orderType.color }"
            class="badge badge-success text-white mr-2"
          >
            <div
              class="px-2 text-uppercase badge-style"
            >
              {{ orderType.name }}
            </div>
          </div>
        </div>

        <div
          class="col-4 pl-0"
          style="align-self: center"
        >
          <div style="font-size: 16px">
            {{ time }}
          </div>
        </div>
        <div
          class="col-5 d-flex justify-content-between row"
          style="align-self: center"
        >
          <div
            class="row mb-0 mr-2"
          >
            <i
              class="fas pr-2"
              :class="{
                'fa-user-circle': userDetails?.firstName || userDetails?.lastName,
                'fa-dot-circle': !userDetails?.firstName && !userDetails?.lastName,
              }"
              style="font-size: 1.75em; opacity: 0.25"
            />
            <div
              style="font-size: 16px; align-self: center"
            >
              <span v-if="userDetails?.firstName || userDetails?.lastName">
                {{ userDetails?.firstName }} {{ userDetails?.lastName }}
              </span>
              <span v-else>
                Vision System
              </span>
            </div>
          </div>
          <div
            v-if="phrasedFields.length || comment"
          >
            <div
              class="history-style pr-3"
              style="font-size: 1.5em; opacity: 0.40"
              @click="openHistory = !openHistory"
            >
              <i
                :class="openHistory ? 'ion ion-ios-arrow-up' : 'ion ion-ios-arrow-down'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="openHistory && comment"
      class="d-flex py-3"
      :class="phrasedFields.length ? 'comment' : 'comment-with-ending-line'"
      style="background-color: #FFFFFF"
    >
      <div
        class="col-3 text-style"
      >
        {{ $t('report.comment') }}
      </div>
      <div
        class="col-9 comment-style"
      >
        "{{ comment }}"
      </div>
    </div>

    <div
      v-if="openHistory && phrasedFields.length"
      class="table-style"
      style="background-color: #F6F6F6"
    >
      <table
        class="table"
      >
        <thead>
          <tr>
            <div
              class="row px-2"
            >
              <th
                class="col-3 text-style pl-1"
              >
                {{ $t('report.key') }}
              </th>
              <th
                class="col-4 text-style pr-2"
              >
                {{ $t('report.oldValue') }}
              </th>
              <th
                class="col-5 text-style pl-3"
              >
                {{ $t('report.newValue') }}
              </th>
            </div>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(prop, key) in phrasedFields"
            :key="key"
          >
            <div
              class="table-row row px-2"
            >
              <td
                class="col-3 pl-1"
              >
                <div class="borderRight">
                  {{ prop.FieldName }}
                </div>
              </td>
              <td
                class="col-4 pr-2"
                style="display: inline-flex"
              >
                <div
                  class="px-2 flex-grow-1"
                  :class="{
                    unchanged: isValueUnchanged(prop),
                    'old-value': !!prop.OldValue,
                    'no-value': !prop.OldValue,
                  }"
                >
                  {{ prop.OldValue }}
                </div>
                <div>
                  <i
                    class="fas fa-arrow-right pl-3"
                    style="font-size: 0.75em"
                  />
                </div>
              </td>
              <td
                class="col-5 pl-3"
                style="display: inline-flex"
              >
                <div
                  class="px-2 flex-grow-1"
                  :class="{
                    unchanged: isValueUnchanged(prop),
                    'new-value': !!prop.NewValue,
                    'no-value': !prop.NewValue,
                  }"
                >
                  {{ prop.NewValue }}
                </div>
              </td>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    action: String,
    comment: String,
    issuerId: String,
    issuerName: String,
    time: String,
    updatedFields: String,
    userDetails: Object,
  },
  data: () => ({
    openHistory: false,
  }),
  computed: {
    ...mapGetters([
      'orderActions',
      'actionUnknown',
    ]),
    phrasedFields() {
      const fields = JSON.parse(this.updatedFields);
      return fields;
    },
    orderType() {
      return this.orderActions.find((type) => type.action === this.action) || this.actionUnknown[0];
    },
  },
  methods: {
    isValueUnchanged(prop) {
      return (prop.NewValue || '').toLowerCase() === (prop.OldValue || '').toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>

  .text-style {
    font-weight: 550;
    font-size: 14px;
  }

  .comment-style {
    opacity: 0.6;
    font-style: italic;
    font-size: 14px;
  }

  .order-history {
    padding: 10px;
    line-height: 0.7;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 4, 0.1);
  }

  .comment {
    padding: 10px;
    line-height: 0.7;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 4, 0.1);
  }

  .comment-with-ending-line {
    padding: 10px;
    line-height: 0.7;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 4, 0.1);
  }

  .history-style {
    align-self: center;
    font-size: 0.65em;

    &:hover {
      cursor: pointer;
    }
  }

  .open-details {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 8px;
  }

  .none-details {
     border-radius: 10px;
     margin-block: 8px;
  }

  .history-box {
    overflow-y: auto;
    padding-inline: 5px;
  }

  .table-row {
    font-size: 14px;
  }

  .table-style {
    padding-inline: 30px;
    padding-bottom: 10px;
    margin-bottom: 8px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 4, 0.1);
  }

  .borderRight {
    border-right: 1px solid #dee2e6;
  }

  .table th, .table td {
    padding: 0.4rem;
    vertical-align: middle;
  }

  .table th {
    border: none,
  }

  .table {
    margin-bottom: 0em;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  .no-value {
    background-color: rgba(gray, 0.3);
    &.unchanged {
      background-color: rgba(#333, 0.2);
    }
  }

  .old-value {
    background-color: rgba(red, 0.2);
    &.unchanged {
      background-color: rgba(#333, 0.2);
    }
  }

  .new-value {
    background-color: rgba(green, 0.1);
    &.unchanged {
      background-color: rgba(#333, 0.2);
    }
  }

</style>
