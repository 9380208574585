import Vue from 'vue';
import lang from '@/lang';
import {
  GOODIN_PLANNING,
  GOODIN_PRODUCTION,
  GOODIN_ARCHIVE,
  GOODIN_IMPORT_LOG,
} from '@/utils/permissionsDictionary';

export default {
  state: {
    mobile: false,
    hubUrl: null,
    applicationVersion: null,
  },
  getters: {
    hubUrl: s => s.hubUrl,
    applicationVersion: s => s.applicationVersion,
    sidebar: () => [
      {
        key: 'planning',
        route: '/',
        icon: 'fas fa-clipboard-list',
        text: lang.t('sidebar.planning'),
        permittedFor: [GOODIN_PLANNING],
      },
      {
        key: 'production',
        route: '/production',
        icon: 'fas fa-business-time',
        text: lang.t('sidebar.production'),
        permittedFor: [GOODIN_PRODUCTION],
      },
      {
        key: 'archive',
        route: '/archive',
        icon: 'fas fa-archive',
        text: lang.t('sidebar.archive'),
        permittedFor: [GOODIN_ARCHIVE],
      },
      {
        key: 'importLog',
        route: '/importLog',
        icon: 'fas fa-file-import',
        text: lang.t('imports.importLog'),
        permittedFor: [GOODIN_IMPORT_LOG],
      },
    ],
    permittedRoutes: (s, g) => g.sidebar.filter(x => !x.permittedFor
      || x.permittedFor.some(permission => g.canAny(permission))),
  },
  mutations: {
    setMobile(s, v) {
      s.mobile = v;
    },
    setConfig(s, config) {
      Object.keys(config).forEach(key => {
        Vue.set(s, key, config[key]);
      });
    },
  },
};
