<template>
  <Apexcharts
    :options="options"
    :series="series"
  />
</template>

<script>
import Apexcharts from 'vue-apexcharts';

export default {
  props: {
    series: Array,
    labels: Array,
  },
  data() {
    return {
      key: 1,
      fullView: true,
    };
  },
  components: {
    Apexcharts,
  },
  computed: {
    dataLabelsEnabled() {
      return this.labels.length < 15;
    },
    options() {
      return {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          zoom: {
            enabled: true,
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        }],
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: this.dataLabelsEnabled,
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 3,
            color: '#000',
            opacity: 0.9,
          },
        },
        xaxis: {
          categories: this.labels,
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: false,
            hideOverlappingLabels: true,
          },
        },
        legend: {
          position: 'top',
          offsetX: -100,
        },
        colors: this.series?.map(s => s.color),
        fill: {
          colors: this.series?.map(s => s.color),
          opacity: 1,
        },
      };
    },
  },
};
</script>
