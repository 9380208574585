import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '@/lang';
import bomProps from './bomProps';
import config from './config';
import display from './display';
import imports from './imports';
import language from './language';
import orders from './orders';
import permissions from './permissions';
import plantProps from './plantProps';
import qualityIssues from './qualityIssues';
import reports from './reports';
import ufi from './ufi';
import time from './time';
import userSettings from './userSettings';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    bomProps,
    config,
    display,
    imports,
    language: language(i18n),
    orders,
    permissions,
    plantProps,
    qualityIssues,
    reports,
    ufi,
    time,
    userSettings,
  },
});
