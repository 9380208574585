<template>
  <div class="d-flex app-menu">
    <LanguageSelect
      class="app-node"
    />

    <InfoSelect
      class="app-node"
    />

    <Tippy
      ref="popup"
      trigger="click"
      theme="light"
      interactive
      arrow
    >
      <template #trigger>
        <div class="app-node">
          <div
            id="userDropdown"
            class="icon"
          >
            <i class="ion ion-ios-contact" />
          </div>
        </div>
      </template>

      <div style="margin: -0.3125rem -0.5625rem">
        <div class="dropdown-row bg-ilabo text-white user-row">
          <div class="icon">
            <i class="fas fa-id-badge pr-2" />
          </div>
          {{ oidcUser ? oidcUser.name : $t('users.name') }}
        </div>
        <div
          v-for="el in dropdown"
          :key="el.text"
          class="dropdown-row clickable"
          @click="el.select(); $refs.popup.tippy().hide()"
        >
          <div class="icon">
            <i :class="`${el.icon} pr-2`" />
          </div>
          <div class="flex-grow-1 text-left">
            {{ el.text }}
          </div>
        </div>
        <div
          class="text-center pt-0 pb-1 text-secondary"
          style="border-top: 1px solid rgba(100,100,100,0.5)"
        >
          <span style="font-size: 11px; text-align: center">
            {{ applicationVersion || '?' }}
          </span>
        </div>
      </div>
    </Tippy>
    <LModal
      :show.sync="preferencesForm"
    >
      <UserPreferences
        :user-id="oidcUser.sub"
        @close="preferencesForm = false"
      />
    </LModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserPreferences from '../../components/settings/users/UserPreferences';
import LanguageSelect from './LanguageSelect';
import InfoSelect from './InfoSelect';

export default {
  data: () => ({
    preferencesForm: false,
  }),
  components: {
    LanguageSelect,
    InfoSelect,
    UserPreferences,
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    ...mapGetters(['applicationVersion']),
    dropdown() {
      return [
        {
          icon: 'fas fa-star',
          text: this.$t('users.preferences'),
          select: () => { this.preferencesForm = true; },
        },
        {
          icon: 'fas fa-power-off',
          text: this.$t('auth.signOut'),
          select: () => this.signOutOidc(),
        },
      ];
    },
  },
  methods: {
    ...mapActions('oidcStore', ['signOutOidc']),
  },
};
</script>

<style lang="scss" scoped>
  @import "./dropdowns/style.scss";

  .app-menu::v-deep .dropdown-toggle {
    padding: 0;
  }

  .app-menu::v-deep *:focus {
    outline: none;
  }

  .user-row {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .app-menu {
    padding-right: 10px;
    padding-top: 2px;
    position: relative;

    .app-node {
      color: white;
      padding: 7px 0 0;
      margin-right: 5px;
      text-align: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      transition: transform 300ms;
      cursor: pointer;

      .icon {
        font-size: 21px;
        line-height: 10px;
      }

      &:hover {
        background-color: rgba(100, 100, 100, 0.2);
      }
    }
  }
</style>
