<template>
  <div class="px-5 py-2">
    <div
      style="font-size: 1.6em; font-weight: 550"
      class="mb-0"
    >
      {{ $t('report.report') }}
    </div>
    <div class="py-2">
      <WorkOrder
        :extended="true"
        :order="order"
        :report="productionReport"
      />
    </div>
    <hr>
    <div class="titles">
      <i class="fas fa-signal" />
      {{ $t('report.errorStatistics') }}
    </div>
    <div class="pr-2">
      <ErrorStats
        :report-data="errorsStats"
      />
    </div>
    <hr>
    <div class="titles">
      <i class="fa fa-exclamation-circle pr-1" />
      {{ $t('report.errorsInTime') }}
    </div>
    <div class="py-2">
      <ErrorsInTime
        :report-data="errorsStats"
        :errors-list="errorsByTime"
      />
    </div>
    <hr>
    <div class="titles">
      <i class="far fa-clipboard" />
      {{ $t('report.productionRun') }}
    </div>
    <div class="py-2">
      <BarChart
        :list="barData"
        :hide-labels="false"
        style="width: 100%; margin-top: 2px; height: 20px"
        :tooltip-fn="getTooltip"
      />
    </div>
    <hr>
    <div class="titles">
      <i class="fas fa-history pr-1" />
      {{ $t('report.history') }}
    </div>
    <div class="py-2">
      <OrderHistoryReport
        v-for="o in orderHistoryData"
        :key="o.key"
        v-bind="o"
      />
      <div
        v-if="orderHistoryData.length === 0"
        style="min-height: 25vh"
      >
        <p
          class="text-center info"
        >
          {{ $t('form.noData') }}
        </p>
      </div>
    </div>

    <hr>

    <div class="titles">
      <i class="fas fa-image" />
      {{ $t('report.incidents') }}
    </div>
    <div class="py-2">
      <PicturesReport
        :errors-list="qualityErrors"
        :samples-list="qualitySamples"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import CalcDuration from '@/utils/calcDuration';
import WorkOrder from '@/components/order/WorkOrder';
import BarChart from '@/components/charts/BarChart';
import ErrorStats from './ErrorStats';
import PicturesReport from './PicturesReport';
import OrderHistoryReport from './OrderHistoryReport';
import ErrorsInTime from './ErrorsInTime';

export default {
  props: {
    orderHistory: Array,
    productionOrder: Object,
    productionReport: Object,
    order: Object,
  },
  data: () => ({
    qualityErrors: null,
    qualitySamples: null,
    orderInProgress: null,
    errorsStats: null,
    errorsByTime: null,
  }),
  components: {
    WorkOrder,
    ErrorStats,
    BarChart,
    PicturesReport,
    ErrorsInTime,
    OrderHistoryReport,
  },
  computed: {
    ...mapGetters([
      'settings',
    ]),
    orderHistoryData() {
      if (!this.orderHistory) return [];

      return this.orderHistory.map(order => ({
        ...order,
        time: this.showRightTime(order.timestamp)?.format('DD/MM/YYYY HH:mm'),
      })).sort((a, b) => (a.timestamp).localeCompare(b.timestamp));
    },
    barData() {
      return [
        {
          color: '#82AC48',
          value: (this.productionDuration / this.orderDuration) * 100,
          time: this.productionDuration,
          label: this.$t('order.work'),
        },
        {
          color: '#828282',
          value: (this.holdDuraton / this.orderDuration) * 100,
          time: this.holdDuraton,
          label: this.$t('order.hold'),
        },
      ];
    },
    orderDuration() {
      return moment(this.productionReport?.endDate).unix()
        - moment(this.productionReport?.startDate).unix();
    },
    productionDuration() {
      const productionPhrased = this.orderInProgress?.map(o => ({
        duration: moment(o.to).unix() - moment(o.from).unix(),
      }));
      const productionDuration = productionPhrased
        ?.map(prod => prod.duration)
        .reduce((accumulator, current) => accumulator + current, 0);

      return productionDuration;
    },
    holdDuraton() {
      return this.orderDuration - this.productionDuration;
    },
  },
  methods: {
    showRightTime(date) {
      const plantUtcOffset = moment.tz(this.settings?.plantTimeZone).utcOffset();

      return moment.utc(date).add(plantUtcOffset, 'minutes');
    },
    getTooltip(el) {
      return `${el.label}<br>
        <b>${Math.round((el.value || 0))}%</b>
        - ${CalcDuration(1, el.time + 1)}`;
    },
  },
  created() {
    if (this.productionReport) {
      this.qualityErrors = this.productionReport.qualityErrorsList;
      this.errorsByTime = this.productionReport?.qualityErrorsSummaryByHour?.map(err => ({
        ...err,
        timeRange: {
          from: this.showRightTime(err.timeRange.from),
          to: this.showRightTime(err.timeRange.to),
        },
      }));
      this.qualitySamples = this.productionReport.qualitySamplesList;
      this.orderInProgress = this.productionReport.orderInProgressTimeRanges;
      this.errorsStats = this.productionReport.qualityErrorsSummary;
    }
  },
};
</script>

<style lang="scss" scoped>

.titles {
  font-weight: 550;
  font-size: 1.2em;
}

.info {
  opacity: 0.6;
  font-size: 1.2em;
  margin-top: 15vh;
}

</style>
