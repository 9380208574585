<template>
  <div class="d-flex flex-column">
    <div class="mb-1">
      <hr>
      <div class="title pb-1">
        <i class="fas fa-tag pr-1" />
        {{ $t('order.order') }}
      </div>
      <div
        v-if="order"
        style="font-size: 0.9em"
      >
        <div>
          <span class="props-text">
            {{ 'Production Order Number' }}:
          </span>
          {{ order.productionOrderNumber || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Work Order Number' }}:
          </span>
          {{ order.workOrderNumber || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Line Code' }}:
          </span>
          {{ order.lineCode || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Branch Plant Code' }}:
          </span>
          {{ order.branchPlantCode || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Start Prod' }}:
          </span>
          {{ order.plannedExecutionStart || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'End Prod' }}:
          </span>
          {{ order.plannedExecutionEnd || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'CA Quantity' }}:
          </span>
          {{ order.caQty || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'EA Quantity' }}:
          </span>
          {{ order.eaQty || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Market' }}:
          </span>
          {{ order.market || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Market Code' }}:
          </span>
          {{ order.marketCode || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'External Status' }}:
          </span>
          {{ order.externalStatus || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Status' }}:
          </span>
          {{ order.status || '-' }}
        </div>
      </div>
    </div>

    <div class="mb-1">
      <hr>
      <div class="title pb-1">
        <i class="fas fa-file pr-1" />
        {{ $t('general.item') }}
      </div>
      <div
        v-if="order"
        style="font-size: 0.9em"
      >
        <div>
          <span class="props-text">
            {{ 'Sku' }}:
          </span>
          {{ order.sku || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Description' }}:
          </span>
          {{ order.description || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Brand' }}:
          </span>
          {{ orderBomData.brand || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'EAN' }}:
          </span>
          {{ orderBomData.ean || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Ufi Code' }}:
          </span>
          {{ order.ufiCode || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Batch Format' }}:
          </span>
          {{ orderBomData.batchFormat || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Size' }}:
          </span>
          {{ order.size || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Size Description' }}:
          </span>
          {{ order.sizeDescription || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'SLD' }}:
          </span>
          {{ order.sld || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Formula Code' }}:
          </span>
          {{ order.formulaCode || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Formula Semi' }}:
          </span>
          {{ order.formulaSemi || '-' }}
        </div>
      </div>
    </div>

    <div class="mb-1">
      <hr>
      <div class="title pb-1">
        <i class="fas fa-folder-open pr-1" />
        {{ 'eKP (BOM)' }}
      </div>
      <div
        v-if="order"
        style="font-size: 0.9em"
      >
        <div>
          <span class="props-text">
            {{ 'BOM Number' }}:
          </span>
          {{ order.bomNumber || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'BOM Status' }}:
          </span>
          {{ order.bomStatus || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Comp Type 1' }}:
          </span>
          {{ order.compType1 || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Comp MSN 1' }}:
          </span>
          {{ order.compMSN1 || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Specification No' }}:
          </span>
          {{ order.specificationNo || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Comp Type 2' }}:
          </span>
          {{ order.compType2 || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Comp MSN 2' }}:
          </span>
          {{ order.compMSN2 || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Comp Type 3' }}:
          </span>
          {{ order.compType3 || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Comp MSN 3' }}:
          </span>
          {{ order.compMSN3 || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Front Label MSN' }}:
          </span>
          {{ order.frontLabelMSN || '-' }}
        </div>
        <div v-if="orderBomData.importantInformation">
          <span class="props-text">
            {{ 'Important Information' }}:
          </span>
          {{ orderBomData.importantInformation || '-' }}
        </div>
      </div>
    </div>

    <div class="mb-1">
      <hr>
      <div class="title pb-1">
        <i class="fas fa-clock pr-1" />
        {{ $t('order.orderHistory') }}
      </div>
      <div
        v-if="order"
        style="font-size: 0.9em"
      >
        <div>
          <span class="props-text">
            {{ 'Last Status Update' }}:
          </span>
          {{ order.lastStatusUpdate || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Updated' }}:
          </span>
          {{ order.updated || '-' }}
        </div>
        <div>
          <span class="props-text">
            {{ 'Created' }}:
          </span>
          {{ order.created || '-' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: Object,
    orderBomData: Object,
  },
};
</script>

<style lang="scss" scoped>

  .title {
    font-size: 1.1em;
    font-weight: 500 !important;
  }

  .props-text {
    opacity: 0.9;
    font-weight: 600 !important;
  }

</style>
