export default {
  general: {
    name: 'Name',
    entry: 'Entry',
    description: 'Description',
    save: 'Save',
    delete: 'Delete',
    restore: 'Restore',
    deleted: 'Deleted',
    removed: 'Removed',
    remove: 'Remove',
    select: 'Select',
    selected: 'Selected',
    cancel: 'Cancel',
    confirm: 'Confirm',
    search: 'Search',
    value: 'Value',
    error: 'Error',
    saved: 'Saved',
    valueType: 'Value type',
    download: 'Download',
    load: 'Load',
    results: 'Results',
    type: 'Type',
    statuses: 'Statuses',
    status: 'Status',
    orderStatus: 'Order Status',
    created: 'Created',
    modified: 'Modified',
    edited: 'Edited',
    enabled: 'Enabled',
    disabled: 'Disabled',
    success: 'Success',
    update: 'Update',
    updateAndRelease: 'Update and release',
    ordinalNumber: 'Ordinal Number',
    yes: 'Yes',
    no: 'No',
    on: 'On',
    off: 'Off',
    of: 'of',
    archive: 'Archive',
    all: 'All',
    areYouSure: 'Are you sure?',
    id: 'Id',
    from: 'From',
    to: 'To',
    auto: 'Auto',
    manual: 'Manual',
    history: 'History',
    details: 'Details',
    never: 'Never',
    noLines: 'No lines',
    noItems: 'No items',
    brand: 'Brand',
    active: 'Active',
    archived: 'Archived',
    preview: 'Preview',
    add: 'Add',
    help: 'Help',
    done: 'Done',
    photo: 'Photo',
    item: 'Item',
  },
  actions: {
    release: 'Release',
    create: 'Create',
    validate: 'Validate',
    details: 'Details',
    unrelease: 'Unrelease',
    releaseAgain: 'Release again',
    finishAndReport: 'Finish and report',
    reviewReport: 'Review report',
    finish: 'Finish',
    deleteOrder: 'Delete order',
    edit: 'Edit',
  },
  connection: {
    status: 'Connection status',
    disconnected: 'Disconnected',
    reconnecting: 'Reconnecting',
    connected: 'Connected',
  },
  date: {
    date: 'Date',
    start: 'Start Date',
    end: 'End Date',
    plannedStart: 'Planned start',
    plannedEnd: 'Planned end',
    selectDateAndTime: 'Select date and time',
  },
  report: {
    report: 'Report',
    errorStatistics: 'Error statistics',
    errorsInTime: 'Errors in time',
    totalNumberOfErrors: 'Total number of errors',
    productionRun: 'Production run',
    history: 'History',
    comment: 'Comment',
    comments: 'Comments',
    addComment: 'Add comment',
    pics: 'Pics',
    incidents: 'Incidents',
    piscOfErrors: 'Pisc of error',
    qualityError: 'Quality error',
    qualityIssues: 'Quality issues',
    qualityReferencesPisc: 'Quality references pisc',
    finishOrder: 'Finish order',
    errors: 'Errors',
    qualitySample: 'Quality sample',
    qualityReferences: 'Quality references',
    reportGenerationError: 'Report generation error',
    key: 'Key',
    oldValue: 'Old value',
    newValue: 'New value',
    deviceName: 'Device name',
  },
  auth: {
    signOut: 'Logout',
    loginError: 'Login error',
    noAccess: 'No access',
  },
  imports: {
    importLog: 'Import log',
    importType: 'Import type',
    triggerType: 'Trigger type',
    dataSource: 'Data source',
    uploadedFile: 'Uploaded file',
    externalApi: 'External API',
    importDetails: 'Import details',
    importErrors: 'Import errors',
    requester: 'Requester',
    details: 'Details',
    status: {
      SCHEDULED: 'Scheduled',
      INPROGRESS: 'InProgress',
      COMPLETED: 'Completed',
      FAILED: 'Failed',
    },
  },
  importTypes: {
    workOrders: 'Work Orders',
    billOfMaterials: 'EKP',
    uniqueFormulaIndentifiers: 'UFI',
    singleBillOfMaterials: 'Single_EKP',
  },
  users: {
    import: 'Import',
    name: 'User name',
    id: 'User Id',
    preferences: 'Preferences',
    preferredArea: 'Preferred area',
    preferredLine: 'Preferred line',
  },
  sidebar: {
    sku: 'SKU',
    settings: 'Settings',
    planning: 'Planning',
    production: 'Production',
    archive: 'Archive',
  },
  error: {
    internal: 'Internal error',
    invalidPassword: 'Invalid password',
    wrongInput: 'Given data is invalid!',
    invalidHour: 'Invalid hour',
    invalidOrderNumber: 'Order number can contain only numbers',
    orderNumberIsTooLong: 'Order number is too long',
    nameIsTooLong: 'Name is too long',
    sizeIsTooLong: 'Size is too long',
    descriptionIsTooLong: 'Description is too long',
    startDateIsGreaterThanEndDate: 'Start date is greater than end date',
    requestFailed: 'Request failed',
    appNotLoadedProperly: 'The GoodIn application is not loaded properly.'
      + ' Some functionalities may be limited and the presented data may be incomplete.',
  },
  validation: {
    valid: 'VALID',
    invalid: 'INVALID',
    validationErrors: 'Validation errors',
    validationErrorInfo: 'There are validation errors, the order cannot be created',
    productionOrderAlreadyExists: 'Production Order created from the selected Working Order and EKP already exists',
  },
  line: {
    line: 'Line',
    lines: 'Lines',
    lineName: 'Line Name',
    selectLine: 'Select Line',
  },
  form: {
    form: 'Form',
    refresh: 'Refresh',
    send: 'Send',
    title: 'Title',
    dataForm: 'Data Form',
    noData: 'No data',
    values: 'Values',
    dataSource: 'Data Source',
    url: 'Type adress URL for scale',
    orderFieldInfo: 'Select order field',
    orderField: 'Order Field',
    label: 'Label',
    labelUpdated: 'Label has been updated',
    required: 'Required',
    checkedByDefault: 'Checked by default',
    preprintedUfi: 'Preprinted UFI',
    description: 'Description',
    text: 'Text',
    input: 'Input',
    select: 'Select',
    textarea: 'Textarea',
    checkbox: 'Checkbox',
    checklist: 'Checklist',
    radios: 'Radio',
    textField: 'Static text',
    addField: 'Add field',
    activeFileds: 'Active fields',
    selectFieldType: 'Select field type',
    fieldType: 'Field type',
    inputType: 'Select input type',
    labelValue: 'Enter question / field name',
    fieldDescription: 'Field description',
    fieldValues: 'Enter field values',
    name: 'Name',
    type: 'Type',
    chooseBackLabelToCreateOrder: 'To create an order, pick a back label',
    chooseEkpToCreateOrder: 'To create an order, pick an eKP',
    editField: 'Select a field to start editing',
    startField: 'Add field to start editing',
    qualityChecklist: 'Quality checklist',
    chooseOrSkanEkp: 'Choose or skan eKP',
    selectOrderToSeeTheDetails: 'Select order to see the details',
    backLabel: 'Back Label',
    videoInspectionSummary: 'Video inspection summary',
    wo: 'WO',
    po: 'PO',
    workingOrders: 'Working Orders',
    productionOrders: 'Production Orders',
    completedOrders: 'Completed Orders',
    workOrder: 'Work Order',
    productionOrder: 'Production Order',
    doYouWantToDelete: 'Do you want to delete',
    areSureYouWantToDelete: 'Are you sure you want to delete this Production Order',
    startImport: 'Start import',
    uploadFile: 'Drop file here to upload',
    singleEkp: 'Single eKP',
    multipleEkp: 'Multiple eKPs',
    multipleData: 'Multiple data',
    importFile: 'Selected Excel file',
    dataSaved: 'Data saved successfully',
    importSuccessInfo: 'The import started successfully',
    inspect: 'Inspect',
    importantInformation: 'Important information',
    optional: 'Optional',
    rowsOnThePage: 'Rows on the page',
    showing: 'Showing',
    results: 'Results',
    searchByOrderNumber: 'Search by order number',
  },
  placeholders: {
    line: 'Pick a line',
    branchPlant: 'Pick a branch plant',
    orderNo: 'Enter order number',
    sku: 'Pick SKU',
    marketCode: 'Pick a market code',
    size: 'Enter size',
    description: 'Enter description',
    ekp: 'Enter eKP',
  },
  order: {
    activeOrders: 'Active orders',
    createOrder: 'Create order',
    updateOrder: 'Update order',
    activeOrderDetails: 'Active Order Details',
    orderActivationHistory: 'Order Activation History',
    orderHistory: 'Order History',
    actions: 'Actions',
    details: 'Details',
    orderActivated: 'Order activated:',
    order: 'Order',
    orderUpdated: 'Order has been updated',
    orderCreated: 'Order has been created',
    orderReleased: 'Order has been released',
    orderDeleted: 'Order has been deleted',
    orderUnreleased: 'Order has been unreleased',
    orders: 'Orders',
    end: 'End Order',
    putOrderNumber: 'Type order number',
    stop: 'Stop',
    work: 'Work',
    hold: 'Hold',
    orderType: 'Order Type',
    addOrderType: 'Add Order Type',
    selectOrderType: 'Select Order Type',
    orderTypes: 'Order Types',
    mappings: 'Order Type Mapping',
    displayName: 'Display Name',
    start: 'Start Order',
    activate: 'Activate Order',
    orderNumber: 'Order No.',
    orderDetails: 'Order details',
    fields: 'Order fields',
    field: 'Order field',
    add: 'Add order',
    badgeColor: 'Badge Color',
    marketCode: 'Market code',
    market: 'Market',
    externalStatus: 'External Status',
    size: 'Size',
    status: {
      VALIDATED: 'VALIDATED',
      READY: 'RELEASED',
      INPROGRESS: 'INPROGRESS',
      ONHOLD: 'ON HOLD',
      COMPLETED: 'COMPLETED',
    },
    action: {
      CREATED: 'CREATED',
      STARTED: 'STARTED',
      UPDATED: 'UPDATED',
      VALIDATED: 'VALIDATED',
      RELEASED: 'RELEASED',
      UNRELEASED: 'UNRELEASED',
      HOLD: 'HOLD',
      COMPLETED: 'COMPLETED',
      DELETED: 'DELETED',
    },
  },
  selectors: {
    period: 'Period',
    moment: 'Moment',
    selectExactDates: 'Select exact dates',
    dateTo: 'Date to',
    dateFrom: 'Date from',
    shift: 'Shift',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This week',
    previousWeek: 'Previous week',
    thisMonth: 'This month',
    previousMonth: 'Previous month',
    lastThreeMonths: 'Last 3 months',
    branchPlant: 'Branch plant',
    validationStatus: 'Validation status',
    ekpNumber: 'eKP number',
  },
  time: {
    days: 'days',
    duration: 'Duration',
    timeRange: 'Time range',
  },
};
