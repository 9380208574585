<template>
  <div class="mx-2">
    <div class="modal-title mb-2">
      <i
        class="ion ion-md-add pr-1"
      />
      {{ $t('order.add') }}
    </div>

    <div class="row px-2">
      <div
        class="col-md-6 px-2"
      >
        <label class="table-title mb-1">
          {{ $t('selectors.branchPlant') }}
        </label>
        <BFormSelect
          v-if="areas"
          v-model="areaId"
          :state="areaId ? true : null"
          :placeholder="$t('placeholders.branchPlant')"
        >
          <option
            v-for="area in areas"
            :key="area.id"
            :value="area.id"
          >
            {{ area.name }}
          </option>
        </BFormSelect>
        <div v-else>
          <div
            class="d-flex flex-row justify-content-center ml-2"
            style="line-height: 40px"
          >
            <Loader
              :size="15"
              class="mr-2"
              color="goodin"
            />
          </div>
        </div>
      </div>
      <div
        class="col-md-6 px-2"
      >
        <label class="table-title mb-1">
          {{ $t('line.line') }}
        </label>
        <BFormSelect
          v-if="lines"
          v-model="lineCode"
          :disabled="!areaId"
          :state="lineCode ? true : null"
          :placeholder="$t('placeholders.line')"
        >
          <option
            v-for="line in filteredLines"
            :key="line.id"
            :value="line.id"
          >
            {{ line.name }}
          </option>
        </BFormSelect>
        <div v-else>
          <div
            class="d-flex flex-row justify-content-center ml-2"
            style="line-height: 40px"
          >
            <Loader
              :size="15"
              class="mr-2"
              color="goodin"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row my-2 px-2">
      <div class="col-md-6 px-2">
        <label class="table-title mb-1">
          {{ $t('order.orderNumber') }}
        </label>
        <BFormInput
          v-model="orderNumber"
          :state="(orderNumber ? orderNumber.length : null)
            && !orderNumberError && isNumber(orderNumber)"
          :placeholder="$t('placeholders.orderNo')"
          trim
        />
      </div>

      <div
        class="col-md-6 px-2"
      >
        <label class="table-title mb-1">
          {{ $t('sidebar.sku') }}
        </label>
        <BFormSelect
          v-if="skuList"
          v-model="skuNumber"
          :state="skuNumber ? true : null"
          :placeholder="$t('placeholders.sku')"
        >
          <option
            v-for="sku in skuList"
            :key="sku.id"
            :value="sku.id"
          >
            {{ sku.name }}
          </option>
        </BFormSelect>
        <div v-else>
          <div
            class="d-flex flex-row justify-content-center ml-2"
            style="line-height: 40px"
          >
            <Loader
              :size="15"
              class="mr-2"
              color="goodin"
            />
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div
      class="row mt-2 px-2"
    >
      <div class="col-md-6 px-2">
        <label class="table-title mb-1">
          {{ $t('order.marketCode') }}
        </label>
        <BFormSelect
          v-model="marketCode"
          :state="marketCode ? true : null"
          :placeholder="$t('placeholders.marketCode')"
        >
          <option
            v-for="country in marketCodes"
            :key="country.marketCode"
            :value="country.marketCode"
          >
            {{ country.marketCode }} - {{ country.marketName }}
          </option>
        </BFormSelect>
      </div>

      <div
        class="col-md-6 px-2"
      >
        <label class="mb-1">
          <span class="table-title">
            {{ $t('order.size') }}
          </span>
        </label>
        <BFormInput
          v-model="size"
          :state="(size ? size.length : null) && !sizeError"
          :placeholder="$t('placeholders.size')"
        />
      </div>

      <div
        class="col-md-12 px-2 mt-2"
      >
        <label class="mb-1">
          <span class="table-title">
            {{ $t('general.description') }}
          </span>
          <span class="optional-text">
            - {{ $t('form.optional') }}
          </span>
        </label>
        <BFormInput
          v-model="description"
          :state="(description ? description.length : null)
            && !orderDescError"
          :placeholder="$t('placeholders.description')"
        />
      </div>
    </div>

    <hr>

    <div class="row my-2 px-2">
      <div
        class="col-md-6 px-2"
      >
        <label class="table-title mb-1">
          {{ $t('date.start') }}
        </label>
        <DateTimePicker
          id="startDateSelector"
          ref="startDateSelector"
          v-model="plannedExecutionStart"
          :color="'#ffb033'"
          class="custom-picker mr-2"
          overlay
          no-clear-button
          enable-button-validate
          :error="datesNotValid || (!plannedExecutionStart && validate)"
          :format="pickerFormat"
          formatted="D MMM YYYY HH:mm"
          :label="$t('date.selectDateAndTime')"
          :locale="localLang || locale"
        />
      </div>

      <div
        class="col-md-6 px-2"
      >
        <label class="mb-1">
          <span class="table-title">
            {{ $t('date.end') }}
          </span>
          <span class="optional-text">
            - {{ $t('form.optional') }}
          </span>
        </label>
        <DateTimePicker
          id="endDateSelector"
          ref="endDateSelector"
          v-model="plannedExecutionEnd"
          :color="'#ffb033'"
          class="custom-picker mr-2"
          overlay
          no-clear-button
          enable-button-validate
          :error="datesNotValid"
          :format="pickerFormat"
          formatted="D MMM YYYY HH:mm"
          :locale="localLang || locale"
          :label="$t('date.selectDateAndTime')"
          :right="true"
        />
      </div>
    </div>

    <hr>

    <Error
      v-if="displayedError"
      class="mb-2"
      :message="displayedError"
    />

    <div class="pt-2 text-center">
      <button
        class="actions-button btn btn-secondary btn-sm mx-2 text-center"
        style="min-width: 125px"
        type="button"
        @click.prevent="$emit('close')"
      >
        <i
          class="ion ion-md-close pr-2"
        />
        {{ $t('general.cancel') }}
      </button>

      <button
        class="actions-button btn btn-primary btn-sm mx-2 text-center"
        style="min-width: 125px"
        type="submit"
        :disabled="!errorCheck"
        @click="createOrder"
      >
        <BSpinner
          v-if="pending"
          variant="light"
          small
          class="mr-1"
        />
        <i
          v-else
          class="ion ion-md-add pr-1"
        />
        {{ $t('general.add') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      orderNumber: null,
      skuNumber: null,
      lineCode: null,
      description: null,
      marketCode: null,
      areaId: null,
      plannedExecutionStart: null,
      plannedExecutionEnd: null,
      pending: false,
      pendingCreate: false,
      pickerFormat: 'YYYY-MM-DD HH:mm:ss',
      validate: null,
      localLang: localStorage.lang,
      size: null,
      marketCodes: [],
    };
  },
  computed: {
    ...mapGetters([
      'areas',
      'lines',
      'skus',
    ]),
    ...mapGetters(['locale']),
    filteredLines() {
      const selectedLines = this.lines.filter(l => l.areaId === this.areaId);
      return selectedLines.length ? selectedLines : this.lines;
    },
    skuList() {
      return this.skus.map(x => ({ id: x, name: x })) || [];
    },
    orderNumberError() {
      if (!this.orderNumber) return false;
      return this.orderNumber.length >= 40;
    },
    sizeError() {
      if (!this.size) return false;
      return this.size.length >= 40;
    },
    orderDescError() {
      if (!this.description) return false;
      return this.description.length >= 100;
    },
    datesNotValid() {
      if (this.plannedExecutionStart && this.plannedExecutionEnd) {
        return moment(this.plannedExecutionStart)
          .isSameOrAfter(this.plannedExecutionEnd);
      }
      return false;
    },
    displayedError() {
      if (this.orderDescError) return this.$t('error.descriptionIsTooLong');
      if (this.sizeError) return this.$t('error.sizeIsTooLong');
      if (this.orderNumberError) return this.$t('error.orderNumberIsTooLong');
      if (!this.isNumber(this.orderNumber) && this.orderNumber) return this.$t('error.invalidOrderNumber');
      if (this.datesNotValid) return this.$t('error.startDateIsGreaterThanEndDate');
      return null;
    },
    errorCheck() {
      if (this.areaId
        && this.lineCode
        && this.orderNumber
        && this.isNumber(this.orderNumber)
        && this.skuNumber
        && this.marketCode
        && this.size
        && this.plannedExecutionStart
        && !this.datesNotValid
        && !this.orderNumberError
        && !this.sizeError
        && !this.orderDescError
      ) return true;
      return false;
    },
  },
  methods: {
    ...mapActions([
      'createNewWorkOrder',
      'getMarketCodes',
    ]),
    selecedLineName(id) {
      return this.lines.find(l => l.id === id)?.name;
    },
    selecedAreaName(id) {
      return this.areas.find(a => a.id === id)?.name;
    },
    isNumber(event) {
      if (/^[0-9]+$/.test(event)) {
        return true;
      }
      return false;
    },
    createOrder() {
      this.pendingCreate = true;
      this.createNewWorkOrder({
        data: {
          orderNumber: this.orderNumber,
          skuNumber: this.skuNumber,
          lineCode: this.selecedLineName(this.lineCode),
          marketCode: this.marketCode,
          market: this.marketCodes.find(x => x.marketCode === this.marketCode)?.marketName || null,
          branchPlantCode: this.selecedAreaName(this.areaId),
          plannedExecutionStart: moment(this.plannedExecutionStart).toISOString(),
          plannedExecutionEnd: moment(this.plannedExecutionEnd).toISOString(),
          description: this.description,
          size: this.size,
        },
      })
        .then(() => {
          this.$emit('successMsg', this.$t('order.orderCreated'));
        })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.errors?.WorkOrder);
        })
        .finally(() => {
          this.pendingCreate = false;
          this.$emit('close');
        });
    },

    marketCodeList() {
      this.getMarketCodes()
        .then(({ data }) => {
          const basicValue = [{
            marketCode: 'OTHER',
            marketName: 'OTHER',
          }];
          this.marketCodes = basicValue.concat(data);
        });
    },

  },
  created() {
    this.marketCodeList();
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/vars.icss';

 .ui.disabled {
    background-color: #e9ecef;
    opacity: 1
  }

  .modal-title {
    font-size: 1.4em;
    font-weight: 500;
  }

  .actions-button {
    text-align: left;
    margin: 5px 0px;
    flex-grow: 1;
    font-size: 1em;
    font-weight: 500;
  }

  .optional-text {
    font-weight: 500;
    opacity: 0.6;
    font-style: italic;
    font-size: 14px;
  }

  .table-title {
    font-weight: 700;
    font-size: 14px;
  }

</style>
