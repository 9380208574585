import lang from '@/lang';
/*
  Calculate the difference in seconds between two timestamps.
  When end is not defined, takes the current time.
*/

const getDuration = (begin, end) => (end ? (end - begin) : (Math.floor(new Date().getTime() / 1000) - begin));

export default (begin, end) => {
  if (!begin) return null;
  let duration = getDuration(begin, end);

  if (duration < 0) return '0 s';
  // return in seconds
  if (duration < 60) return `${duration} s`;
  const seconds = Math.floor(duration) % 60;
  duration = Math.floor(duration / 60);
  // return in minutes and seconds
  if (duration < 60) return `${duration} min ${seconds} s`;
  const minutes = duration % 60;
  duration = Math.floor(duration / 60);
  // return in hours and minutes
  if (duration < 48) return `${duration}h ${minutes} min`;
  duration = Math.floor(duration / 24);
  // return in days
  return `${duration} ${lang.t('time.days')}`;
};
