<template>
  <div class="d-flex flex-column">
    <div class="modal-title mb-2 ml-2">
      <i class="fas fa-upload pr-2" />
      {{ $t('users.import') }}
    </div>
    <div class="d-flex">
      <div class="col-3 mt-3 title">
        {{ $t('users.import') }}
      </div>
      <div class="col-9 checklist">
        <BFormRadioGroup
          v-model="selectedImport"
          :disabled="file"
          button-variant="outline-success"
          style="display: inline-grid"
        >
          <template v-for="o in options">
            <BFormRadio
              :key="o.value"
              :value="o.value"
              class="mt-3"
            >
              <span>
                <div class="checkbox-text cursor">
                  {{ o.description }}
                </div>
              </span>
            </BFormRadio>
          </template>
        </BFormRadioGroup>
      </div>
    </div>

    <hr>

    <div
      v-if="selectedImport === 'importFile'"
      class="col-9 px-2"
    >
      <VueDropzone
        v-if="!pending && !file"
        id="dropzone"
        style="border-radius: 20px"
        class="cursor"
        :options="dropzoneOptions"
        :use-custom-slot="true"
        @vdropzone-file-added="uploaded"
      >
        <template v-if="dropzoneOptions">
          <i
            class="fas fa-download"
          />
          {{ $t('form.uploadFile') }}
        </template>
      </VueDropzone>
      <div
        v-else-if="pending"
        class="loader-box"
      >
        <Loader
          class="pt-4"
          color="goodin"
        />
      </div>
      <div
        v-else
        class="d-flex align-items-center job-row"
      >
        <div
          class="status-icon text-center d-flex align-items-center justify-content-center"
          :style="{
            'background-color': 'rgb(117, 175, 52)'
          }"
        >
          <i class="fas fa-check" />
        </div>
        <div class="pl-2">
          <div
            class="text-secondary font-weight-bold small"
          >
            {{ showRightTime(importedFile.created) }}
          </div>
          <div
            class="pr-2"
            style="line-height: 1"
          >
            {{ jsonPhrase(importedFile.details).FileName }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="selectedImport === 'singleEkp'"
      class="col-9 px-2"
    >
      <div class="title mb-2">
        eKP
      </div>
      <div>
        <BFormInput
          v-model="ekpNumber"
          style="height: 42px"
          :placeholder="$t('placeholders.ekp')"
        />
      </div>
    </div>

    <div
      v-if="selectedImport === 'allData' || selectedImport === 'allEkp'"
      class="col-9 px-2"
    >
      <div
        v-if="selectedImport === 'allData'"
        class="title mb-2"
      >
        {{ $t('date.date') }} ({{ $t('importTypes.billOfMaterials') }} + {{ $t('importTypes.workOrders') }})
      </div>
      <div
        v-else
        class="title mb-2"
      >
        {{ $t('date.date') }}
      </div>
      <DateTimePicker
        id="maxDateSelector"
        ref="maxDateSelector"
        v-model="dateTo"
        :color="'#ffb033'"
        class="custom-picker mr-2"
        overlay
        no-clear-button
        enable-button-validate
        :error="dateToNotValid"
        :format="'YYYY-MM-DD HH:mm:ss'"
        :min-date="pickerFormat($now)"
        formatted="D MMM YYYY HH:mm"
        :label="$t('date.selectDateAndTime')"
        :locale="localLang || locale"
      />
    </div>

    <div
      v-if="selectedImport === 'allData'"
      class="col-9 px-2 pt-2"
    >
      <div class="title mb-2">
        {{ $t('date.date') }} ({{ $t('importTypes.uniqueFormulaIndentifiers') }})
      </div>
      <DateTimePicker
        id="startDateSelector"
        ref="startDateSelector"
        v-model="dateFrom"
        :color="'#ffb033'"
        class="custom-picker mr-2"
        overlay
        no-clear-button
        enable-button-validate
        :error="dateFromNotValid"
        :format="'YYYY-MM-DD HH:mm:ss'"
        :max-date="pickerFormat($now)"
        formatted="D MMM YYYY HH:mm"
        :label="$t('date.selectDateAndTime')"
        :locale="localLang || locale"
      />
    </div>

    <hr>

    <div class="pt-2 text-center">
      <button
        v-if="!file"
        class="actions-button btn btn-secondary btn-sm mx-2"
        type="button"
        @click="$emit('close')"
      >
        <i
          class="ion ion-md-close pr-2"
        />
        {{ $t('general.cancel') }}
      </button>

      <button
        class="actions-button btn btn-primary btn-sm"
        type="submit"
        :disabled="
          (selectedImport === 'singleEkp' && !ekpNumber)
            || (selectedImport === 'importFile' && !file)
            || (selectedImport === 'allEkp' && !dateTo)
            || (selectedImport === 'allData' && (!dateFrom || !dateTo))
            || pending"
        @click="startImport"
      >
        <BSpinner
          v-if="pending"
          variant="light"
          small
          class="mr-1"
        />
        <i
          v-else
          class=" pr-2"
          :class="file ? 'fas fa-check' : 'fas fa-upload'"
        />
        <span v-if="file">
          {{ $t('general.done') }}
        </span>
        <span v-else>
          {{ $t('form.startImport') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment-timezone';
import VueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  data: () => ({
    datePickerFormat: 'YYYY-MM-DD HH:mm:ss',
    dateFrom: null,
    dateTo: null,
    localLang: localStorage.lang,
    selectedImport: 'singleEkp',
    ekpNumber: null,
    pending: false,
    error: null,
    file: null,
    importedFile: null,
    test: false,
  }),
  components: {
    VueDropzone,
  },
  computed: {
    ...mapGetters([
      'locale',
      'settings',
    ]),
    dateFromNotValid() {
      if (this.dateFrom) {
        return moment(this.dateFrom)
          .isSameOrAfter(this.pickerFormat(this.$now));
      }
      return false;
    },
    dateToNotValid() {
      if (this.dateTo) {
        return moment(this.dateTo)
          .isSameOrBefore(this.pickerFormat(this.$now));
      }
      return false;
    },
    options() {
      return [
        {
          value: 'singleEkp',
          description: this.$t('form.singleEkp'),
        },
        {
          value: 'allEkp',
          description: this.$t('form.multipleEkp'),
        },
        {
          value: 'allData',
          description: this.$t('form.multipleData'),
        },
        {
          value: 'importFile',
          description: this.$t('form.importFile'),
        },
      ];
    },
    dropzoneOptions() {
      return {
        url: '/api/internal/import/excel',
        dictDefaultMessage: this.$t('form.uploadFile'),
        thumbnailWidth: 150,
        maxFilesize: 1.0,
        headers: { },
      };
    },
  },
  methods: {
    ...mapActions([
      'importSingleBom',
      'importBom',
      'importOrder',
      'importUfi',
      'importFile',
    ]),
    showRightTime(date) {
      const plantUtcOffset = moment.tz(this.settings?.plantTimeZone).utcOffset();

      return moment.utc(date).add(plantUtcOffset, 'minutes').format('ll LTS');
    },
    startImport() {
      if (this.selectedImport === 'singleEkp' && this.ekpNumber) {
        this.importFunction(this.selectedImport);
      }
      if (this.selectedImport === 'allEkp' && this.dateTo) {
        this.importFunction(this.selectedImport);
      }
      if (this.selectedImport === 'allData' && this.dateFrom && this.dateTo) {
        this.importFunction(this.selectedImport);
      }
      this.$emit('close');
    },
    jsonPhrase(data) {
      return JSON.parse(data) || null;
    },
    pickerFormat(date) {
      return moment.utc(date * 1000).format(this.datePickerFormat);
    },
    successMessage() {
      this.$bvToast.toast(this.$t('form.importSuccessInfo'), {
        title: this.$t('general.success'),
        autoHideDelay: 10000,
        variant: 'success',
      });
    },
    errorMessage(data) {
      this.$root.$bvToast.toast(data.title, {
        title: data.type || this.$t('general.error'),
        autoHideDelay: 10000,
        variant: 'danger',
      });
    },
    importFunction(type) {
      this.pending = true;
      this.error = null;
      let promise = Promise.resolve();
      if (type === 'singleEkp') {
        promise = this.importSingleBom({ params: { bomNumber: this.ekpNumber } });
      } else if (type === 'allEkp') {
        promise = this.importBom({ params: { query: { maxDateTime: this.dateTo } } });
      } else if (type === 'allData') {
        const bom = this.importBom({ params: { query: { maxDateTime: this.dateTo } } });
        const order = this.importOrder({ params: { query: { maxDateTime: this.dateTo } } });
        const ufi = this.importUfi({ params: { query: { publishedAfter: this.dateFrom } } });
        promise = Promise.all([bom, order, ufi]);
      }

      promise
        .then(() => {
          this.successMessage();
        })
        .catch(({ response: { data } }) => {
          this.errorMessage(data);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    uploaded(file) {
      this.pending = true;
      this.error = false;
      this.file = file;

      const formData = new FormData();
      formData.append('file', file);
      this.importFile({
        data: formData,
      })
        .then(({ data }) => {
          [this.importedFile] = data;
          this.successMessage();
        })
        .catch(({ response: { data } }) => {
          this.error = data;
          this.errorMessage(data);
          this.file = null;
          this.importedFile = null;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.dateTo = moment
      .utc(this.$now * 1000)
      .endOf('day')
      .add(1, 'days')
      .format(this.datePickerFormat);
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .title {
    font-size: 1.1em;
    font-weight: 500 !important;
  }

  .modal-title {
    font-size: 1.4em;
    font-weight: 500;
  }

  .actions-button {
    text-align: left;
    margin: 5px 0px;
    flex-grow: 1;
    font-size: 1em;
    font-weight: 500;
  }

  hr {
    border: 0;
    clear:both;
    display:block;
    width: 96%;
    background-color: #f1f1f1;
    height: 1px;
  }

  .vl {
    border-left: 1px solid #f1f1f1;
    height: 100%;
    padding-left: 20px !important;
  }

  .cursor {
    cursor: pointer;
  }

  .loader-box {
    min-height: 150px;
    border: 2px solid #e5e5e5;
    background: white;
    border-radius: 20px;
    padding: 20px 20px;
  }

  .job-row {
    cursor: pointer;

    .status-icon {
      height: 30px;
      color: white;
      width: 30px;
      flex-shrink: 0;
      border-radius: 50%;
    }

    &:hover {
      background-color: #fafafa;
    }
  }

</style>
