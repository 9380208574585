import Vue from 'vue';
import VueRouter from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import store from '@/store';
import {
  GOODIN_PLANNING,
  GOODIN_PRODUCTION,
  GOODIN_ARCHIVE,
  GOODIN_IMPORT_LOG,
} from '@/utils/permissionsDictionary';
import Dashboard from '@/pages/Dashboard';
import NoAccess from '@/pages/auth/NoAccess';
import OidcCallback from '@/pages/auth/OidcCallback';
import Planning from '@/components/pages/Planning';
import ImportLog from '@/components/pages/ImportLog';
import Production from '@/components/pages/Production';
import Archive from '@/components/pages/Archive';

Vue.use(VueRouter);

const routes = [
  {
    path: '/authentication/login-callback',
    component: OidcCallback,
  },
  {
    path: '/',
    component: Dashboard,
    children: [
      {
        path: '/',
        component: Planning,
        meta: {
          permittedFor: [GOODIN_PLANNING],
        },
      },
      {
        path: '/production',
        component: Production,
        meta: {
          permittedFor: [GOODIN_PRODUCTION],
        },
      },
      {
        path: '/archive',
        component: Archive,
        meta: {
          permittedFor: [GOODIN_ARCHIVE],
        },
      },
      {
        path: '/importLog',
        component: ImportLog,
        meta: {
          permittedFor: [GOODIN_IMPORT_LOG],
        },
      },
      {
        path: '/noAccess',
        component: NoAccess,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

export default router;
