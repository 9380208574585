export default {
  general: {
    name: 'Nazwa',
    entry: 'Wpis',
    description: 'Opis',
    save: 'Zapisz',
    delete: 'Usuń',
    restore: 'Przywróć',
    deleted: 'Usunięto',
    removed: 'Usunięte',
    remove: 'Usuń',
    select: 'Wybierz',
    selected: 'Wybrane',
    cancel: 'Anuluj',
    confirm: 'Potwierdź',
    search: 'Szukaj',
    value: 'Wartość',
    error: 'Błąd',
    saved: 'Zapisano',
    valueType: 'Typ wartości',
    download: 'Pobierz',
    load: 'Załaduj',
    results: 'Wyniki',
    type: 'Rodzaj',
    status: 'Status',
    orderStatus: 'Status Zlecenia',
    statuses: 'Statusy',
    created: 'Utworzono',
    modified: 'Zmodyfikowano',
    edited: 'Edytowano',
    enabled: 'Włączony',
    disabled: 'Wyłączono',
    success: 'Udało się',
    update: 'Zaktualizuj',
    updateAndRelease: 'Zaktualizuj i zwolnij',
    ordinalNumber: 'Liczba porządkowa',
    yes: 'Tak',
    no: 'Nie',
    on: 'Włącz',
    off: 'Wyłącz',
    of: 'z',
    archive: 'Archiwizuj',
    all: 'Wszystkie',
    areYouSure: 'Czy na pewno?',
    id: 'Id',
    from: 'Od',
    to: 'Do',
    auto: 'Automatyczny',
    manual: 'Ręczny',
    history: 'Historia',
    details: 'Szczegóły',
    never: 'Nigdy',
    noLines: 'Brak linii',
    noItems: 'Brak elementów',
    brand: 'Marka',
    active: 'Aktywne',
    archived: 'Archiwalny',
    preview: 'Podgląd',
    add: 'Dodaj',
    help: 'Pomoc',
    done: 'Gotowe',
    photo: 'Zdjęcie',
    item: 'Produkt',
  },
  actions: {
    release: 'Zwolnij',
    create: 'Stwórz',
    validate: 'Zwaliduj',
    details: 'Szczegóły',
    unrelease: 'Cofnij',
    releaseAgain: 'Zwolnij ponownie',
    finishAndReport: 'Zakończ i zaraportuj',
    reviewReport: 'Sprawdź raport',
    finish: 'Zakończ',
    deleteOrder: 'Usuń zlecenie',
    edit: 'Edytuj',
  },
  connection: {
    status: 'Status połączenia',
    disconnected: 'Rozłączono',
    reconnecting: 'Trwa ponowne łączenie',
    connected: 'Podłączono',
  },
  date: {
    date: 'Data',
    start: 'Data rozpoczęcia',
    end: 'Data zakończenia',
    plannedStart: 'Planowany start',
    plannedEnd: 'Planowany koniec',
    selectDateAndTime: 'Wybierz datę i czas',
  },
  report: {
    report: 'Raport',
    errorStatistics: 'Statystyka błędów',
    errorsInTime: 'Błędy w czasie',
    totalNumberOfErrors: 'Całkowita liczba błędów',
    productionRun: 'Przebieg produkcji',
    history: 'Historia',
    comment: 'Komentarz',
    comments: 'Komentarze',
    addComment: 'Dodaj komentarz',
    pics: 'Zdjęcia',
    incidents: 'Incydenty',
    piscOfErrors: 'Zdjęcia błędów',
    qualityError: 'Błąd jakościowy',
    qualityIssues: 'Problemy jakościowe',
    qualityReferencesPisc: 'Jakościowe zdjęcia referencyjne',
    finishOrder: 'Zakończ zlecenie',
    errors: 'Błędy',
    qualitySample: 'Wzorzec jakościowy',
    qualityReferences: 'Próbki statystyczne',
    reportGenerationError: 'Błąd generacji raportu',
    key: 'Klucz',
    oldValue: 'Stara wartość',
    newValue: 'Nowa wartość',
    deviceName: 'Nazwa urządzenia',
  },
  auth: {
    signOut: 'Wyloguj',
    loginError: 'Błąd logowania',
    noAccess: 'Brak dostępu',
  },
  imports: {
    importLog: 'Logi importowe',
    importType: 'Typ importu',
    triggerType: 'Typ wyzwolenia',
    dataSource: 'Źródło danych',
    uploadedFile: 'Przesłany plik',
    externalApi: 'Zewnętrzne API',
    importDetails: 'Szczegóły importu',
    importErrors: 'Błędy importu',
    requester: 'Wnioskujący',
    details: 'Szczegóły',
    status: {
      SCHEDULED: 'Zaplanowany',
      INPROGRESS: 'W trakcie',
      COMPLETED: 'Zakończony',
      FAILED: 'Nieudany',
    },
  },
  importTypes: {
    workOrders: 'Work Orders',
    billOfMaterials: 'EKP',
    uniqueFormulaIndentifiers: 'UFI',
    singleBillOfMaterials: 'Single_EKP',
  },
  users: {
    import: 'Importuj',
    name: 'Nazwa użytkownika',
    id: 'Id użytkownika',
    preferences: 'Preferencje',
    preferredArea: 'Preferowany obszar',
    preferredLine: 'Preferowana linia',
  },
  sidebar: {
    sku: 'SKU',
    settings: 'Ustawienia',
    planning: 'Planowanie',
    production: 'Produkcja',
    archive: 'Archiwum',
  },
  error: {
    internal: 'Błąd wewnętrzny',
    invalidPassword: 'Niewłaściwe hasło',
    wrongInput: 'Podane dane są nieprawidłowe!',
    invalidHour: 'Niewłaściwa godzina',
    invalidOrderNumber: 'Numer zlecenia może się składać tylko z cyfr',
    orderNumberIsTooLong: 'Numer zlecenia jest za długi',
    nameIsTooLong: 'Nazwa jest za długa',
    sizeIsTooLong: 'Rozmiar jest za długi',
    descriptionIsTooLong: 'Opis jest za długi',
    startDateIsGreaterThanEndDate: 'Data początkowa jest późniejsza niż data końcowa',
    requestFailed: 'Żądanie nie powiodło się',
    appNotLoadedProperly: 'Aplikacja GoodIn nie załadowała się w sposób prawidłowy.'
      + ' Niektóre funkcjonalności mogą być ograniczone, a prezentowane dane niekompletne.',
  },
  validation: {
    valid: 'VALID',
    invalid: 'INVALID',
    validationErrors: 'Błędy walidacji',
    validationErrorInfo: 'Występują błędy walidacyjne, nie można stworzyć zlecenia',
    productionOrderAlreadyExists: 'Zlecenie Produkcyjne stworzone z wybranych Zlecenia Roboczego i EKP już istnieje',
  },
  line: {
    line: 'Linia',
    lineName: 'Nazwa Linii',
    lines: 'Linie',
    selectLine: 'Wybierz Linię',
  },
  form: {
    form: 'Formularz',
    refresh: 'Odśwież',
    title: 'Tytuł',
    send: 'Wyślij',
    dataForm: 'Formularz Danych',
    noData: 'Brak Danych',
    dataSource: 'Źródło Danych',
    url: 'Wpisz adres URL do wagi',
    orderFieldInfo: 'Wybierz pole zlecenia',
    orderField: 'Pole Zlecenia',
    label: 'Etykieta',
    labelUpdated: 'Etykieta została zaktualizowana',
    required: 'Pole wymagane',
    checkedByDefault: 'Domyślnie zaznaczone',
    preprintedUfi: 'Wstępnie nadrukowany UFI',
    description: 'Opis',
    values: 'Wartości',
    text: 'Tekst',
    input: 'Pole tekstowe',
    select: 'Lista wyboru',
    textarea: 'Obszar tekstowy',
    checkbox: 'Pole wyboru',
    checklist: 'Pole wielokrotnego wyboru',
    radios: 'Pole opcji',
    textField: 'Tekst statyczny',
    addField: 'Dodaj pole',
    activeFileds: 'Aktywne pola',
    selectFieldType: 'Wybierz typ pola',
    fieldType: 'Typ pola',
    inputType: 'Typ odpowiedzi',
    labelValue: 'Wpisz pytanie / nazwę pola',
    fieldDescription: 'Opis pola',
    fieldValues: 'Wypisz wartości pól',
    name: 'Nazwa',
    type: 'Typ',
    chooseBackLabelToCreateOrder: 'Aby stworzyć zlecenie wybierz tylną etykietę',
    chooseEkpToCreateOrder: 'Aby stworzyć zlecenie wybierz eKP',
    editField: 'Wybierz pole aby rozpocząć edycję',
    startField: 'Dodaj pole aby rozpocząć edycję',
    qualityChecklist: 'Lista jakościowa',
    chooseOrSkanEkp: 'Wybierz lub zeskanuj eKP',
    selectOrderToSeeTheDetails: 'Wybierz zlecenie aby zobaczyć szczegóły',
    backLabel: 'Tylna etykieta',
    videoInspectionSummary: 'Podsumowanie inspekcji wideo',
    wo: 'ZR',
    po: 'ZP',
    workingOrders: 'Zlecenia Robocze',
    productionOrders: 'Zlecenia Produkcyjne',
    completedOrders: 'Zlecenia Zakończone',
    workOrder: 'Zlecenie Robocze',
    productionOrder: 'Zlecenie Produkcyjne',
    doYouWantToDelete: 'Czy chcesz usunąć',
    areSureYouWantToDelete: 'Czy na pewno chcesz usunąć to Zlecenie Produkcyjne',
    startImport: 'Start importu',
    uploadFile: 'Upuść plik tutaj żeby wysłać',
    singleEkp: 'Pojedyncze eKP',
    multipleEkp: 'Wiele eKP',
    multipleData: 'Wiele danych',
    importFile: 'Wybrany plik Excel',
    dataSaved: 'Dane zostały pomyślnie zapisane',
    importSuccessInfo: 'Import rozpoczął się pomyślnie',
    inspect: 'Sprawdź',
    importantInformation: 'Ważna informacja',
    optional: 'Opcjonalne',
    rowsOnThePage: 'Wierszy na stronie',
    showing: 'Wyświetlanie',
    results: 'Wyników',
    searchByOrderNumber: 'Wyszukaj po numerze zlecenia',
  },
  placeholders: {
    line: 'Wybierz linię',
    branchPlant: 'Wybierz obszar',
    orderNo: 'Podaj numer zlecenia',
    sku: 'Wybierz SKU',
    marketCode: 'Wybierz kod rynku',
    size: 'Podaj rozmiar',
    description: 'Podaj opis',
    ekp: 'Podaj eKP',
  },
  order: {
    createOrder: 'Stwórz zlecenie',
    updateOrder: 'Zaktualizuj zlecenie',
    activeOrders: 'Aktywne zlecenia',
    activeOrderDetails: 'Szczegóły Aktywnego Zlecenia',
    orderActivationHistory: 'Historia Aktywacji Zlecenia',
    orderHistory: 'Historia Zmian',
    actions: 'Akcje',
    details: 'Szczegóły',
    orderActivated: 'Aktywacje zlecenia: ',
    order: 'Zlecenie',
    orderUpdated: 'Zlecenie zostało zaktualizowane',
    orderCreated: 'Zlecenie zostało stworzone',
    orderReleased: 'Zlecenie zostało zwolnione',
    orderDeleted: 'Zlecenie zostało usunięte',
    orderUnreleased: 'Zlecenie zostało cofnięte',
    orders: 'Zlecenia',
    end: 'Zakończ Zlecenie',
    putOrderNumber: 'Podaj numer zlecenia',
    stop: 'Stop',
    work: 'Praca',
    hold: 'Nieaktywny',
    orderType: 'Typ Zlecenia',
    addOrderType: 'Dodaj Typ Zlecenia',
    selectOrderType: 'Wybierz Typ Zlecenia',
    orderTypes: 'Typy Zlecenia',
    mappings: 'Mapowanie Typu Zlecenia',
    displayName: 'Wyświetlana Nazwa',
    start: 'Start Zlecenia',
    activate: 'Aktywuj Zlecenie',
    orderNumber: 'Nr. Zlecenia',
    orderDetails: 'Szczegóły Zlecenia',
    fields: 'Pola zlecenia',
    field: 'Pole zlecenia',
    add: 'Dodaj zlecenie',
    badgeColor: 'Kolor Oznaczenia',
    marketCode: 'Kod rynku',
    market: 'Rynek',
    externalStatus: 'Status zewnętrzny',
    size: 'Rozmiar',
    status: {
      VALIDATED: 'VALIDATED',
      READY: 'RELEASED',
      INPROGRESS: 'INPROGRESS',
      ONHOLD: 'ON HOLD',
      COMPLETED: 'COMPLETED',
    },
    action: {
      CREATED: 'CREATED',
      STARTED: 'STARTED',
      UPDATED: 'UPDATED',
      VALIDATED: 'VALIDATED',
      RELEASED: 'RELEASED',
      HOLD: 'HOLD',
      UNRELEASED: 'UNRELEASED',
      COMPLETED: 'COMPLETED',
      DELETED: 'DELETED',
    },
  },
  selectors: {
    period: 'Okres',
    moment: 'Moment',
    selectExactDates: 'Wybierz dokładne daty',
    dateTo: 'Data do',
    dateFrom: 'Data od',
    shift: 'Zmiana',
    day: 'Dzień',
    week: 'Tydzień',
    month: 'Miesiąc',
    today: 'Dzisiaj',
    yesterday: 'Wczoraj',
    thisWeek: 'Ten tydzień',
    previousWeek: 'Poprzedni tydzień',
    thisMonth: 'Ten miesiąc',
    previousMonth: 'Poprzedni miesiąc',
    lastThreeMonths: 'Ostatnie 3 miesiące',
    branchPlant: 'Obszar',
    validationStatus: 'Status walidacji',
    ekpNumber: 'Numer eKP',
  },
  time: {
    days: 'dni',
    duration: 'Czas trwania',
    timeRange: 'Zakres czasu',
  },
};
