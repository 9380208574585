export const orderStatuses = {
  validated: 'VALIDATED',
  ready: 'READY',
  inProgress: 'INPROGRESS',
  onHold: 'ONHOLD',
  completed: 'COMPLETED',
};

export const sortDirections = {
  ascending: 1,
  descending: 2,
};

export default {
  sortDirections,
  orderStatuses,
};
