<template>
  <div>
    <Chartist
      id="donutPareto"
      ref="chart"
      :data="chartData"
      :event-handlers="eventHandlers"
      :options="chartOptions"
      ratio="ct-square"
      type="Pie"
    />
  </div>
</template>

<script>
import tinycolor from 'tinycolor2';

export default {
  props: {
    values: Array,
    labels: Array,
    colors: Array,
    hovered: Number,
  },
  data() {
    return {
      width: null,
      eventHandlers: [{
        event: 'draw',
        fn: ctx => {
          if (ctx.type === 'label') {
            const { index } = ctx;
            if (this.values[index] < 3) {
              ctx.element.attr({
                style: 'color: transparent; fill: transparent',
              });
            }
          }
          if (ctx.type === 'slice') {
            const { index } = ctx;
            ctx.element.attr({
              style: `fill: ${this.colors[index]}`,
            });
          }
        },
      }],
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        series: this.values,
      };
    },
    chartOptions() {
      return {
        donut: true,
        donutWidth: this.width && this.width < 250 ? 40 : 90,
        donutSolid: true,
        startAngle: 0,
        showLabel: true,
      };
    },
  },
  watch: {
    hovered(i) {
      if (!this.$refs.chart) return;
      const el = this.$refs.chart.$el.getElementsByClassName('ct-chart-donut')[0];
      if (!el) return;
      el.childNodes.forEach((node, index) => {
        const path = node.getElementsByTagName('path')[0];
        if (path && i !== index && i !== null) {
          const color = this.colors[index] || '#efefef';
          path.style.fill = tinycolor(color).setAlpha(0.2).toString();
        } else if (path) {
          path.style.fill = this.colors[index];
        }
      });
    },
  },
  mounted() {
    if (this.$refs.chart) {
      this.width = this.$refs.chart.$el.scrollWidth;
    }
  },
};
</script>

<style>
  #donutPareto .ct-chart-donut .ct-label {
    color: white;
    fill: white;
  }

  #donutPareto .ct-slice-donut-solid {
    transition: fill 500ms;
  }
  #donutPareto .other-alarm {
    color: #fff !important;
  }
</style>
