import Vue from 'vue';
import VueI18n from 'vue-i18n';
import moment from 'moment';
import plLocale from '@/utils/plMomentLocale';

// translations
import en from './en/index';
import pl from './pl/index';

// load necessary moment locales
require('moment/locale/pl');
require('moment/locale/en-gb');

Vue.use(VueI18n);

moment.updateLocale('pl', plLocale);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    pl,
  },
});

export default i18n;
