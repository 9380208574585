import Vue from 'vue';
import axios from 'axios';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import getOidcSettings, { settings, authorityStorageKey } from '@/api/oidc';
import { goodin } from '@/api/axios';
import { info } from '@core/utils/logger';

export default async function start({ store, vue }) {
  let config = {
    apiUrl: process.env.VUE_APP_API_URL,
    authUrl: process.env.VUE_APP_AUTH_URL,
    hubUrl: process.env.VUE_APP_HUB_URL,
    applicationVersion: process.env.VUE_APP_VERSION,
  };

  if (process.env.VUE_APP_REQUESTCONFIG === 'true') {
    const { data } = await axios.get(`${process.env.VUE_APP_PUBLIC_PATH}api/config`);
    config = {
      apiUrl: process.env.VUE_APP_PUBLIC_PATH,
      authUrl: data.authUrl,
      hubUrl: data.hubUrl,
    };
    if (!process.env.VUE_APP_VERSION || process.env.VUE_APP_VERSION === 'undefined') {
      config.applicationVersion = data.applicationVersion;
    } else {
      config.applicationVersion = process.env.VUE_APP_VERSION;
    }
  }

  settings.authority = config.authUrl;
  localStorage.setItem(authorityStorageKey, config.authUrl);

  store.registerModule('oidcStore',
    vuexOidcCreateStoreModule(getOidcSettings(), {
      namespaced: true,
      routeBase: process.env.VUE_APP_PUBLIC_PATH,
    }));

  goodin.defaults.baseURL = `${config.apiUrl}api`;

  info(`PUBLIC PATH: ${process.env.VUE_APP_PUBLIC_PATH}`);
  info(`API URL: ${config.apiUrl}`);
  info(`AUTH URL: ${config.authUrl}`);
  info(`VERSION: ${config.applicationVersion}`);
  store.commit('setConfig', config);

  /* eslint-disable no-new */
  return new Vue(vue);
}
