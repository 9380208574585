import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getQualityIssues',
    property: 'issues',
    path: () => '/internal/qualityIssuesDefinitions',
  })

  .getStore();

store.getters = {
};

export default store;
