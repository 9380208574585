<template>
  <div>
    <div
      v-if="tableItems.length > 0"
      class="row p-3"
    >
      <SimpleTable
        class="col-6 history-table pl-0"
        :items="tableItems"
        :fields="tableFields"
      >
        <template #cell(name)="{item}">
          <div class="row ml-0">
            <div
              class="color-box"
              :style="{ 'background-color': item.color }"
            />
            <span class="title pl-2">
              {{ item.name || '...' }}
            </span>
          </div>
        </template>

        <template #cell(number)="{item}">
          {{ item.number || '-' }}
        </template>

        <template #cell(percent)="{item}">
          {{ item.percent ? `${item.percent}%` : '-' }}
        </template>
      </SimpleTable>
      <div
        class="col-6 justify-content-center right-chart"
      >
        {{ $t('report.totalNumberOfErrors') }}: {{ errorsInTotal }}
        <DonutReport
          :values="donutValues"
          :labels="donutLabels"
          :colors="donutColors"
          class="report-chart donut-pareto pt-sm-3"
        />
      </div>
    </div>
    <div
      v-else
      style="min-height: 25vh"
    >
      <p
        class="text-center info"
      >
        {{ $t('form.noData') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SimpleTable from '@/components/tables/SimpleTable';
import DonutReport from '@/components/charts/DonutReport';

export default {
  props: {
    reportData: Object,
  },
  data: () => ({
    name: '',
    description: '',
  }),
  components: {
    SimpleTable,
    DonutReport,
  },
  computed: {
    ...mapState({
      qualityIssues: state => state.qualityIssues.issues,
    }),
    errorsInTotal() {
      const sumValues = Object.values(this.reportData?.incidentCounts || {}).reduce((a, b) => a + b, 0);
      return sumValues;
    },
    tableItems() {
      const data = this.reportData?.incidentCounts;
      const dataInArray = Object.entries(data).map(([name, value]) => ({ name, value }));
      const unknownError = {
        code: 'unknown',
        color: '#D04E2C',
        id: null,
        name: 'Unknown error',
        parentId: null,
      };
      const finalErrorList = dataInArray.map(d => {
        const error = this.qualityIssues.find(issue => d.name === issue.code)
          || unknownError;

        return {
          ...error,
          number: d.value,
          percent: ((d.value / this.errorsInTotal) * 100) > 0 && ((d.value / this.errorsInTotal) * 100) < 1
            ? '<1' : Math.round((d.value / this.errorsInTotal) * 100),
        };
      }).sort((a, b) => a.parentId?.localeCompare(b.parentId)) || [];
      return finalErrorList;
    },
    donutValues() {
      return this.tableItems.map(v => v.number);
    },
    donutLabels() {
      return this.tableItems.map(v => `${v.percent}%`);
    },
    donutColors() {
      return this.tableItems.map(v => v.color);
    },
    tableFields() {
      return [
        {
          key: 'name',
          label: 'Status',
        },
        {
          key: 'number',
          label: 'Number',
        },
        {
          key: 'percent',
          label: 'Percentage',
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~@/styles/vars.icss";

  .title {
    font-weight: 500;
    font-size: 0.9em;
  }

  .info {
    opacity: 0.6;
    font-size: 1.2em;
    margin-top: 15vh;
  }

  .color-box {
    margin-right: 10px;
    margin-top: 2px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #CED3D8;
    position: relative;
  }

  .right-chart {
    font-size: 1em;
    font-weight: 550;
    text-align: center;
  }

  .history-table {
    max-width: 350px;
  }

  .history-table :deep(th) {
    padding: 0 0.75rem !important;
  }

  .history-table :deep(tr) {
    transition: 0.2s all;
  }

  .history-table :deep(thead) {
    tr {
      height: 40px !important;
    }
  }

  .history-table :deep(tbody) {
    tr {
      height: 70px;
    }
  }

  .history-table :deep(.name) {
    min-width: 100px;
  }

  .history-table :deep(.number) {
    min-width: 100px;
  }

  .history-table :deep(.percent) {
    min-width: 100px;
  }

  .report-chart {
    min-height: 250px;
  }

  .donut-pareto {
    max-width: 450px !important;
  }

</style>
