<template>
  <div>
    <Tippy
      ref="popup"
      :placement="placement || 'right'"
      :distance="7"
      theme="light"
      interactive
      append-to="parent"
      trigger="click"
      arrow
      :on-show="onShow"
    >
      <template #trigger>
        <slot>
          <div
            class="select-box bg-white"
            style="border-radius: 0.25rem"
            :style="!selected.length ? 'text-align: right' : ''"
          >
            <i
              v-if="!selected.length"
              class="fas fa-caret-down p-2"
            />

            <div
              v-for="el in selected"
              :key="el.id"
              class="p-1"
            >
              <div>
                <i
                  v-if="icon"
                  :class="`${icon} pr-1`"
                />
                {{ el.name }}
              </div>

              <div
                v-if="hasUnit(value)"
                style="position: relative"
                @click.stop
              >
                <BFormInput
                  :ref="`input${el.id}`"
                  :value="value[el.id]"
                  size="sm"
                  style="border-radius: 10px"
                  @input="updateValue($event, el.id)"
                  @click="$refs[`input${el.id}`][0].focus(); $refs[`input${el.id}`][0].select()"
                />
                <span class="unit">
                  {{ unit }}
                </span>
              </div>
            </div>
          </div>
        </slot>
      </template>

      <div
        class="popover-container text-left"
      >
        <div class="mt-2">
          <BFormGroup>
            <BFormRadioGroup
              id="btn-radios-1"
              v-model="selectedView"
              button-variant="outline-warning"
              name="radio-btn-warning"
              size="sm"
              buttons
            >
              <template v-for="option in viewOptions">
                <BFormRadio
                  :key="option.text"
                  :value="option.value"
                >
                  <span class="py-2 px-3">
                    <i
                      :class="{
                        'fas fa-eye': option.value === 'active',
                        'fas fa-eye-slash': option.value === 'archived'
                      }"
                      class="pr-1"
                    />
                    {{ option.text }}
                  </span>
                </BFormRadio>
              </template>
            </BFormRadioGroup>
          </BFormGroup>
        </div>
        <div class="mx-0">
          <BFormInput
            v-if="searchable.length > 0"
            ref="search"
            v-model="search"
            size="sm"
            :placeholder="`${$t('general.search')}...`"
          />
          <div
            v-if="!pendingBoms"
            class="mt-2"
            style="height: 310px; overflow: auto"
          >
            <div
              v-for="w in list"
              :key="w.id"
              class="value-row"
              :class="{ selected: isSelected(w.id) }"
              @click="select(w.id)"
            >
              <i
                :class="`${icon} pr-1`"
              />
              <span style="color: black">
                {{ w.name }}
              </span>
            </div>
            <div
              v-if="!list.length"
              class="mt-5 info-text"
            >
              {{ $t('general.noItems') }}
            </div>
            <div class="pagination-align">
              <BPagination
                v-if="totalBomsRows"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalBomsRows"
                size="sm"
                pills
                limit="2"
                first-number
              />
            </div>
          </div>
          <Loader
            v-else
            color="goodin"
          />
        </div>
      </div>
    </Tippy>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    icon: String,
    value: [Array, Object, Number, String],
    single: Boolean,
    unit: String,
    searchable: {
      type: Array,
      default: () => [],
    },
    placement: String,
    selectedOrder: Object,
  },
  data: () => ({
    popover: false,
    pendingBoms: false,
    search: null,
    currentPage: 1,
    perPage: 10,
    totalBomsRows: 0,
    boms: null,
    selectedView: 'active',
  }),
  computed: {
    list() {
      return this.boms?.map(b => ({
        id: b.bomOverview.bomNumber,
        name: b.bomOverview.bomNumber,
      })) || [];
    },
    viewOptions() {
      return [
        { text: this.$t('general.active'), value: 'active' },
        { text: this.$t('general.archived'), value: 'archived' },
      ];
    },
    valueList() {
      if (!this.value) return [];
      if (Array.isArray(this.value)) return this.value;
      if ((typeof this.value) === 'number' || (typeof this.value) === 'string') return [this.value];
      return Object.keys(this.value);
    },
    selected() {
      return this.valueList.map(x => this.list.find(y => y.id === x))
        .filter(x => x);
    },
    selectedElement() {
      return this.boms.find(b => b.bomOverview.bomNumber === this.selected[0]?.id) || null;
    },
  },
  watch: {
    selectedOrder() {
      this.requestBoms();
    },
    value() {
      this.requestBoms();
      this.$emit('update:selected', this.selectedElement);
    },
    search() {
      this.requestBoms();
    },
    selectedView() {
      this.requestBoms();
    },
    currentPage() {
      this.requestBoms();
    },
    perPage() {
      this.requestBoms();
    },
  },
  methods: {
    ...mapActions([
      'getBoms',
    ]),
    requestBoms() {
      this.pendingBoms = true;
      this.getBoms({
        params: {
          query: {
            searchBomNumber: this.search || null,
            skuCodes: [this.selectedOrder?.skuNumber] || [],
            cardStatuses: [this.selectedView === 'active' ? 'published' : 'archive'],
            page: this.currentPage - 1,
            count: this.perPage,
          },
        },
      })
        .then(({ data }) => {
          this.boms = data?.items;
          this.totalBomsRows = data.totalCount;
        })
        .finally(() => {
          this.pendingBoms = false;
        });
    },
    hasUnit(value) {
      if (Array.isArray(value)) return false;
      if ((typeof this.value) === 'number' || (typeof this.value) === 'string') return false;
      return true;
    },
    onShow() {
      this.$nextTick(() => {
        if (this.$refs.search) {
          this.$refs.search.focus();
          this.$refs.search.select();
        }
      });
    },
    close() {
      this.$refs.popup.tippy().hide();
    },
    updateValue(v, id) {
      if (this.single) {
        this.$emit('update:value', v);
      }
      this.$emit('update:value', {
        ...this.value,
        [id]: v,
      });
    },
    isSelected(x) {
      return this.valueList.some(id => id === x);
    },
    select(id) {
      if (this.isSelected(id)) {
        if (this.single) {
          this.$emit('update:value', '');
          this.close();
        } else if (Array.isArray(this.value)) {
          this.$emit('update:value', this.value.filter(x => x !== id));
        } else {
          const res = { ...this.value };
          this.$delete(res, id);
          this.$emit('update:value', res);
        }
      } else if (this.single) {
        this.$emit('update:value', id);
        this.close();
      } else if (Array.isArray(this.value)) {
        this.$emit('update:value', this.value.concat([id]));
      } else {
        this.$emit('update:value', {
          ...this.value,
          [id]: 1,
        });
      }
    },
  },
  created() {
    this.requestBoms();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .tippy-popper {
    width: 400px;
  }

  .pagination-align {
    position: absolute;
    bottom: 0px;
  }

  #owner-select {
    .box {
      border: 1px solid #ced4da;
      border-radius: .2rem;
      padding: 2px 10px;
      cursor: pointer;

      &:hover {
        border: 1px solid rgba(100, 100, 100, 0.5);
      }
    }
  }

  .unit {
    position: absolute;
    top: 3px;
    right: 5px;
    font-size: 12px;
    color: rgb(100, 100, 100);
  }

  .popover-container {
    margin-top: -2px;
  }

  .value-row {
    padding: 2px 15px;
    cursor: pointer;
    line-height: 1.6;
    color: $goodin;

    &:hover {
      background-color: rgba(39, 33, 33, 0.1);
    }

    &.selected {
      font-weight: 650;
    }
  }

  .btn-outline-warning:not(:disabled):not(.disabled).active {
    color: white !important;
  }

  .btn-outline-warning:hover {
    color: white !important;
  }

  .close {
    cursor: pointer;
    font-size: 20px;
    position: relative;
    top: -2px;
  }

  .info-text {
    font-size: 1.1em;
    font-weight: 450;
    opacity: 0.7;
    text-align: center;
  }
</style>
