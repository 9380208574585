import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getOrderReport',
    property: 'data',
    path: ({ orderNumber }) => `/internal/reports/reports/order/production/${orderNumber}`,
  })

  .get({
    action: 'getIncidentImages',
    property: 'data',
    path: ({ incidentId }) => `/internal/reports/incident/${incidentId}/images`,
  })

  .get({
    action: 'getIncidentData',
    property: 'data',
    path: ({ incidentId }) => `/internal/reports/incident/${incidentId}`,
  })
  .getStore();

store.getters = {
};

export default store;
