import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import countriesList from 'country-list';

const store = new Vapi({
  axios,
  state: {
    lineList: [],
    areasList: [],
    skuList: [],
  },
})
  .get({
    action: 'getLines',
    property: 'lineList',
    path: () => '/internal/lines',
  })
  .get({
    action: 'getAreas',
    property: 'areasList',
    path: () => '/internal/areas',
  })
  .get({
    action: 'getSkus',
    property: 'skuList',
    path: () => '/internal/skus',
  })
  .get({
    action: 'getMarketCodes',
    property: 'data',
    path: () => '/internal/markets',
  })

  .getStore();

store.getters = {
  lines: s => s.lineList,
  linesFromArea: s => areaId => {
    if (!areaId) return s.lineList;
    return s.lineList.filter(l => l.areaId === areaId);
  },
  lineName: s => lineId => s.lineList.find(l => l.id === lineId)?.name,
  areas: s => s.areasList,
  skus: s => s.skuList,
  countries: () => countriesList,
};

export default store;
