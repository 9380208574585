export default {
  state: {
    screenWidth: 0,
    mobile: false,
    ready: false,
  },
  mutations: {
    setMobile(s, v) {
      s.mobile = v;
    },
    setReady(s, v) {
      s.ready = v;
    },
    setScreenWidth(s, v) {
      s.screenWidth = v;
    },
  },
  getters: {
    screenWidth: s => s.screenWidth,
    isMobile: s => s.isMobile,
    isReady: s => s.ready,
  },
};
