<template>
  <div id="app">
    <ScreenMeasure @mobile="setMobile" />
    <RouterView />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScreenMeasure from '@core/utils/screenMeasure';

export default {
  components: {
    ScreenMeasure,
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcIsAuthenticated']),
  },
  watch: {
    oidcIsAuthenticated() {
      this.init();
    },
  },
  methods: {
    setMobile(v) {
      this.$store.commit('setMobile', v);
    },
    async init() {
      if (!this.oidcIsAuthenticated) return;
      this.$store.dispatch('initUserPermissions', ['goodin', 'config']);
    },
  },
  created() {
    this.init();
  },
};
</script>
