import Qs from 'qs';

export const paramsSerializer = params => {
  const { query } = params;
  if (query) {
    return Qs.stringify(query, { arrayFormat: 'repeat' });
  }
  return null;
};

export const setHeaders = (ax, store) => {
  // eslint-disable-next-line consistent-return
  ax.interceptors.response.use(response => response, error => {
    if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch('oidcStore/signOutOidc');
      // handle error: inform user, go to login, etc
    } else {
      return Promise.reject(error);
    }
  });

  ax.interceptors.request.use(configuration => {
    const token = store.state.oidcStore.access_token;
    const conf = { ...configuration };
    if (token != null) {
      conf.headers.Authorization = `Bearer ${token}`;
    }

    const requestLang = localStorage.getItem('lang') || store.getters.language.lang;
    if (requestLang) {
      conf.headers['Accept-Language'] = requestLang;
    }

    return conf;
  }, err => Promise.reject(err));
};
