export function addSpaces(n) {
  if (!n || n < 1000) return n;
  const parts = n.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

export function round(v, precision) {
  const multiplier = 10 ** (precision || 2);

  return Math.round((v || 0) * multiplier) / multiplier;
}

export default {
  addSpaces, round,
};
