<template>
  <div class="p-3">
    <div>
      <ColumnError
        v-if="errorsList.length"
        :series="tableItems"
        :labels="labelsData"
      />
      <div
        v-else
        style="min-height: 25vh"
      >
        <p
          class="text-center info"
        >
          {{ $t('form.noData') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import ColumnError from '@/components/charts/ColumnError';

export default {
  props: {
    errorsList: Array,
    reportData: Object,
  },
  components: {
    ColumnError,
  },
  computed: {
    ...mapState({
      qualityIssues: state => state.qualityIssues.issues,
    }),
    tableItems() {
      const data = this.reportData?.incidentCounts;
      const dataInArray = Object.entries(data).map(([name, value]) => ({ name, value }));
      const unknownError = {
        code: 'unknown',
        color: '#D04E2C',
        id: null,
        name: 'Unknown error',
        parentId: null,
      };
      const finalErrorList = dataInArray.map(d => {
        const error = this.qualityIssues.find(issue => d.name === issue.code)
          || unknownError;

        return {
          ...error,
          errorCode: d.name,
          data: this.errorsList?.map(err => err.incidentCounts[d.name] || 0),
        };
      }).sort((a, b) => a.parentId?.localeCompare(b.parentId)) || [];
      return finalErrorList;
    },
    labelsData() {
      return this.errorsList?.map(err => [
        moment(err.timeRange?.from)?.format('DD.MM'),
        moment(err.timeRange?.from)?.format('HH:mm'),
      ]);
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~@/styles/vars.icss";

  .title {
    font-weight: 500;
    font-size: 0.9em;
  }

  .info {
    opacity: 0.6;
    font-size: 1.2em;
    margin-top: 15vh;
  }

</style>
