import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import lang from '@/lang';

const store = new Vapi({
  axios,
  state: {
    importList: [],
    importProps: [],
  },
})
  .get({
    action: 'getImports',
    property: 'importList',
    path: () => '/internal/imports',
  })
  .get({
    action: 'getImportProps',
    property: 'importList',
    path: ({ importId }) => `/internal/import/${importId}`,
  })
  .post({
    action: 'importSingleBom',
    path: ({ bomNumber }) => `/internal/import/bom/${bomNumber}`,
  })
  .post({
    action: 'importBom',
    path: () => '/internal/import/bom',
  })
  .post({
    action: 'importOrder',
    path: () => '/internal/import/order',
  })
  .post({
    action: 'importUfi',
    path: () => '/internal/import/ufi',
  })
  .post({
    action: 'importFile',
    path: () => '/internal/import/excel',
  })
  .getStore();

store.getters = {
  importTypes: () => [
    {
      type: 'WorkOrders',
      icon: 'fas fa-square-caret-right',
      text: `${lang.t('importTypes.workOrders')}`,
      color: '#FFCC0B',
    },
    {
      type: 'UniqueFormulaIdentifiers',
      icon: 'fas fa-flask',
      text: `${lang.t('importTypes.uniqueFormulaIndentifiers')}`,
      color: '#FF7448',
    },
    {
      type: 'SingleBillOfMaterials',
      icon: 'fas fa-folder-open',
      text: `${lang.t('importTypes.singleBillOfMaterials')}`,
      color: '#A985DE',
    },
    {
      type: 'BillOfMaterials',
      icon: 'fas fa-folder-open',
      text: `${lang.t('importTypes.billOfMaterials')}`,
      color: '#A985DE',
    },
  ],

  importStatuses: () => [
    {
      status: 'SCHEDULED',
      icon: 'fas fa-check',
      text: `${lang.t('imports.status.SCHEDULED')}`,
      color: '#FFAE48',
    },
    {
      status: 'INPROGRESS',
      icon: 'fas fa-play',
      text: `${lang.t('imports.status.INPROGRESS')}`,
      color: '#3E8DC0',
    },
    {
      status: 'FAILED',
      icon: 'fas fa-exclamation-triangle',
      text: `${lang.t('imports.status.FAILED')}`,
      color: '#ff7448',
    },
    {
      status: 'COMPLETED',
      icon: 'fas fa-check',
      text: `${lang.t('imports.status.COMPLETED')}`,
      color: '#39CB81',
    },
  ],

  triggerTypes: () => [
    {
      id: 'User',
      name: `${lang.t('general.manual')}`,
      iconName: 'fas fa-user',
    },
    {
      id: 'AutoTimeScheduler',
      name: `${lang.t('general.auto')}`,
      iconName: 'fas fa-retweet',
    },
  ],
};

export default store;
