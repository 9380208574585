<template>
  <div>
    <BTable
      table-class="simple-table"
      :fields="fields"
      :items="items"
      :selectable="selectable"
      v-bind="$attrs"
      v-on="$listeners"
      @row-clicked="$emit('selected', $event)"
    >
      <template
        v-for="(_, name) in $scopedSlots"
        #[name]="slotData"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
    </BTable>
    <p
      v-if="!items.length"
      class="text-center mt-5 info"
    >
      {{ $t('form.noData') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'SimpleTable',
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectable: Boolean,
  },
};
</script>

<style scoped lang="scss">
  .info {
    opacity: 0.6;
    font-size: 1.2em;
  }

  .simple-table :deep() {
    th {
      text-align: center;
    }
    th, td {
      border-color: #F5F5F5;
      text-align: center;
      padding-inline: 15px;
      vertical-align: middle !important;
      font-size: 0.75em;
      overflow-wrap: anywhere;

      &:first-child, &:last-child {
        text-align: unset;
      }
    }

    tbody {
      border-bottom: 1px solid #F5F5F5;

      tr {
        border-left: 1px solid #F5F5F5;
        border-right: 1px solid #F5F5F5;
        transition: 0.2s all;
        cursor: pointer;

        &:hover {
          background-color: #ECECEC;
        }
      }
    }

    thead {
      th {
        border-top: 0;
        background-color: #F5F5F5;

        &:first-child {
          border-radius: 10px 0 0 0;
        }
        &:last-child {
          border-radius: 0 10px 0 0;
        }
      }
    }
  }
</style>
