<template>
  <div class="text-uppercase text-white">
    <i
      v-tippy="{ arrow: true }"
      :content="$t('general.help')"
      class="fa fa-question-circle"
      @click="openPage"
    />
  </div>
</template>

<script>

export default {
  methods: {
    openPage() {
      window.open('https://ilabo.atlassian.net/wiki/spaces/LW/overview', 'User Guide');
    },
  },
};
</script>
