import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import lang from '@/lang';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getWorkingOrders',
    property: 'data',
    path: () => '/internal/orders/work',
  })
  .get({
    action: 'getProductionOrders',
    property: 'data',
    path: () => '/internal/orders/production',
  })
  .get({
    action: 'getBomsForProdOrders',
    property: 'data',
    path: () => '/internal/orders/production/boms',
  })
  .get({
    action: 'getProductionOrdersFromWorkingOrder',
    path: ({ workingOrderNumber }) => `/internal/orders/production/workOrder/${workingOrderNumber}`,
  })
  .post({
    action: 'createProdOrder',
    path: () => '/internal/orders/production',
  })
  .post({
    action: 'createNewWorkOrder',
    path: () => '/internal/orders/work',
  })
  .post({
    action: 'validateOrder',
    path: ({ orderNumber }) => `/internal/orders/work/${orderNumber}/validate`,
  })
  .put({
    action: 'editProdOrder',
    path: ({ orderNumber }) => `/internal/orders/production/${orderNumber}`,
  })
  .put({
    action: 'releaseProdOrder',
    path: ({ orderNumber }) => `/internal/orders/production/${orderNumber}/release`,
  })
  .put({
    action: 'unreleaseProdOrder',
    path: ({ orderNumber }) => `/internal/orders/production/${orderNumber}/unrelease`,
  })
  .delete({
    action: 'deleteProdOrder',
    path: ({ orderNumber }) => `/internal/orders/production/${orderNumber}`,
  })
  .getStore();

store.getters = {
  orderStatusesList: () => [
    {
      status: 'VALIDATED',
      icon: 'fas fa-check',
      name: `${lang.t('order.status.VALIDATED')}`,
      color: '#48ACA6',
    },
    {
      status: 'READY',
      icon: 'fas fa-list',
      name: `${lang.t('order.status.READY')}`,
      color: '#FFAE48',
    },
    {
      status: 'INPROGRESS',
      icon: 'fas fa-play',
      name: `${lang.t('order.status.INPROGRESS')}`,
      color: '#3E8DC0',
    },
    {
      status: 'ONHOLD',
      icon: 'fas fa-stop-circle',
      name: `${lang.t('order.status.ONHOLD')}`,
      color: '#838383',
    },
    {
      status: 'COMPLETED',
      icon: 'fas fa-check-circle',
      name: `${lang.t('order.status.COMPLETED')}`,
      color: '#39CB81',
    },
  ],

  orderBadge: () => [
    {
      status: 'ORDER',
      name: `${lang.t('order.order')}`,
      color: '#75af34',
    },
  ],

  orderActions: () => [
    {
      action: 'OrderCreated',
      name: `${lang.t('order.action.CREATED')}`,
      color: '#B9ADFB',
    },
    {
      action: 'OrderStarted',
      name: `${lang.t('order.action.STARTED')}`,
      color: '#B088EC',
    },
    {
      action: 'OrderUpdated',
      name: `${lang.t('order.action.UPDATED')}`,
      color: '#FBC600',
    },
    {
      action: 'OrderValidated',
      name: `${lang.t('order.action.VALIDATED')}`,
      color: '#48ACA6',
    },
    {
      action: 'OrderReleased',
      name: `${lang.t('order.action.RELEASED')}`,
      color: '#FF9C1D',
    },
    {
      action: 'OrderWithdrawn',
      name: `${lang.t('order.action.UNRELEASED')}`,
      color: '#4A9FFF',
    },
    {
      action: 'OrderOnHold',
      name: `${lang.t('order.action.HOLD')}`,
      color: '#9D9D9D',
    },
    {
      action: 'OrderCompleted',
      name: `${lang.t('order.action.COMPLETED')}`,
      color: '#71D47D',
    },
    {
      action: 'OrderDeleted',
      name: `${lang.t('order.action.DELETED')}`,
      color: '#FF7448',
    },
  ],

  actionUnknown: () => [
    {
      status: 'Unknown',
      name: 'UNKNOWN',
      color: '#75af34',
    },
  ],

  validBadges: () => [
    {
      type: 'VALID',
      name: `${lang.t('validation.valid')}`,
      color: '#48ACA6',
    },
    {
      type: 'INVALID',
      name: `${lang.t('validation.invalid')}`,
      color: '#E2734C',
    },
  ],

};

export default store;
