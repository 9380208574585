<template>
  <div
    v-if="error"
    class="text-danger"
  >
    {{ $t('auth.loginError') }}
    <div class="small">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'OidcCallback',
  data: () => ({
    error: null,
  }),
  methods: {
    ...mapActions(
      'oidcStore', ['oidcSignInCallback'],
    ),
  },
  mounted() {
    this.oidcSignInCallback()
      .then(redirectPath => {
        this.$router.push(redirectPath);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
        this.error = err;
      });
  },
};
</script>
