<template>
  <div class="order-execution">
    <div
      class="d-flex"
    >
      <div class="flex-grow-1">
        <div
          :style="{ 'background-color': orderType.color }"
          class="badge badge-success text-white mr-2"
        >
          <div
            class="px-2 text-uppercase badge-style"
          >
            {{ orderType.name }}
          </div>
        </div>
        <span class="title-style pr-1">
          {{ $t('order.orderNumber') }}:
        </span>
        <span class="title-style pr-1">
          {{ order.orderNumber || order.productionOrderNumber || '-' }}
        </span>
      </div>
      <div>
        <span class="title-style pr-1">
          {{ order.description || ' ' }}
        </span>
      </div>
    </div>
    <div
      class="d-flex pt-2"
      style="color: #595959"
    >
      <div class="font-weight-bold flex-grow-1">
        <div>
          <span class="text-style">
            {{ $t('sidebar.sku') }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.skuNumber || order.sku || '-' }}
          </span>
        </div>
        <div class="pt-2">
          <span class="text-style">
            {{ 'BP' }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.branchPlantCode || '-' }}
          </span>
        </div>
        <div
          v-if="extended || allPropsView"
          class="pt-2"
        >
          <span class="text-style">
            {{ $t('selectors.ekpNumber') }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.bomNumber || bomNumber || '-' }}
          </span>
        </div>
        <div
          v-if="extended || allPropsView"
          class="pt-2"
        >
          <span class="text-style">
            {{ $t('form.backLabel') }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.compMSN1 || msnCode || '-' }}
          </span>
        </div>
        <div
          v-if="allPropsView"
          class="pt-2"
        >
          <span class="text-style">
            {{ 'caQty' }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.caQty || '-' }}
          </span>
        </div>
        <div
          v-if="allPropsView"
          class="pt-2"
        >
          <span class="text-style">
            {{ 'eaQty' }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.eaQty || '-' }}
          </span>
        </div>
        <div
          v-if="allPropsView"
          class="pt-2"
        >
          <span class="text-style">
            {{ 'Unit 1' }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.unit1 || '-' }}
          </span>
        </div>
        <div
          v-if="allPropsView"
          class="pt-2"
        >
          <span class="text-style">
            {{ 'Unit 2' }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.unit2 || '-' }}
          </span>
        </div>
      </div>
      <div
        class="font-weight-bold"
        :class="extended || allPropsView ? 'col-6' : 'text-right'"
      >
        <div>
          <span class="text-style">
            {{ $t('line.line') }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.lineCode || '-' }}
          </span>
        </div>
        <div
          v-if="allPropsView"
          class="pt-2"
        >
          <span class="text-style">
            {{ $t('order.market') }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.market || '-' }}
          </span>
        </div>
        <div
          v-if="allPropsView"
          class="pt-2"
        >
          <span class="text-style">
            {{ $t('order.marketCode') }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.marketCode || '-' }}
          </span>
        </div>
        <div
          v-if="allPropsView"
          class="pt-2"
        >
          <span class="text-style">
            {{ $t('order.size') }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.size || '-' }}
          </span>
        </div>
        <div
          v-if="extended || allPropsView"
          class="pt-2"
        >
          <span class="text-style">
            {{ $t('form.description') }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.sizeDescription || '-' }}
          </span>
        </div>
        <div
          v-if="allPropsView"
          class="pt-2"
        >
          <span class="text-style">
            {{ $t('order.externalStatus') }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.externalStatus || '-' }}
          </span>
        </div>
        <div
          class="pt-2"
        >
          <span class="text-style">
            {{ order.start ? $t('date.start') : $t('date.plannedStart') }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.start || order.plannedExecutionStart }}
          </span>
        </div>
        <div
          v-if="extended || allPropsView"
          class="pt-2"
        >
          <span class="text-style">
            {{ order.end ? $t('date.end') : $t('date.plannedEnd') }}:
          </span>
          <span style="font-size: 0.8em">
            {{ order.end || order.plannedExecutionEnd }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="extended && report"
      class="col-6 pt-4 pl-0"
    >
      <div
        class="d-flex pb-2 justify-content-between font-weight-bold"
        style="font-size: 13px"
      >
        <div>
          {{ done }} |
          <span v-if="wasted">
            <span class="text-danger">
              {{ wasted }}
            </span> |
          </span>
          {{ order.eaQty || '0' }} {{ order.unit2 }}
        </div>
      </div>
      <ProductionChart
        :done="done"
        :wasted="wasted"
        :planned="order.eaQty"
        :unit="order.unit2"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import ProductionChart from '@/components/charts/production/ProductionChart';

export default {
  props: {
    order: Object,
    bomNumber: String,
    msnCode: String,
    report: Object,
    extended: {
      type: Boolean,
      default: false,
    },
    allPropsView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProductionChart,
  },
  filters: {
    formatDate(date) {
      if (!date) return '...';
      return moment(date).format('ll LTS');
    },
  },
  computed: {
    ...mapGetters([
      'orderBadge',
      'orderStatusesList',
      'validBadges',
    ]),
    done() {
      return this.report?.produced || 0;
    },
    wasted() {
      return this.report?.wasted || 0;
    },
    orderType() {
      const isValid = this.order?.isValid ? 'VALID' : 'INVALID';

      return this.orderStatusesList?.find(type => type.status === this.order?.status)
        || this.validBadges?.find(badge => badge.type === isValid);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .order-execution {
    padding-block: 15px;
    padding-inline: 20px;
    margin-block: 5px;
    line-height: 0.7;
    background-color: white;
    border-radius: 7px;
    box-shadow: 0 0 5px rgba(0, 0, 4, 0.1);

    &.detail {
      border: 1.75px solid;
      border-color: #5886C1;
    }
  }

  .badge {
    text-transform: uppercase;
    vertical-align: middle;
  }

  .text-style {
    font-size: 0.75em;
    font-weight: 650;
  }

  .title-style {
    font-size: 1em;
    font-weight: 500;
  }

  .badge-style {
    padding-top: 2px;
    font-size: 0.9em;
    font-weight: 650;
  }
</style>
