<template>
  <div>
    {{ $t('auth.noAccess') }}
  </div>
</template>

<script>
export default {

};
</script>
