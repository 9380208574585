<template>
  <div class="d-flex flex-column">
    <div class="text-left modal-title mb-2">
      <i class="pr-1" />
      {{ $t('order.orderDetails') }}
    </div>
    <div>
      <WorkOrder
        :extended="true"
        :order="order"
        :bom-number="bomNumber"
        :msn-code="msnCode"
        :all-props-view="true"
      />
    </div>
    <div
      v-if="validationErrors.length"
      class="warning-box px-3 py-2"
    >
      <div class="title">
        <i class="fas fa-triangle-exclamation" />
        {{ $t('validation.validationErrors') }}:
      </div>
      <div
        v-for="error in validationErrors"
        :key="error"
      >
        <div
          class="d-flex row"
        >
          <li
            class="col-1 px-0"
            style="text-align: right"
          />
          <div
            class="col-11 px-0"
            style="font-size: 0.9em"
          >
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import WorkOrder from '@/components/order/WorkOrder';

export default {
  props: {
    order: Object,
    bomNumber: String,
    msnCode: String,
  },
  data: () => ({
    validationErrors: [],
  }),
  components: {
    WorkOrder,
  },
  methods: {
    ...mapActions([
      'validateOrder',
    ]),
    checkOrderValidation() {
      this.validateOrder({
        params: {
          orderNumber: this.order?.orderNumber,
        },
      })
        .then(({ data }) => {
          this.validationErrors = data.validationErrors || [];
        });
    },
  },
  created() {
    this.checkOrderValidation();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .title {
    font-size: 1.0em;
    font-weight: 500 !important;
  }

  .modal-title {
    font-size: 1.6em;
    font-weight: 500;
  }

  .warning-box {
    padding-block: 5px;
    margin: 10px 0px;
    border-radius: 7px;
    background-color: #FFEFEE;
    color: #D70819;
    flex-flow: row;
  }

</style>
