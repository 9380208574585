var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar-chart"},_vm._l((_vm.normalised),function(el){return _c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
      arrow: true,
      trigger: _vm.tooltipFn ? 'mouseenter' : 'manual'
    }),expression:"{\n      arrow: true,\n      trigger: tooltipFn ? 'mouseenter' : 'manual'\n    }"}],key:el.id,staticClass:"chart-block",style:({
      width: `${el.value}%`,
      left: `${el.left}%`,
      'background-color': el.color,
    }),attrs:{"content":_vm.tooltipFn ? _vm.tooltipFn(el) : null}},[_c('div',[(el.value > 5 && !_vm.hideLabels)?_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(el.label)+" ")]):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }