var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history-box"},[_c('div',{staticClass:"order-history",class:_vm.openHistory ? 'open-details' : 'none-details'},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"col-3",staticStyle:{"align-self":"center"}},[_c('div',{staticClass:"badge badge-success text-white mr-2",style:({ 'background-color': _vm.orderType.color })},[_c('div',{staticClass:"px-2 text-uppercase badge-style"},[_vm._v(" "+_vm._s(_vm.orderType.name)+" ")])])]),_c('div',{staticClass:"col-4 pl-0",staticStyle:{"align-self":"center"}},[_c('div',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.time)+" ")])]),_c('div',{staticClass:"col-5 d-flex justify-content-between row",staticStyle:{"align-self":"center"}},[_c('div',{staticClass:"row mb-0 mr-2"},[_c('i',{staticClass:"fas pr-2",class:{
              'fa-user-circle': _vm.userDetails?.firstName || _vm.userDetails?.lastName,
              'fa-dot-circle': !_vm.userDetails?.firstName && !_vm.userDetails?.lastName,
            },staticStyle:{"font-size":"1.75em","opacity":"0.25"}}),_c('div',{staticStyle:{"font-size":"16px","align-self":"center"}},[(_vm.userDetails?.firstName || _vm.userDetails?.lastName)?_c('span',[_vm._v(" "+_vm._s(_vm.userDetails?.firstName)+" "+_vm._s(_vm.userDetails?.lastName)+" ")]):_c('span',[_vm._v(" Vision System ")])])]),(_vm.phrasedFields.length || _vm.comment)?_c('div',[_c('div',{staticClass:"history-style pr-3",staticStyle:{"font-size":"1.5em","opacity":"0.40"},on:{"click":function($event){_vm.openHistory = !_vm.openHistory}}},[_c('i',{class:_vm.openHistory ? 'ion ion-ios-arrow-up' : 'ion ion-ios-arrow-down'})])]):_vm._e()])])]),(_vm.openHistory && _vm.comment)?_c('div',{staticClass:"d-flex py-3",class:_vm.phrasedFields.length ? 'comment' : 'comment-with-ending-line',staticStyle:{"background-color":"#FFFFFF"}},[_c('div',{staticClass:"col-3 text-style"},[_vm._v(" "+_vm._s(_vm.$t('report.comment'))+" ")]),_c('div',{staticClass:"col-9 comment-style"},[_vm._v(" \""+_vm._s(_vm.comment)+"\" ")])]):_vm._e(),(_vm.openHistory && _vm.phrasedFields.length)?_c('div',{staticClass:"table-style",staticStyle:{"background-color":"#F6F6F6"}},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('div',{staticClass:"row px-2"},[_c('th',{staticClass:"col-3 text-style pl-1"},[_vm._v(" "+_vm._s(_vm.$t('report.key'))+" ")]),_c('th',{staticClass:"col-4 text-style pr-2"},[_vm._v(" "+_vm._s(_vm.$t('report.oldValue'))+" ")]),_c('th',{staticClass:"col-5 text-style pl-3"},[_vm._v(" "+_vm._s(_vm.$t('report.newValue'))+" ")])])])]),_c('tbody',_vm._l((_vm.phrasedFields),function(prop,key){return _c('tr',{key:key},[_c('div',{staticClass:"table-row row px-2"},[_c('td',{staticClass:"col-3 pl-1"},[_c('div',{staticClass:"borderRight"},[_vm._v(" "+_vm._s(prop.FieldName)+" ")])]),_c('td',{staticClass:"col-4 pr-2",staticStyle:{"display":"inline-flex"}},[_c('div',{staticClass:"px-2 flex-grow-1",class:{
                  unchanged: _vm.isValueUnchanged(prop),
                  'old-value': !!prop.OldValue,
                  'no-value': !prop.OldValue,
                }},[_vm._v(" "+_vm._s(prop.OldValue)+" ")]),_vm._m(0,true)]),_c('td',{staticClass:"col-5 pl-3",staticStyle:{"display":"inline-flex"}},[_c('div',{staticClass:"px-2 flex-grow-1",class:{
                  unchanged: _vm.isValueUnchanged(prop),
                  'new-value': !!prop.NewValue,
                  'no-value': !prop.NewValue,
                }},[_vm._v(" "+_vm._s(prop.NewValue)+" ")])])])])}),0)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fas fa-arrow-right pl-3",staticStyle:{"font-size":"0.75em"}})])
}]

export { render, staticRenderFns }