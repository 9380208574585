<template>
  <div class="sidebar">
    <div
      v-for="r in permittedRoutes"
      :key="r.key"
      class="route d-flex flex-column justify-content-center"
      :class="{ selected: r.route === page }"
      @click="select(r)"
    >
      <div class="icon">
        <i :class="r.icon" />
      </div>
      <div class="text">
        {{ r.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    page: String,
  },
  computed: {
    ...mapGetters(['permittedRoutes']),
  },
  methods: {
    select(r) {
      this.$router.push(r.route).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .sidebar {
    width: 80px;
    background-color: rgba(223, 223, 223, 1) ;
    margin-top: -50px;
    padding-top: 50px;
    padding-left: 3px;
    padding-right: 3px;
  }

  .route {
    text-align:  center;
    margin: 12px 0;
    cursor: pointer;
    width: 70px;
    height: 70px;
    border-radius: 50%;

    .text {
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
    }

    .icon {
      font-size: 27px;
      line-height: 1.1;
      transition: transform 200ms;
      color: rgba(#333, 0.7);
    }

    &:hover, &.selected {
      background-color: rgba(#333, 0.1);

      .icon {
        color: #333;
      }
    }

    &:hover .icon {
      transform: scale(1.2);
    }
  }

</style>
