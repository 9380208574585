<template>
  <div>
    <div
      style="font-size: 1.6em; font-weight: 650"
      class="mb-0"
    >
      <i class="fas fa-business-time pr-2" />
      {{ $t('sidebar.production') }}
    </div>
    <div
      class="d-flex mr-3"
      style="min-height: 70vh"
    >
      <div class="col-9 d-flex flex-column px-4 py-3 my-4 orders-box-style">
        <div>
          <div class="title pb-2">
            <i class="fas fa-caret-square-right pr-1" />
            {{ $t('form.po') }} - {{ $t('form.productionOrders') }}
          </div>
          <hr>
          <div class="d-flex">
            <div class="col-12 box-style">
              <div class="d-flex justify-content-between row">
                <div class="row ml-1">
                  <div class="pr-3 my-3">
                    <div class="title-style">
                      {{ $t('general.search') }}
                    </div>
                    <BFormInput
                      ref="search"
                      v-model="search"
                      size="sm"
                      style="min-width: 225px; height: 26px; font-size: 0.8em"
                      :placeholder="`${$t('form.searchByOrderNumber')}...`"
                      type="search"
                    />
                  </div>
                  <div
                    class="divide-line mr-3"
                  />
                  <AreaFilterSelector
                    :box-height="'26px'"
                  />
                  <LineFilterSelector
                    :box-height="'26px'"
                  />
                  <ProSelect
                    v-model="selectedStatus"
                    :options="statuses"
                    show-all-option
                    :label="$t('general.orderStatus')"
                    class="pr-3 my-3"
                    :box-height="'26px'"
                    icon-class="fas fa-bars-progress"
                    icon-color-class="app-color"
                  />
                  <ProSelect
                    v-model="bomCode"
                    :options="bomsOptions"
                    show-all-option
                    show-search
                    :label="$t('selectors.ekpNumber')"
                    class="pr-3 my-3"
                    :box-height="'26px'"
                    icon-class="fas fa-layer-group"
                    icon-color-class="app-color"
                  />
                </div>
                <div
                  class="my-3"
                  style="margin-left: -10px;"
                >
                  <DatePicker
                    ref="dateItemPicker"
                    :start-date="dates.startDate"
                    :end-date="dates.endDate"
                    :custom-height="'26px'"
                    @setDates="dates = $event"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div
              class="box-style pb-5"
              :class="selectedOrder ? 'col-9' : 'col-12'"
            >
              <Loader
                v-if="pendingOrders"
                color="goodin"
              />
              <div>
                <div v-if="!pendingOrders">
                  <div
                    v-if="selectedOrder"
                    ref="listContainer"
                    class="orders-list"
                    style="max-height: 50vh"
                  >
                    <WorkOrder
                      v-for="o in orders"
                      :key="o.productionOrderNumber"
                      :order="o"
                      class="hoverable"
                      :class="o.productionOrderNumber === selected ? 'selected' : ''"
                      @click.native="selected === o.productionOrderNumber
                        ? selected = null : (selected = o.productionOrderNumber)"
                    />
                  </div>
                  <div
                    v-else
                    ref="listContainer"
                    class="orders-list"
                    style="max-height: 50vh"
                  >
                    <TransitionGroup
                      v-if="orders.length"
                      name="full-order-list"
                    >
                      <WorkOrder
                        v-for="o in orders"
                        :key="o.productionOrderNumber"
                        :order="o"
                        class="hoverable"
                        @click.native="selected === o.productionOrderNumber
                          ? selected = null : (selected = o.productionOrderNumber)"
                      />
                    </TransitionGroup>
                    <div
                      v-else
                      class="mt-5 info-text"
                    >
                      {{ $t('form.noData') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination-align">
              <BPagination
                v-if="totalOrdersRows"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalOrdersRows"
                size="sm"
                pills
                limit="5"
                first-number
              />
            </div>
            <div
              v-if="selectedOrder"
              class="col-3"
            >
              <div class="action-style">
                <span class="title">
                  <i class="fas fa-list pb-1" />
                  {{ $t('order.actions') }}
                </span>
                <hr>
                <div
                  v-if="selectedOrder.status === orderStatuses.validated"
                  class="d-flex"
                >
                  <button
                    class="actions-button btn-primary btn btn-sm"
                    type="button"
                    @click="releaseOrder"
                  >
                    <BSpinner
                      v-if="pendingRelease"
                      variant="light"
                      small
                      class="mr-1"
                    />
                    <i
                      v-else
                      class="fas fa-rocket pr-2"
                    />
                    {{ $t('actions.release') }}
                  </button>
                </div>
                <div
                  v-if="selectedOrder.status === orderStatuses.onHold || selectedOrder.status === orderStatuses.ready"
                  class="d-flex"
                >
                  <button
                    class="actions-button btn-primary btn btn-sm"
                    type="button"
                    @click="releaseOrder"
                  >
                    <BSpinner
                      v-if="pendingRelease"
                      variant="dark"
                      small
                      class="mr-1"
                    />
                    <i
                      v-else
                      class="fas fa-rocket pr-2"
                    />
                    {{ $t('actions.releaseAgain') }}
                  </button>
                </div>
                <div
                  v-if="selectedOrder.status === orderStatuses.inProgress"
                  class="d-flex"
                >
                  <button
                    class="actions-button btn btn-outline-secondary btn-sm"
                    type="button"
                    @click="editForm = true"
                  >
                    <i
                      class="fas fa-eye pr-2"
                    />
                    {{ $t('general.preview') }}
                  </button>
                </div>
                <div
                  v-if="selectedOrder.status === orderStatuses.validated
                    || selectedOrder.status === orderStatuses.onHold"
                  class="d-flex"
                >
                  <button
                    class="actions-button btn btn-outline-secondary btn-sm"
                    type="button"
                    @click="editForm = true"
                  >
                    <BSpinner
                      v-if="pendingEdit"
                      variant="dark"
                      small
                      class="mr-1"
                    />
                    <i
                      v-else
                      class="ion ion-md-create pr-2"
                    />
                    {{ $t('actions.edit') }}
                  </button>
                </div>
                <div
                  v-if="selectedOrder.status === orderStatuses.ready"
                  class="d-flex"
                >
                  <button
                    class="actions-button btn btn-outline-secondary btn-sm"
                    type="button"
                    @click="editForm = true"
                  >
                    <i class="fas fa-check pr-2" />
                    {{ $t('form.inspect') }}
                  </button>
                </div>
                <div
                  v-if="selectedOrder.status === orderStatuses.ready"
                  class="d-flex"
                >
                  <button
                    class="actions-button btn btn-outline-secondary btn-sm"
                    type="button"
                    @click="unreleaseOrder"
                  >
                    <BSpinner
                      v-if="pendingUnrelease"
                      variant="dark"
                      small
                      class="mr-1"
                    />
                    <i
                      v-else
                      class="ion ion-md-refresh pr-2"
                    />
                    {{ $t('actions.unrelease') }}
                  </button>
                </div>
                <div
                  v-if="selectedOrder.status === orderStatuses.validated"
                  class="d-flex"
                >
                  <button
                    class="actions-button btn btn-outline-secondary btn-sm"
                    type="button"
                    @click="removeModal = true"
                  >
                    <BSpinner
                      v-if="pendingDelete"
                      variant="dark"
                      small
                      class="mr-1"
                    />
                    <i
                      v-else
                      class="ion ion-md-trash pr-2"
                    />
                    {{ $t('actions.deleteOrder') }}
                  </button>
                </div>
                <hr>
                <div
                  style="height: 25vh; overflow-y: auto"
                >
                  <CheckList
                    :show-edit="true"
                    :order="selectedOrder"
                    :pending-orders="pendingOrders"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-3 d-flex flex-column my-4 px-4 py-3 ml-3 wo-box-style"
        style="max-height: 80vh"
      >
        <div class="title mt-1">
          <i class="fas fa-info-circle pr-1" />
          {{ $t('form.po') }} - {{ $t('general.details') }}
        </div>
        <div
          v-if="selectedOrder"
          class="py-1"
          style="overflow-y: auto"
        >
          <ProductionOrdersDetails
            :order="selectedOrder"
            :order-bom-data="orderBomData"
            class="transition-item"
          />
        </div>
        <div
          v-else
          class="py-1 mt-1"
          style="overflow-y: auto"
        >
          <hr>
          <div class="mt-4 info-text">
            {{ $t('form.selectOrderToSeeTheDetails') }}
          </div>
        </div>
      </div>
    </div>
    <LModal
      size="lg"
      :show.sync="removeModal"
    >
      <div
        class="text-center mt-3"
      >
        <div class="modal-info-text m-4">
          {{ $t('form.areSureYouWantToDelete') }} ?
        </div>
      </div>
      <hr>
      <div class="pt-2 text-center">
        <button
          class="actions-button btn btn-secondary btn-sm mx-2"
          type="button"
          @click="removeModal = false"
        >
          <i
            class="ion ion-md-close pr-2"
          />
          {{ $t('general.cancel') }}
        </button>

        <button
          class="actions-button btn btn-primary btn-sm"
          type="submit"
          :disabled="pendingDelete"
          @click="deleteOrder"
        >
          <BSpinner
            v-if="pendingDelete"
            variant="light"
            small
            class="mr-1"
          />
          <i
            v-else
            class="ion ion-md-trash align-self-center pr-2"
          />
          <span>
            {{ $t('general.delete') }}
          </span>
        </button>
      </div>
    </LModal>

    <LModal
      size="lg"
      class="p-5"
      :show.sync="editForm"
    >
      <OrderEditAndCreate
        v-if="selectedOrder"
        :show-edit="true"
        :order-bom-data="orderBomData"
        :can-line-be-edited="selectedOrder.status === orderStatuses.validated"
        :order="selectedOrder"
        :bom-number="selectedOrder.bomNumber"
        :msn-code="selectedOrder.compMSN1"
        :ufi-code="selectedOrder.ufiCode"
        @lineId="lineId = $event"
        @checkList="checkList = $event"
        @close="editForm = false"
        @update="updateOrder($event, false)"
        @release="releaseOrder()"
        @updateAndRelease="updateOrder($event, true)"
      />
    </LModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import loopRequest from '@core/mixins/loopRequest';
import WorkOrder from '@/components/order/WorkOrder';
import CheckList from '@/components/selectors/CheckList';
import ProductionOrdersDetails from '@/components/order/ProductionOrdersDetails';
import OrderEditAndCreate from '@/components/order/OrderEditAndCreate';
import { orderStatuses, sortDirections } from '@/utils/dictionary';
import DatePicker from '@/components/selectors/DatePicker';
import AreaFilterSelector from '@/components/selectors/AreaFilterSelector';
import LineFilterSelector from '@/components/selectors/LineFilterSelector';

export default {
  data: () => ({
    lineId: null,
    selected: null,
    editForm: false,
    selectedStatus: null,
    search: '',
    currentPage: 1,
    perPage: 20,
    totalOrdersRows: 0,
    productionOrders: null,
    pendingOrders: true,
    pendingUnrelease: false,
    pendingRelease: false,
    pendingDelete: false,
    pendingEdit: false,
    bomCode: null,
    checkList: null,
    removeModal: false,
    boms: null,
    dates: {
      startDate: null,
      endDate: null,
    },
    orderBomData: {},
    orderStatuses,
  }),
  components: {
    WorkOrder,
    ProductionOrdersDetails,
    CheckList,
    OrderEditAndCreate,
    DatePicker,
    AreaFilterSelector,
    LineFilterSelector,
  },
  mixins: [loopRequest('requestOrders', 10000)],
  computed: {
    ...mapGetters([
      'settings',
      'orderStatusesList',
      'fromPlantTimeToUtc',
      'lines',
      'lineName',
      'linesFromArea',
      'selectedFilterAreaId',
      'selectedFilterLineId',
    ]),
    filteredLines() {
      const selectedLines = this.linesFromArea(this.selectedFilterAreaId);
      return selectedLines.length ? selectedLines : this.lines;
    },
    orders() {
      if (!this.filteredLines || !this.productionOrders) return [];

      return this.productionOrders.map(order => {
        const line = this.filteredLines.find(l => l.id === order.lineId);

        return {
          ...order,
          lineName: line ? line.name : '',
          created: order.created ? this.showRightTime(order.created) : '',
          updated: order.updated ? this.showRightTime(order.updated) : '',
          lastStatusUpdate: order.lastStatusUpdate ? this.showRightTime(order.lastStatusUpdate) : '',
          plannedExecutionStart: order.plannedExecutionStart ? this.showRightTime(order.plannedExecutionStart) : '',
          plannedExecutionEnd: order.plannedExecutionEnd ? this.showRightTime(order.plannedExecutionEnd) : '',
        };
      });
    },
    statuses() {
      return this.orderStatusesList.filter(o => o.status !== orderStatuses.completed).map(s => ({
        ...s,
        label: s.name,
        value: s.status.toLowerCase(),
        iconClass: s.icon,
        color: s.color,
      })) || [];
    },
    bomsOptions() {
      return this.boms
        ?.map(b => ({
          ...b,
          label: b.name,
          value: b.id,
          iconClass: 'fas fa-layer-group app-color',
        })) || [];
    },
    selectedOrder() {
      return this.orders.find(x => x.productionOrderNumber === this.selected);
    },
    filters() {
      return {
        selectedFilterAreaId: this.selectedFilterAreaId,
        selectedFilterLineId: this.selectedFilterLineId,
        dates: this.dates,
        search: this.search,
        perPage: this.perPage,
        currentPage: this.currentPage,
        selectedStatus: this.selectedStatus,
        bomCode: this.bomCode,
      };
    },
  },
  watch: {
    selectedOrder() {
      this.getData();
    },
    filters() {
      this.pendingOrders = true;
      this.requestOrders();
    },
  },
  methods: {
    ...mapActions([
      'getProductionOrders',
      'releaseProdOrder',
      'unreleaseProdOrder',
      'deleteProdOrder',
      'editProdOrder',
      'getBomsForProdOrders',
      'getBom',
    ]),
    getData() {
      if (this.selectedOrder?.bomNumber) {
        this.getBom({
          params: {
            bomNumber: this.selectedOrder.bomNumber,
          },
        })
          .then(({ data }) => {
            this.orderBomData = data;
          });
      }
    },
    showRightTime(date) {
      const plantUtcOffset = moment.tz(this.settings?.plantTimeZone).utcOffset();
      return moment.utc(date).add(plantUtcOffset, 'minutes').format('ll LTS');
    },
    valueOfACheck(name) {
      return this.checkList?.find(c => c.name === name)?.value;
    },
    showSuccessMsg(msg) {
      this.$bvToast.toast(msg || this.$t('general.success'), {
        title: this.$t('general.success'),
        variant: 'success',
        autoHideDelay: 2000,
      });
    },
    showFailedMsg(msg) {
      this.$bvToast.toast(msg || this.$t('error.requestFailed'), {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: 4000,
      });
    },
    requestBoms() {
      this.getBomsForProdOrders({
        params: {
          query: {
            orderStatuses: [
              orderStatuses.onHold,
              orderStatuses.inProgress,
              orderStatuses.validated,
              orderStatuses.ready,
            ],
          },
        },
      })
        .then(({ data }) => {
          this.boms = data.map(x => ({ id: x, name: x })) || [];
        });
    },
    updateOrder(comment, release) {
      this.pendingEdit = true;
      this.editProdOrder({
        params: {
          orderNumber: this.selected,
        },
        data: {
          lineCode: this.lineName(this.lineId),
          qualityCheckList: {
            printBatchCode: this.valueOfACheck('printBatchCode'),
            validateBatchCode: this.valueOfACheck('validateBatchCode'),
            printUfi: this.valueOfACheck('printUfi'),
            validateUfi: this.valueOfACheck('validateUfi'),
            validatePrePrintedUfi: this.valueOfACheck('validatePrePrintedUfi'),
            validateEan: this.valueOfACheck('validateEan'),
            validateCap: this.valueOfACheck('validateCap'),
            validateTrigger: this.valueOfACheck('validateTrigger'),
            validateFrontLabel: this.valueOfACheck('validateFrontLabel'),
            validateBackLabel: this.valueOfACheck('validateBackLabel'),
            validateProductRotation: this.valueOfACheck('validateProductRotation'),
          },
          updateComment: comment,
        },
      })
        .then(() => {
          this.requestOrders();
          this.showSuccessMsg(this.$t('order.orderUpdated'));
          if (release) {
            this.releaseOrder();
          }
        })
        .catch(({ response: { data } }) => {
          const message = data.errors ? JSON.stringify(data.errors) : data.detail;
          this.showFailedMsg(message);
        })
        .finally(() => {
          this.pendingEdit = false;
        });
    },
    deleteOrder() {
      this.pendingDelete = true;
      this.deleteProdOrder({
        params: {
          orderNumber: this.selected,
        },
      })
        .then(() => {
          this.requestOrders();
          this.showSuccessMsg(this.$t('order.orderDeleted'));
        })
        .catch(({ response: { data } }) => {
          this.showFailedMsg(data.detail);
        })
        .finally(() => {
          this.pendingDelete = false;
          this.removeModal = false;
        });
    },
    unreleaseOrder() {
      this.pendingUnrelease = true;
      this.unreleaseProdOrder({
        params: {
          orderNumber: this.selected,
        },
      })
        .then(() => {
          this.requestOrders();
          this.showSuccessMsg(this.$t('order.orderUnreleased'));
        })
        .catch(({ response: { data } }) => {
          this.showFailedMsg(data.detail);
        })
        .finally(() => {
          this.pendingUnrelease = false;
        });
    },
    releaseOrder() {
      this.pendingRelease = true;
      this.releaseProdOrder({
        params: {
          orderNumber: this.selected,
        },
      })
        .then(() => {
          this.requestOrders();
          this.showSuccessMsg(this.$t('order.orderReleased'));
        })
        .catch(({ response: { data } }) => {
          this.showFailedMsg(data.detail);
        })
        .finally(() => {
          this.pendingRelease = false;
        });
    },
    requestOrders() {
      if (this.removeModal || this.editForm) {
        return;
      }
      const lines = this.selectedFilterAreaId && !this.selectedFilterLineId
        ? this.filteredLines.map(x => [x.name])
        : this.lineName(this.selectedFilterLineId);
      const query = {};

      if (this.dates?.startDate) {
        this.$set(query, 'from', this.dates.startDate);
      }

      if (this.dates?.endDate) {
        this.$set(query, 'to', this.dates.endDate);
      }

      this.getProductionOrders({
        params: {
          query: {
            searchOrderNumber: this.search,
            page: this.currentPage - 1,
            count: this.perPage,
            sortDirection: sortDirections.ascending,
            lineCodes: lines || [],
            areaIds: this.selectedFilterAreaId || [],
            statuses: this.selectedStatus || this.statuses.map(s => s.status),
            bomCodes: this.bomCode || [],
            ...query,
          },
        },
      })
        .then(({ data }) => {
          this.productionOrders = data?.items;
          this.totalOrdersRows = data.totalCount;
        })
        .finally(() => {
          this.pendingOrders = false;
        });
    },
  },
  created() {
    this.requestBoms();
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .title {
    font-size: 1.2em;
    font-weight: 500;
  }

  .close-icon {
    color:#6c757d;
    cursor: pointer;

    &:hover {
      color: #332e34;
    }
  }

  .pagination-align {
    position: absolute;
    bottom: 0px;
  }

  .transition-item {
    transition: 300ms all;
    width: 100%;
    left: 0;
  }

  .modal-info-text {
    font-size: 1.8em;
    font-weight: 600;
    padding-inline: 100px;
  }

  .modal-title {
    font-size: 1.5em;
    font-weight: 500;
  }

  .title-style {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #848484;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .divide-line {
    border-right: 1px solid gray;
    height: 26px;
    margin-top: 28px
  }

  .date-selector {
    margin-top: 15px;
    margin-bottom: 8px;
    margin-left: -15px;
  }

  .box-style {
    padding-inline: 12px;
  }

  .orders-box-style {
    margin-top: 10px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .wo-box-style {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .action-style {
    padding: 20px;
    margin-top: 10px;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .info-text {
    font-size: 1.1em;
    font-weight: 450;
    opacity: 0.7;
    text-align: center;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  .actions-button {
    text-align: left;
    margin: 5px 0px;
    flex-grow: 1;
    font-size: 1em;
    font-weight: 500;
  }

  .orders-list {
    padding-inline: 2px;
    overflow-y: auto;
  }

  .hoverable {
    cursor: pointer;
    margin-block: 10px;

    &:hover {
      background-color: rgba(200, 200, 200, 0.1);
    }

    &.selected {
      background-color: #EBEBEB;
      border-color: #333;
    }
  }
</style>
