const config = ({ authority, publicPath }) => ({
  authority,
  clientId: 'goodin_ui',
  redirectUri: `${window.location.origin}${publicPath}authentication/login-callback`,
  post_logout_redirect_uri: `${window.location.origin}${publicPath}`,
  silent_redirect_uri: `${window.location.origin}/silent-renew-oidc.html`,
  responseType: 'code',
  scope: 'openid profile goodin.api Logix.Hub logix.auth',
  accessTokenExpiringNotificationTime: 10,
  automaticSilentRenew: true,
  automaticSilentSignin: false,
  monitorSession: false,
  loadUserInfo: true,
});

export const authorityStorageKey = 'goodin:authority';
export const settings = {
  authority: localStorage.getItem(authorityStorageKey), // this is set in the start.js
  publicPath: process.env.VUE_APP_PUBLIC_PATH,
};

export default () => config(settings);
