<template>
  <div class="d-flex flex-column">
    <div class="modal-title mb-2">
      <span>
        <i
          class="pr-1"
          :class="header.icon"
        />
        {{ header.title }} {{ $t('form.productionOrder') }}
      </span>
    </div>
    <div>
      <WorkOrder
        :extended="true"
        :order="order"
        :bom-number="bomNumber"
        :msn-code="msnCode"
      />
    </div>

    <div class="d-flex pt-2">
      <div class="col-md-6 p-2 pr-4">
        <div class="title">
          UFI code
        </div>
        <BFormInput
          :value="ufiCode || '-'"
          disabled
          trim
        />
      </div>
      <hr>
      <div class="col-md-6 vl p-2">
        <div class="title">
          EAN
        </div>
        <BFormInput
          :value="eanValue || '-'"
          disabled
          trim
        />
      </div>
    </div>

    <hr>

    <div class="d-flex pt-2">
      <div class="col-6 p-2 pr-4">
        <div class="title">
          {{ $t('form.importantInformation') }}
        </div>
        <textarea
          :value="importantInformation || '-'"
          disabled
          class="form-control flex-grow-1"
          type="text"
          rows="5"
        />
        <div class="title mt-3">
          {{ $t('line.selectLine') }}:
        </div>
        <BFormSelect
          v-model="lineId"
          :options="lineList"
          :disabled="!canLineBeEdited"
          class="mb-1 pr-4"
        />
      </div>

      <hr>

      <div class="col-6 vl p-2">
        <CheckList
          :order="order"
          :show-edit="showEdit"
          :bom-number="bomNumber"
          :msn-code="msnCode"
          :disabled="order.status !== orderStatuses.onHold"
          @listChanged="listChanged = $event"
          @checkList="$emit('checkList', $event)"
        />
        <BFormInput
          v-if="listChanged"
          v-model="checkListComment"
          :placeholder="`${$t('report.addComment')}...`"
          trim
        />
      </div>
    </div>
    <hr>
    <div class="pt-2 text-center">
      <button
        class="actions-button btn btn-secondary btn-sm mx-2"
        type="button"
        @click="$emit('close')"
      >
        <i
          class="ion ion-md-close pr-2"
        />
        {{ $t('general.cancel') }}
      </button>

      <button
        v-if="order.status === orderStatuses.validated"
        class="actions-button btn btn-primary btn-sm mx-2"
        type="submit"
        :disabled="!lineId"
        @click="$emit('update', checkListComment); $emit('close')"
      >
        <i class="ion ion-md-checkmark pr-2" />
        {{ $t('general.save') }}
      </button>

      <button
        v-if="!showEdit"
        class="actions-button btn btn-primary btn-sm mx-2"
        type="submit"
        :disabled="!lineId"
        @click="$emit('create'); $emit('close')"
      >
        <i class="ion ion-md-checkmark pr-2" />
        {{ $t('actions.create') }}
      </button>

      <button
        v-if="order.status === orderStatuses.ready"
        class="actions-button btn btn-primary btn-sm mx-2"
        type="submit"
        :disabled="!lineId"
        @click="$emit('release'); $emit('close')"
      >
        <i class="fas fa-rocket pr-2" />
        {{ $t('actions.releaseAgain') }}
      </button>

      <button
        v-if="order.status === orderStatuses.onHold || order.status === orderStatuses.validated"
        class="actions-button btn btn-primary btn-sm mx-2"
        type="submit"
        :disabled="!lineId || (!checkListComment && listChanged)"
        @click="$emit('updateAndRelease', checkListComment); $emit('close')"
      >
        <i class="fas fa-rocket pr-2" />
        {{ $t('general.updateAndRelease') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CheckList from '@/components/selectors/CheckList';
import WorkOrder from '@/components/order/WorkOrder';
import { orderStatuses } from '@/utils/dictionary';

export default {
  props: {
    order: Object,
    bomNumber: String,
    msnCode: String,
    showEdit: {
      type: Boolean,
      default: false,
    },
    canLineBeEdited: {
      type: Boolean,
      default: true,
    },
    orderBomData: Object,
    ufiCode: null,
  },
  data: () => ({
    lineId: null,
    areaId: null,
    checkListComment: null,
    listChanged: null,
    eanValue: null,
    importantInformation: null,
    orderStatuses,
  }),
  components: {
    CheckList,
    WorkOrder,
  },
  computed: {
    ...mapGetters([
      'lines',
      'areas',
      'linesFromArea',
    ]),
    lineList() {
      const linesFromArea = this.linesFromArea(this.areaId);
      return linesFromArea.map(line => ({
        text: line.name,
        value: line.id,
      })) || [];
    },
    header() {
      if (this.order.status === orderStatuses.validated || this.order.status === orderStatuses.onHold) {
        return {
          title: this.$t('actions.edit'),
          icon: 'ion ion-md-create',
        };
      }

      if (this.order.status === orderStatuses.ready) {
        return {
          title: this.$t('form.inspect'),
          icon: 'fas fa-eye',
        };
      }

      if (this.order.status === orderStatuses.inProgress) {
        return {
          title: this.$t('general.preview'),
          icon: 'fas fa-eye',
        };
      }

      return {
        title: this.$t('actions.create'),
        icon: 'fas fa-rocket',
      };
    },
  },
  watch: {
    lineId() {
      this.$emit('lineId', this.lineId);
    },
  },
  created() {
    if (this.orderBomData) {
      this.eanValue = this.orderBomData.ean;
      this.importantInformation = this.orderBomData.importantInformation;
    }
    this.lineId = this.lines
      .find(l => l.name?.toUpperCase() === this.order?.lineCode?.toUpperCase())
      ?.id;
    this.areaId = this.areas
      .find(a => a.name?.toUpperCase() === this.order?.branchPlantCode?.toUpperCase())
      ?.id;
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .title {
    font-size: 1.1em;
    font-weight: 500 !important;
  }

  .modal-title {
    font-size: 1.4em;
    font-weight: 500;
  }

  .actions-button {
    text-align: left;
    margin: 5px 0px;
    flex-grow: 1;
    font-size: 1em;
    font-weight: 500;
  }

  hr {
    border: 0;
    clear:both;
    display:block;
    width: 96%;
    background-color: #f1f1f1;
    height: 1px;
  }

  .vl {
    border-left: 1px solid #f1f1f1;
    height: 100%;
    padding-left: 20px !important;
  }

  .form-control.form-simple {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,bo
  }

  .form-control.form-simple:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }

</style>
