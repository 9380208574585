import moment from 'moment';
import lang from '@/lang';

const days = dayStart => {
  const result = [
    {
      key: 'today',
      name: `${lang.t('selectors.today')}`,
      startDate: dayStart.add(1, 'seconds').unix(),
      endDate: moment(dayStart).add(1, 'd').unix(),
    },
    {
      key: 'yesterday',
      name: `${lang.t('selectors.yesterday')}`,
      startDate: moment(dayStart).subtract(1, 'd').unix(),
      endDate: moment(dayStart).subtract(1, 'd').endOf('day').unix(),
    },
  ];

  for (let i = 2; i < 7; i += 1) {
    result.push({
      key: `day${i}`,
      name: `${moment(dayStart).subtract(i, 'd').format('ddd Do')}`,
      startDate: moment(dayStart).subtract(i, 'd').add(1, 'seconds').unix(),
      endDate: moment(dayStart).subtract(i, 'd').endOf('day').unix(),
    });
  }
  return result;
};

const weeks = dayStart => [
  {
    key: 'thisWeek',
    name: lang.t('selectors.thisWeek'),
    startDate: moment(dayStart).startOf('week').add(1, 'seconds').unix(),
    endDate: moment(dayStart).endOf('week').unix(),
  },
  {
    key: 'previousWeek',
    name: lang.t('selectors.previousWeek'),
    startDate: moment(dayStart).subtract(1, 'week').startOf('week').add(1, 'seconds')
      .unix(),
    endDate: moment(dayStart).subtract(1, 'week').endOf('week').unix(),
  },
];

const months = dayStart => [
  {
    key: 'thisMonth',
    name: lang.t('selectors.thisMonth'),
    startDate: moment(dayStart).startOf('month').add(1, 'seconds').unix(),
    endDate: moment(dayStart).endOf('month').unix(),
  },
  {
    key: 'previousMonth',
    name: lang.t('selectors.previousMonth'),
    startDate: moment(dayStart).subtract(1, 'month').startOf('month').add(1, 'seconds')
      .unix(),
    endDate: moment(dayStart).subtract(1, 'month').endOf('month').unix(),
  },
  {
    key: 'lastThreeMonths',
    name: lang.t('selectors.lastThreeMonths'),
    startDate: moment(dayStart).subtract(3, 'month').startOf('month').add(1, 'seconds')
      .unix(),
    endDate: moment(dayStart).subtract(0, 'month').endOf('month').unix(),
  },
];

export default {

  getters: {
    timeOfNotification: () => 5000,
    dayStart: () => moment().startOf('day').unix(),
    dayEnd: () => moment().endOf('day').unix(),

    allDateScopes: () => {
      const ds = moment().startOf('day');

      return [
        {
          name: lang.t('selectors.day'),
          moments: days(ds),
        },
        {
          name: lang.t('selectors.week'),
          moments: weeks(ds),
        },
        {
          name: lang.t('selectors.month'),
          moments: months(ds),
        },
      ];
    },
  },
};
