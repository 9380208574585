<template>
  <div>
    <ProSelect
      v-model="lineId"
      :options="lineOptions"
      show-all-option
      :label="$t('line.lines')"
      class="pr-3 my-3"
      icon-class="fas fa-layer-group"
      icon-color-class="app-color"
      :box-height="boxHeight"
      :light="light"
      @input="filterUpdated"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    light: Boolean,
    boxHeight: {
      type: String,
      default: '32px',
    },
  },
  data() {
    return {
      lineId: null,
    };
  },
  computed: {
    ...mapGetters([
      'lines',
      'selectedFilterLineId',
      'selectedFilterAreaId']),
    linesFilteredBySelectedArea() {
      return this.lines.filter(l => !this.selectedFilterAreaId
          || l.areaId === this.selectedFilterAreaId);
    },
    lineOptions() {
      return this.linesFilteredBySelectedArea
        .map(l => ({
          ...l,
          label: l.name,
          value: l.id,
          iconClass: 'fas fa-layer-group app-color',
        })) || [];
    },
  },
  watch: {
    selectedFilterLineId() {
      this.lineId = this.selectedFilterLineId;
    },
  },
  methods: {
    filterUpdated() {
      this.$store.commit('setFilterLineId', this.lineId);
    },
  },
  created() {
    this.lineId = this.selectedFilterLineId;
  },
};
</script>

  <style lang="scss" scoped>

  </style>
