/* eslint-disable max-len */
const monthsNominative = 'styczeń_luty_marzec_kwiecień_maj_czerwiec_lipiec_sierpień_wrzesień_październik_listopad_grudzień'.split('_');
const monthsSubjective = 'stycznia_lutego_marca_kwietnia_maja_czerwca_lipca_sierpnia_września_października_listopada_grudnia'.split('_');

const locale = {
  months(momentToFormat, format) {
    if (!momentToFormat) {
      return monthsNominative;
    }
    if (format === '') {
      // Hack: if format empty we know this is used to generate
      // RegExp by moment. Give then back both valid forms of months
      // in RegExp ready format.
      return `(${monthsSubjective[momentToFormat.month()]}|${monthsNominative[momentToFormat.month()]})`;
    }
    if (/D.+MMMM/.test(format)) {
      return monthsSubjective[momentToFormat.month()];
    }
    return monthsNominative[momentToFormat.month()];
  },
};

export default locale;
