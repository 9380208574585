<template>
  <div>
    <ProSelect
      v-model="areaId"
      :options="areasOptions"
      show-all-option
      :label="$t('selectors.branchPlant')"
      class="pr-3 my-3"
      icon-class="fas fa-border-none"
      icon-color-class="app-color"
      :box-height="boxHeight"
      :light="light"
      @input="filterUpdated"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    light: Boolean,
    boxHeight: {
      type: String,
      default: '32px',
    },
  },
  data() {
    return {
      areaId: null,
    };
  },
  computed: {
    ...mapGetters([
      'areas',
      'selectedFilterAreaId',
      'selectedFilterLineId',
      'linesFromArea']),
    areasOptions() {
      return this.areas
        .map(a => ({
          ...a,
          label: a.name,
          value: a.id,
          iconClass: 'fas fa-border-none app-color',
        })) || [];
    },
  },
  watch: {
    selectedFilterAreaId() {
      this.areaId = this.selectedFilterAreaId;
    },
  },
  methods: {
    filterUpdated() {
      this.$store.commit('setFilterAreaId', this.areaId);
      if (!this.areaId || !this.linesFromArea(this.areaId).find(l => l.id === this.selectedFilterLineId)) {
        this.$store.commit('setFilterLineId', null);
      }
    },
  },
  created() {
    this.areaId = this.selectedFilterAreaId;
  },
};
</script>

  <style lang="scss" scoped>

  </style>
