<template>
  <div class="row modal-container">
    <div class="col-8 properties">
      <div class="row justify-content-between">
        <div
          class="title m-2 py-2"
        >
          <i class="fas fa-file-import pr-2" />
          {{ $t('imports.importDetails') }}
        </div>
      </div>
      <div
        class="row"
      >
        <div class="col-12">
          <div class="my-3 d-flex justify-content-between">
            <span class="prop-text">
              GUID:
            </span>
            <span class="value-text">
              {{ selected.id || '-' }}
            </span>
          </div>

          <div class="my-3 d-flex justify-content-between">
            <span class="prop-text">
              {{ $t('imports.importType') }}
            </span>
            <span>
              <div
                :style="{ 'background-color': type.color }"
                class="badge badge-danger text-white"
              >
                <div
                  class="px-2 text-uppercase font-weight-bold"
                  style="padding-block: 1px; font-size: 0.8em; width: 100px"
                >
                  <i
                    :class="type.icon"
                    class="pr-1"
                  />
                  {{ type.text || '-' }}
                </div>
              </div>
            </span>
          </div>

          <div class="my-3 d-flex justify-content-between">
            <span class="prop-text">
              {{ $t('date.start') }}
            </span>
            <span class="value-text">
              {{ selected.created || '-' }}
            </span>
          </div>

          <div class="my-3 d-flex justify-content-between">
            <span class="prop-text">
              {{ $t('date.end') }}
            </span>
            <span class="value-text">
              {{ selected.completed || '-' }}
            </span>
          </div>

          <div class="my-3 d-flex justify-content-between">
            <span class="prop-text">
              {{ $t('time.duration') }}
            </span>
            <span class="value-text">
              {{ selected.duration || '-' }}
            </span>
          </div>

          <div class="my-3 d-flex justify-content-between">
            <span class="prop-text">
              {{ $t('imports.triggerType') }}:
            </span>
            <span class="value-text">
              {{ selected.triggerType.name || '-' }}
            </span>
          </div>

          <div class="my-3 d-flex justify-content-between">
            <span class="prop-text">
              {{ $t('imports.dataSource') }}:
            </span>
            <span class="value-text">
              {{ selected.dataSourceName || '-' }}
            </span>
          </div>

          <div class="my-3 d-flex justify-content-between">
            <span class="prop-text">
              {{ $t('general.status') }}
            </span>
            <span>
              <div
                :style="{ 'background-color': status.color }"
                class="badge badge-success text-white"
              >
                <div
                  class="px-2 text-uppercase font-weight-bold"
                  style="padding-block: 1px; font-size: 0.8em; width: 100px"
                >
                  <i
                    :class="status.icon"
                    class="pr-1"
                  />
                  {{ status.text || '-' }}
                </div>
              </div>
            </span>
          </div>

          <hr v-if="selected.triggeredByUserDetails">

          <div v-if="selected.triggeredByUserDetails">
            <div class="my-3 d-flex justify-content-between">
              <span class="prop-text">
                {{ $t('imports.requester') }}
              </span>
              <span class="value-text">
                {{ selected.triggeredByUserDetails.email || '-' }}
              </span>
            </div>

            <div class="my-3 d-flex justify-content-between">
              <span class="prop-text">
                {{ $t('users.id') }}
              </span>
              <span class="value-text">
                {{ selected.triggeredByUserDetails.id || '-' }}
              </span>
            </div>

            <div class="my-3 d-flex justify-content-between">
              <span class="prop-text">
                {{ $t('users.name') }}
              </span>
              <span
                v-if="selected.triggeredByUserDetails?.firstName && selected.triggeredByUserDetails?.lastName"
                class="value-text"
              >
                {{ selected.triggeredByUserDetails.firstName }} {{ selected.triggeredByUserDetails.lastName }}
              </span>
              <span
                v-else
                class="value-text"
              >
                {{ '-' }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="selected.allImportIssues.length"
        class="warning-box px-3 py-2 my-3"
      >
        <div class="title-style">
          <i class="fas fa-circle-exclamation" />
          {{ $t('imports.importErrors') }}:
        </div>
        <div
          class="error-list"
        >
          <div
            v-for="error in selected.allImportIssues"
            :key="error"
          >
            <div
              class="d-flex row"
            >
              <li
                class="col-2 px-0"
                style="text-align: right"
              />
              <div
                class="col-10 px-0"
                style="font-size: 0.9em"
              >
                {{ error }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="selected.validationErrors.length"
        class="warning-box px-3 py-2 my-3"
      >
        <div class="title-style">
          <i class="fas fa-triangle-exclamation" />
          {{ $t('validation.validationErrors') }}:
        </div>
        <div
          class="error-list"
        >
          <div
            v-for="error in selected.validationErrors"
            :key="error"
          >
            <div
              v-if="error"
              class="mx-1 mb-2"
              style="font-size: 0.9em"
            >
              <div
                class="mb-2"
                style="font-weight: 600"
              >
                {{ error.entityId }}:
              </div>
              <div
                v-for="err in error.validationErrors"
                :key="err"
              >
                <div
                  class="d-flex row"
                >
                  <li
                    class="col-2 px-0"
                    style="text-align: right"
                  />
                  <div class="col-10 px-0">
                    {{ err }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-4"
      style="padding-top: 40px"
    >
      <div class="mx-2 my-3">
        <div>
          <label>
            {{ $t('imports.details') }}
          </label>
        </div>
        <div
          class="payload-style"
          :style="selected.triggeredByUserDetails ? 'height: 40vh' : 'height: 30vh'"
        >
          {{ selected.details || $t('form.noData') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    selected: Object,
    type: Object,
    status: Object,
  },
  data: () => ({
    template: [],
    resendPending: false,
    details: null,
  }),
  methods: {
    ...mapActions([
      'getImportProps',
    ]),
    getImportDetails() {
      this.getImportProps({
        params: {
          importId: this.selected.id,
        },
      })
        .then(({ data }) => {
          this.details = data;
        });
    },
  },
  created() {
    this.getImportDetails();
  },
};
</script>

<style lang="scss" scoped>
  label {
    font-weight: bold;
    font-size: 0.9em;
    margin-bottom: 2px;
  }

  .title {
    font-size: 1.75em;
    font-weight: 500;
  }

  .title-style {
    font-size: 1em;
    font-weight: 500 !important;
  }

  .payload-style {
    font-size: 0.9rem;
    font-weight: 450;
    padding: 10px;
    color: #9C9C9C;
    background-color: CED3D8;
    border: 1.5px solid #ced4da;
    border-radius: 5px;
    overflow-wrap: break-word;
    overflow-y: scroll;
  }

  .error-list {
    max-height: 25vh;
    overflow-y: auto;
    padding-right: 15px;
    padding-block: 5px;
  }

  .warning-box {
    padding-block: 5px;
    margin: 10px 0px;
    border-radius: 7px;
    background-color: #FFEFEE;
    color: #D70819;
    flex-flow: row;
  }

  .info-box {
    padding: 10px;
    margin: 50px 25px;
    border-radius: 7px;
    background-color: #DDECFF;
    color: #3D69A3;
    flex-flow: row;
  }

  .vl {
    border-left: 1px solid rgba(0, 0, 0, 0.1);;
  }

  .save-buttons::v-deep .btn {
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .properties {
    background-color: white;
    border-radius: 15px;
    padding: 15px 30px;
    box-shadow: 0 0 3px rgba(100, 100, 100, 0.2);
  }

  .modal-container {
    background-color: hsla(0,0%,78.4%,.2);
    border-radius: 15px;
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .prop-text {
    font-size: 0.8rem;
    font-weight: 500;
    color: #979797;
    text-transform: capitalize;
  }

  .value-text {
    font-size: 0.8rem;
    font-weight: 500;
    color: 3F3F3F;
  }

</style>
