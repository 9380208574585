<template>
  <div>
    <div class="row no-gutters">
      <div
        v-for="el in list"
        :key="el.key"
        class="col-6 col-sm-3 col-md-auto btn-container"
      >
        <div
          class="radio-label d-flex justify-content-center align-items-center"
          :class="{
            small,
            active: el.key === selected
          }"
          @click="selected = el.key"
        >
          <i :class="el.icon" />
          <div class="pl-1 text-nowrap">
            {{ el.name }} {{ el.value }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex py-3">
      <div class="pr-4">
        <div
          class="title-style pl-1"
        >
          {{ $t('general.from') }}
        </div>
        <DateTimePicker
          id="startDateSelector"
          ref="startDateSelector"
          v-model="startDateFilter"
          :color="'#ffb033'"
          class="custom-picker mx-1"
          overlay
          no-clear-button
          enable-button-validate
          :error="datesNotValid"
          :format="pickerFormat"
          formatted="D MMM YYYY HH:mm"
          :label="$t('date.selectDateAndTime')"
          :locale="localLang || locale"
        />
      </div>
      <div class="pr-4">
        <div
          class="title-style pl-1"
        >
          {{ $t('general.to') }}
        </div>
        <DateTimePicker
          id="endDateSelector"
          ref="endDateSelector"
          v-model="endDateFilter"
          :color="'#ffb033'"
          class="custom-picker mx-1"
          overlay
          no-clear-button
          enable-button-validate
          :error="datesNotValid"
          :format="pickerFormat"
          formatted="D MMM YYYY HH:mm"
          :label="$t('date.selectDateAndTime')"
          :locale="localLang || locale"
          :right="true"
        />
      </div>
      <div class="custom-picker">
        <div
          class="title-style pl-1"
        >
          {{ $t('report.deviceName') }}
        </div>
        <div class="field">
          <select
            v-model="elementNameFilter"
            class="field-input"
            style="border: 1px solid rgba(0, 0, 0, .2);"
          >
            <option :value="null">
              {{ $t('general.all') }}
            </option>

            <option
              v-for="elementName in allElementNamesOptions"
              :key="elementName"
            >
              {{ elementName }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div
      style="overflow-y: scroll; height: 55vh"
    >
      <div
        v-for="e in rightPictures"
        :key="e.id"
        class="element-row d-flex align-items-center justify-content-between"
        @click="select(e)"
      >
        <div class="date-text">
          {{ e.timestamp }}
        </div>

        <div
          v-if="e.elementName"
          class="ml-auto mr-4 font-weight-bold small text-black-50"
        >
          {{ e.elementName }}
        </div>

        <div>
          <div
            class="badge badge-success px-2 py-1 ml-2 badge-vertical-align"
            style="border-radius: 10px; height: 20px"
            :style="{ 'background-color': e.color}"
          >
            <span>
              <i
                :class="e.icon"
                class="pr-1"
              />
              {{ selected === 'errors' ? e.qualityIssues.length : e.imageCount }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="!rightPictures.length"
        style="min-height: 30vh"
      >
        <p
          class="text-center info"
        >
          {{ $t('form.noData') }}
        </p>
      </div>
    </div>
    <LModal
      v-if="showPicture"
      :show.sync="showPicture"
      size="lg"
      style="min-height: 30vh"
    >
      <div class="justify-content-between mx-5 mt-4">
        <div
          style="font-size: 1.5em"
          class="pt-2 pb-0 font-weight-bold"
        >
          <i :class="selected === 'errors' ? 'fas fa-image' : 'fa fa-vial'" />
          {{ selected === 'errors' ? $t('report.qualityError') : $t('report.qualitySample') }}
        </div>
        <div
          class="info-text pb-1"
          style="font-size: 1.2em"
        >
          {{ selectedIncident.timestamp }}
        </div>
        <div
          v-if="selectedIncident.elementName"
          class="pb-1 badge badge-secondary"
          style="font-size: 1em"
        >
          {{ selectedIncident.elementName }}
        </div>
        <div
          class="info-text pb-1 pt-3"
          style="font-size: 0.8em"
        >
          ID: {{ selectedIncident.id }}
        </div>
        <div
          v-if="selectedIncident.qualityIssues && selected === 'errors'"
          class="text-center px-3 pb-3"
        >
          <div
            class="warning-box row px-3"
          >
            <i class="fas fa-exclamation-circle pt-1 mr-2" />
            <div style="text-align: left">
              <span class="font-weight-bold">
                {{ $t('report.qualityIssues') }}:
              </span>
              <span
                v-for="(issue, index) in selectedIncident.qualityIssues"
                :key="issue"
              >
                {{ getErrorName(issue) }}
                <span v-if="selectedIncident.qualityIssues.length !== (index + 1)">
                  ,
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!imagesPending">
        <div v-if="images.length">
          <div
            v-for="img in images"
            :key="img.cameraId"

            class="mx-5 mt-4 mb-5"
          >
            <p
              class="text-left font-weight-bold text-uppercase"
            >
              {{ `CAM ${img?.cameraId}` || $t('general.photo') }}
            </p>
            <div>
              <img
                class="image"
                :src="img?.src"
              >
            </div>
          </div>
        </div>
        <div
          v-else
          style="min-height: 25vh"
        >
          <p
            class="text-center info"
          >
            {{ $t('form.noData') }}
          </p>
        </div>
      </div>
      <Loader
        v-else
        color="goodin"
      />
    </LModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';

export default {
  props: {
    small: Boolean,
    errorsList: Array,
    samplesList: Array,
  },
  data: () => ({
    startDateFilter: null,
    endDateFilter: null,
    elementNameFilter: null,
    selected: 'errors',
    pickerFormat: 'YYYY-MM-DD HH:mm:ss',
    localLang: localStorage.lang,
    selectedIncident: null,
    showPicture: false,
    images: null,
    imagesPending: false,
    incident: null,
  }),
  computed: {
    ...mapState({
      qualityIssuesDefinitions: state => state.qualityIssues.issues,
    }),
    ...mapGetters([
      'locale',
      'settings',
    ]),
    allElementNamesOptions() {
      return [
        ...new Set([
          ...this.errorsList.map(e => e.elementName),
          ...this.samplesList.map(e => e.elementName),
        ]),
      ].sort((a, b) => a.localeCompare(b));
    },
    sortedList() {
      const epochStartTime = moment.utc(this.startDateFilter).unix() || null;
      const epochEndTime = moment.utc(this.endDateFilter).unix() || null;
      const rightList = this.selected === 'errors' ? this.errorsList : this.samplesList;
      const plantUtcOffset = moment.tz(this.settings?.plantTimeZone).utcOffset();

      const list = rightList.map(o => ({
        ...o,
        epochTime: moment(o.timestamp).add(plantUtcOffset, 'minutes').unix(),
        timestamp: moment.utc(o.timestamp).add(plantUtcOffset, 'minutes').format('DD/MM/YYYY HH:mm:ss'),
      })).sort((a, b) => b.epochTime - a.epochTime);

      if (epochStartTime && epochEndTime) {
        return list.filter(x => (x.epochTime >= epochStartTime) && (x.epochTime <= epochEndTime));
      }

      if (epochStartTime) {
        return list.filter(x => x.epochTime >= epochStartTime);
      }

      if (epochEndTime) {
        return list.filter(x => x.epochTime <= epochEndTime);
      }

      if (this.allElementNamesOptions) {
        return list
          .filter(x => {
            if (this.elementNameFilter) {
              return x.elementName === this.elementNameFilter;
            }
            return true;
          });
      }

      return list;
    },
    datesNotValid() {
      if (this.startDateFilter && this.endDateFilter) {
        return moment(this.startDateFilter)
          .isSameOrAfter(this.endDateFilter);
      }
      return false;
    },
    list() {
      return [
        {
          name: this.$t('report.errors'),
          icon: 'fas fa-image',
          key: 'errors',
          value: this.errorsList?.length,
        },
        {
          name: this.$t('report.qualityReferences'),
          icon: 'fa fa-vial',
          key: 'reference',
          value: this.samplesList?.length,
        },
      ];
    },
    rightPictures() {
      return this.sortedList?.map(e => ({
        ...e,
        icon: this.selected === 'errors' ? 'fa fa-exclamation-circle' : 'fa fa-camera',
        color: this.selected === 'errors' ? '#DD5D3E' : '#FFB033',
      }));
    },
  },
  watch: {
    selectedIncident() {
      this.getImages();
    },
  },
  methods: {
    ...mapActions([
      'getIncidentImages',
    ]),
    getErrorName(v) {
      return this.qualityIssuesDefinitions.find(issue => v === issue.code)?.name || 'Unknown error';
    },
    getImages() {
      this.imagesPending = true;
      this.images = [];

      this.getIncidentImages({
        params: {
          incidentId: this.selectedIncident.id,
        },
      })
        .then(({ data }) => {
          this.images = data.map(img => ({
            cameraId: img.cameraId,
            src: `data:image/png;base64,${img.content}`,
          }));
        })
        .finally(() => {
          this.imagesPending = false;
        });
    },
    select(item) {
      this.selectedIncident = item;
      this.showPicture = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

.title-style {
  font-weight: bold;
  font-size: 1em;
}

.info-text {
  font-weight: 500;
  opacity: 0.45;
}

.image {
  max-height: 100%;
  max-width: 100%;
  box-shadow: 0 0 4px rgba(100, 100, 100, 0.5);
}

.custom-picker::v-deep .field .field-input {
  text-align: left;
  min-height: 25px !important;
  height: 35px !important;
  font-family: inherit;
  border-radius: 2px;
  width: 175px;
  z-index: 1001;
}

.warning-box {
  padding-block: 5px;
  margin-block: 5px;
  border-radius: 7px;
  background-color: #FFEFEE;
  color: #D70819;
  flex-flow: row;
}

.element-row {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #eee;
  margin-block: 12px;
  cursor: pointer;

  &:hover {
    background-color: #bebebe;
  }
}

.info {
  opacity: 0.6;
  font-size: 1.2em;
  margin-top: 15vh;
}

.date-text {
  font-weight: 500;
  font-size: 1em;
}

.radio-label {
  text-transform: uppercase;
  font-weight: 500;
  padding: 4px 10px;
  font-size: 12px;
  min-height: 35px;
  margin-block: 10px;
  padding-right: 10px !important;
  border-radius: 10px;
  border: 2px solid rgba(100, 100, 100, 0.1);
  cursor: pointer;

  &.active {
    color: $ilabo;
    text-shadow: 0 0 2px rgba(100, 100, 100, 0.2);
    box-shadow: 0 0 2px rgba(100, 100, 100, 0.2);
    border-color: $ilabo;
  }

  i {
    font-size: 19px;
  }

  &:hover {
    box-shadow: 0 0 3px rgba(100, 100, 100, 0.4);
  }

  &.small {
    font-size: 10px;
    padding: 2px 10px;
  }
}

.btn-container {
  min-width: 140px;
  padding-right: 15px !important;
}

.field-input {
  cursor: pointer;
  background-color: #fff;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  position: relative;
  width: 100%;
  height: 42px;
  min-height: 42px;
  padding-left: 12px;
  padding-right: 44px;
  font-weight: 400;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 4px;
  font-size: 14px;
  z-index: 0;
}

</style>
