<template>
  <Layout v-if="!pagePending && accessToRoutedView">
    <RouterView />
  </Layout>
  <Loader
    v-else
    :size="50"
    color="goodin"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Layout from '@/layout/Layout';

export default {
  data: () => ({
    pagePending: true,
    accessToRoutedView: false,
  }),
  components: {
    Layout,
  },
  computed: {
    ...mapGetters(['permissionsPromise', 'canAny', 'permittedRoutes']),
    userHasAccessToApp() {
      return this.permittedRoutes.length > 0;
    },
  },
  watch: {
    $route() {
      this.checkAccessToRoutedView();
    },
  },
  methods: {
    ...mapActions([
      'getLines',
      'getAreas',
      'getSkus',
      'getSettings',
      'getUserSettings',
    ]),
    checkAccessToRoutedView() {
      this.accessToRoutedView = false;
      if (!this.$route.meta || !this.$route.meta.permittedFor) {
        this.accessToRoutedView = true;
        return;
      }

      this.permissionsPromise.then(() => {
        const accessToRoute = this.$route.meta.permittedFor.some(perm => this.canAny(perm));
        if (accessToRoute) {
          this.accessToRoutedView = true;
          return;
        }

        const firstPageWithAccess = this.permittedRoutes[0];
        if (firstPageWithAccess) {
          this.$router.push(firstPageWithAccess.route);
          this.accessToRoutedView = true;
        } else {
          this.$router.push('/noAccess');
        }
      });
    },
  },
  created() {
    this.checkAccessToRoutedView();
    this.permissionsPromise.then(() => {
      if (!this.userHasAccessToApp) {
        this.pagePending = false;
        return;
      }
      let plantLoaded = true;
      Promise.allSettled([
        this.getLines().catch(() => { plantLoaded = false; }),
        this.getAreas().catch(() => { plantLoaded = false; }),
        this.getSettings().catch(() => { plantLoaded = false; }),
        this.getSkus(),
        this.getUserSettings(),
      ]).then(() => {
        this.pagePending = false;
        if (!plantLoaded) {
          // eslint-disable-next-line no-alert
          alert(this.$t('error.appNotLoadedProperly'));
        }
      });
    });
  },
};
</script>

<style>

</style>
