<template>
  <div>
    <SimpleTable
      class="extended-table"
      :items="list"
      :fields="tableFields"
      @selected="selected = $event"
      @row-clicked="handleRowClick"
    >
      <template #cell(typeProperties)="{item}">
        <div
          :style="{ 'background-color': item.typeProperties.color }"
          class="badge badge-danger text-white"
        >
          <div
            class="px-2 text-uppercase font-weight-bold"
            style="padding-block: 1px; font-size: 1em; width: 125px"
          >
            <i
              :class="item.typeProperties.icon"
              class="pr-1"
            />
            {{ item.typeProperties.text }}
          </div>
        </div>
      </template>
      <template #cell(triggerType)="{item}">
        <div>
          {{ item.triggerType.name }}
        </div>
      </template>
      <template #cell(statusProperties)="{item}">
        <div
          :style="{ 'background-color': item.statusProperties.color }"
          class="badge badge-success text-white"
        >
          <div
            class="px-2 text-uppercase font-weight-bold"
            style="padding-block: 1px; font-size: 1em; width: 125px"
          >
            <i
              :class="item.statusProperties.icon"
              class="pr-1"
            />
            {{ item.statusProperties.text }}
          </div>
        </div>
      </template>
      <template #cell(errors)="{item}">
        <div
          v-if="item.allImportIssues.length || item.validationErrors.length"
          class="row pl-3"
        >
          <li
            style="color: #D70819"
          />
          <span v-if="item.allImportIssues.length">
            {{ $t('imports.importErrors') }}
          </span>
          <span v-if="item.validationErrors.length">
            <span v-if="item.allImportIssues.length">
              ,
            </span>
            {{ $t('validation.validationErrors') }}
          </span>
        </div>
      </template>
      <template #cell(created)="{item}">
        <div>
          {{ item.created }}
        </div>
      </template>
    </SimpleTable>
  </div>
</template>

<script>
import SimpleTable from '@/components/tables/SimpleTable';

export default {
  props: {
    list: Array,
    tableFields: Array,
  },
  data: () => ({
    selected: null,
  }),
  components: {
    SimpleTable,
  },
  methods: {
    async handleRowClick(selected, index, ev) {
      const targetClassList = ev.target.classList;
      if (
        targetClassList.contains('square-box')
        || targetClassList.contains('color-box-container')
        || targetClassList.contains('clickable')
      ) {
        return;
      }

      this.checkboxPopover = false;
      this.$emit('selected', selected);
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~@/styles/vars.icss";

  .extended-table :deep(th) {
    padding: 0 0.75rem !important;
  }

  .extended-table :deep(tr) {
    transition: 0.2s all;
    cursor: pointer;

    .color-box.square-box {
      transition: 0.2s all;
    }

    &:hover {
      background-color: #ECECEC;

      .color-box.square-box {
        background-color: #D4D4D4 !important;
      }
    }
  }

  .extended-table :deep(thead) {
    tr {
      height: 40px !important;
    }
  }

  .extended-table :deep(tbody) {
    tr {
      height: 40px;
    }
  }

  .extended-table :deep(.left-options) {
    min-width: 40px;
    max-width: 40px;
  }

  .extended-table :deep(externalId) {
    text-align: left !important;
  }

</style>
