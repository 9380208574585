import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getBoms',
    property: 'data',
    path: () => '/internal/boms/management',
  })
  .get({
    action: 'getBom',
    path: ({ bomNumber }) => `/internal/bom/${bomNumber}`,
  })
  .get({
    action: 'getDefaultChecklist',
    property: 'data',
    path: ({ bomNumber, msnCode }) => `/internal/bom/${bomNumber}/backLabel/${msnCode}/defaultQualityChecklist`,
  })
  .post({
    action: 'validateBom',
    path: ({ bomNumber }) => `/internal/bom/${bomNumber}/validate`,
  })
  .put({
    action: 'setBomProperties',
    path: ({ bomNumber }) => `/internal/bom/${bomNumber}/properties`,
  })
  .getStore();

store.getters = {
};

export default store;
