import axios from 'axios';
import { paramsSerializer, setHeaders } from './axiosSetup';

export const goodin = axios.create({ paramsSerializer });

export const init = store => {
  setHeaders(goodin, store);
};

export default goodin;
