import moment from 'moment';

const supportedLanguages = [
  {
    name: 'English',
    lang: 'en',
    flag: 'gb',
  },
  {
    name: 'Polski',
    lang: 'pl',
    flag: 'pl',
  },
];

const updateLanguage = (langCode, i18n) => {
  const supported = supportedLanguages.find(x => x.lang === langCode);
  const lang = supported?.lang || 'en';
  const locale = supported?.locale || supported?.lang || 'en';
  moment.locale(locale);
  // eslint-disable-next-line no-param-reassign
  i18n.locale = lang;
  return lang;
};

const browserLang = () => {
  const lang = navigator.language || navigator.userLanguage;
  return (lang ? lang.substring(0, 2) : null);
};

const language = localStorage.getItem('lang') || browserLang() || 'en';

export default i18n => {
  const currentLanguage = updateLanguage(language, i18n);

  return {
    state: {
      language: currentLanguage,
    },
    getters: {
      locale: s => s.language,
      language: (s, g) => g.languages.find(l => l.lang === g.locale) || g.languages[0],
      languages: () => supportedLanguages.filter(sl => Object.keys(i18n.messages).includes(sl.lang)),
    },
  };
};
