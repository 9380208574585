<template>
  <div style="position: relative; overflow: hidden">
    <!-- Main chart -->

    <Tippy
      arrow
      theme="light"
      offset="[0, 15]"
      :trigger="hideLabels ? 'manual' : 'mouseenter'"
    >
      <template #trigger>
        <BarChart
          :list="dataset"
          :hide-labels="hideLabels"
          style="width: 100%; margin-top: 2px; text-align: center"
          :style="{ height }"
        />
      </template>
    </Tippy>
  </div>
</template>

<script>
import { addSpaces } from '@core/utils/numbers';
import BarChart from '@/components/charts/BarChart';
import CalcDuration from '@/utils/calcDuration';
import productionColor from './productionColor';

export default {
  props: {
    done: Number,
    planned: String,
    wasted: Number,
    height: {
      type: String,
      default: '17px',
    },
    hideLabels: Boolean,
  },
  components: {
    BarChart,
  },
  computed: {
    doneNorm() {
      if (!this.planned) {
        return 0;
      }
      const done = this.done || 0;
      return this.planned <= done || this.planned <= 0
        ? 100
        : Math.floor((100 * done) / this.planned);
    },
    wastedNorm() {
      if (!this.wasted || !this.planned) return 0;
      if (this.planned <= (this.done + this.wasted) || this.planned <= 0) {
        return 100 - this.doneNorm;
      }
      return Math.max(0.1, Math.floor((100 * this.wasted) / this.planned));
    },
    dataset() {
      return [
        {
          color: '#75af34',
          label: this.planned
            ? `${Math.floor((this.done / this.planned) * 100)}%`
            : this.$t('monitoring.noPlan'),
          value: this.planned ? this.doneNorm : 0,
        },
        {
          color: 'rgb(237 48 40 / 90%)',
          value: this.planned ? this.wastedNorm : 0,
        },
        {
          color: '#e9e9e9',
          value: Math.max(0, 100 - (this.doneNorm + this.wastedNorm)),
        },
      ];
    },
  },
  methods: {
    productionColor,
    addSpaces,
    CalcDuration,
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/vars.icss';

</style>
