<template>
  <div>
    <div
      class="title-style"
    >
      {{ $t('time.timeRange') }}
    </div>
    <DateTimePicker
      id="rangeSelector"
      ref="rangeSelector"
      v-model="selectedRange"
      :color="'#ffb033'"
      :locale="locale"
      class="w-80 custom-picker mr-1"
      :style="`--custom-height: ${customHeight || '35px'}`"
      overlay
      no-clear-button
      enable-button-validate
      :only-date="true"
      :range="true"
      :right="true"
      format="YYYY-MM-DD"
      formatted="YYYY-MM-DD"
      :custom-shortcuts="rangeShortcuts"
      :label="label"
      @is-hidden="updateDates"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    startDate: String,
    endDate: String,
    customHeight: String,
  },
  data() {
    return {
      selectedRange: null,
    };
  },
  computed: {
    ...mapGetters(['locale', 'fromUtcToPlantTime', 'fromPlantTimeToUtc']),
    rangeShortcuts() {
      return [
        { key: 'today', label: this.$t('selectors.today'), value: 'day' },
        { key: 'thisWeek', label: this.$t('selectors.thisWeek'), value: 'isoWeek' },
        { key: 'lastWeek', label: this.$t('selectors.previousWeek'), value: '-isoWeek' },
        { key: 'thisMonth', label: this.$t('selectors.thisMonth'), value: 'month' },
        { key: 'lastMonth', label: this.$t('selectors.previousMonth'), value: '-month' },
        {
          key: 'all',
          label: this.$t('general.all'),
          value: 'day',
          callback: () => {
            this.selectedRange = {
              start: null,
              end: null,
            };
          },
        },
      ];
    },
    label() {
      if (this.selectedRange.start !== null || this.selectedRange.end !== null) {
        return '';
      }
      return this.$t('general.all');
    },
  },
  methods: {
    updateDates() {
      const toEmit = {
        startDate: this.selectedRange.start !== null
          ? this.fromPlantTimeToUtc(this.selectedRange.start)
          : null,
        endDate: this.selectedRange.end !== null
          ? this.fromPlantTimeToUtc(moment.utc(this.selectedRange.end).add(1, 'days').format())
          : null,
      };
      this.$emit('setDates', toEmit);
    },
  },
  created() {
    this.selectedRange = {
      start: this.fromUtcToPlantTime(this.startDate),
      end: this.fromUtcToPlantTime(this.endDate),
    };
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/vars.icss';

  .custom-picker {
    font-weight: normal;
  }

  .custom-picker::v-deep .field .field-input {
    margin-top: 1px;
    background-color: #F2F2F2;
    border-radius: 4px;
    padding-top: 0 !important;
    text-align: left;
    min-height: var(--custom-height) !important;
    height: var(--custom-height) !important;
    font-family: inherit;
    font-size: 0.8em;
    font-weight: 450;
    border: 0px;
    &:hover {
      background-color: #e5e5e5;
    }
    &::placeholder {
      font-size: 14px;
      color: black;
      opacity: 1;
    }
  }

  .custom-picker::v-deep .datetimepicker > div{
    width: 430px !important;
    min-width: 430px !important;
  }

  .custom-picker::v-deep .shortcuts-container{
    width: 160px;
    max-width: 160px;
    min-width: 160px;
  }

  .title-style {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #848484;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

</style>
