<template>
  <div
    class="row flex-nowrap table-container mb-3"
    style="min-height: 65vh; overflow-y: scroll"
  >
    <div class="col-6 col-md-12">
      <div>
        <div
          style="font-size: 1.6em; font-weight: 650"
          class="mt-1 mb-3"
        >
          <i class="fas fa-file-import pr-2" />
          {{ $t('imports.importLog') }}
        </div>
        <div class="d-flex">
          <div class="col-12 box-style">
            <div class="d-flex justify-content-between row">
              <div class="row ml-3">
                <ProSelect
                  v-model="importType"
                  :options="importTypesList"
                  show-all-option
                  :label="$t('imports.importType')"
                  class="pr-3 my-3"
                  :box-height="'26px'"
                  icon-class="fas fa-gears"
                  icon-color-class="app-color"
                />
                <ProSelect
                  v-model="triggerType"
                  :options="triggerTypesOptions"
                  show-all-option
                  :label="$t('imports.triggerType')"
                  class="pr-3 my-3"
                  :box-height="'26px'"
                  icon-class="fas fa-layer-group"
                  icon-color-class="app-color"
                />
                <ProSelect
                  v-model="selectedStatus"
                  :options="importStatusesOptions"
                  show-all-option
                  :label="$t('general.status')"
                  class="pr-3 my-3"
                  :box-height="'26px'"
                  icon-class="fas fa-bars-progress"
                  icon-color-class="app-color"
                />
              </div>
              <div class="pr-3 my-3">
                <DatePicker
                  ref="dateItemPicker"
                  :start-date="dates.startDate"
                  :end-date="dates.endDate"
                  :custom-height="'26px'"
                  @setDates="dates = $event"
                />
              </div>
            </div>
            <div>
              <div class="flex-fill pt-3 mb-3">
                <ImportLogTable
                  v-if="!pending && importsList"
                  :list="importsList"
                  :table-fields="columns"
                  select-many
                  @selected="setSelected($event)"
                />
                <Loader
                  v-else
                  class="pt-5"
                  style="height: 50vh"
                  color="goodin"
                />
                <div class="row justify-content-between align-items-center mx-0">
                  <span class="row-text ml-1">
                    {{ $t('form.showing') }}
                    <span style="font-weight: 600">
                      {{ 1 + currentPage*perPage - perPage }}
                      - {{ currentPage*perPage > totalRows ? totalRows : currentPage*perPage }}
                    </span>
                    {{ $t('general.of') }}
                    <span style="font-weight: 600">
                      {{ totalRows }}
                    </span>
                    {{ $t('form.results' ).toLowerCase() }}
                  </span>
                  <span class="pt-4">
                    <BPagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalRows"
                      size="sm"
                      pills
                      limit="5"
                      first-number
                      last-number
                    />
                  </span>
                  <span class="row align-items-center mr-2">
                    <span class="row-text mr-2">
                      {{ $t('form.rowsOnThePage') }}
                    </span>

                    <BFormSelect
                      v-model="perPage"
                      :options="options"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      style="width: 75px"
                      size="sm"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LModal
      :show.sync="form"
      size="lg"
    >
      <ImportLogPreviewForm
        v-if="selected"
        :selected="selected"
        :type="typeProperties(selected.type)"
        :status="statusProperties(selected.status)"
        @close="form = false"
        @reload="importsRequest()"
      />
    </LModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import tinycolor from 'tinycolor2';
import calcDuration from '@/utils/calcDuration';
import ImportLogTable from '@/components/importLog/ImportLogTable';
import ImportLogPreviewForm from '@/components/importLog/ImportLogPreviewForm';
import DatePicker from '@/components/selectors/DatePicker';

export default {
  data() {
    return {
      form: false,
      selected: null,
      pending: false,
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      options: [
        { item: 20, name: '20' },
        { item: 50, name: '50' },
        { item: 100, name: '100' },
      ],
      selectedStatus: null,
      triggerType: null,
      importType: null,
      dates: {
        startDate: null,
        endDate: null,
      },
      imports: [],
    };
  },
  components: {
    ImportLogTable,
    ImportLogPreviewForm,
    DatePicker,
  },
  computed: {
    ...mapGetters([
      'settings',
      'importTypes',
      'importStatuses',
      'triggerTypes',
      'fromPlantTimeToUtc',
    ]),
    importsList() {
      return this.imports?.map(x => ({
        ...x,
        typeProperties: this.typeProperties(x.type),
        statusProperties: this.statusProperties(x.status),
        triggerType: this.triggerProperties(x.triggerType),
        duration: x.created && x.completed ? this.calculateDuration(x.created, x.completed) : '-',
        created: x.created ? this.showRightTime(x.created) : '',
        completed: x.completed ? this.showRightTime(x.completed) : '',
        skuNumber: x.skuNumber || '-',
        labelName: x.labelName || '-',
        errors: x.validationErrors.length || x.allImportIssues.length,
        dataSourceName: x.dataSource === 'UploadedFile'
          ? this.$t('imports.uploadedFile') : this.$t('imports.externalApi'),
      }));
    },
    importTypesList() {
      return this.importTypes?.map(t => ({
        ...t,
        label: t.text,
        value: t.type,
        iconClass: t.icon,
        color: t.color,
      })) || [];
    },
    triggerTypesOptions() {
      return this.triggerTypes?.map(t => ({
        ...t,
        label: t.name,
        value: t.id,
        iconClass: t.iconName,
        color: '#ffb033',
      })) || [];
    },
    importStatusesOptions() {
      return this.importStatuses?.map(t => ({
        ...t,
        label: t.text,
        value: t.status.toLowerCase(),
        iconClass: t.icon,
        color: t.color,
      })) || [];
    },
    columns() {
      return [
        {
          key: 'typeProperties',
          label: this.$t('imports.importType'),
          class: 'text-left',
        },
        {
          key: 'triggerType',
          label: this.$t('imports.triggerType'),
          class: 'text-left',
        },
        {
          key: 'dataSourceName',
          label: this.$t('imports.dataSource'),
          class: 'text-left',
        },
        {
          key: 'statusProperties',
          label: this.$t('general.status'),
          class: 'text-left',
        },
        {
          key: 'errors',
          label: this.$t('report.errors'),
          class: 'text-left',
        },
        {
          key: 'created',
          label: this.$t('date.start'),
          class: 'text-left',
        },
      ];
    },
  },
  watch: {
    currentPage() {
      this.importsRequest();
    },
    perPage() {
      this.importsRequest();
    },
    importType() {
      this.importsRequest();
    },
    selectedStatus() {
      this.importsRequest();
    },
    triggerType() {
      this.importsRequest();
    },
    dates() {
      this.importsRequest();
    },
  },
  methods: {
    calcDuration,
    ...mapActions([
      'getImports',
    ]),
    showRightTime(date) {
      const plantUtcOffset = moment.tz(this.settings?.plantTimeZone).utcOffset();

      return moment.utc(date).add(plantUtcOffset, 'minutes').format('ll LTS');
    },
    importsRequest() {
      this.pending = true;
      const query = {};

      if (this.dates?.startDate) {
        this.$set(query, 'from', this.dates.startDate);
      }

      if (this.dates?.endDate) {
        this.$set(query, 'to', this.dates.endDate);
      }

      this.getImports({
        params: {
          query: {
            importTypes: this.importType || ['WorkOrders', 'BillOfMaterials',
              'UniqueFormulaIdentifiers', 'SingleBillOfMaterials'],
            triggerTypes: this.triggerType || ['User', 'AutoTimeScheduler'],
            page: this.currentPage - 1,
            count: this.perPage,
            statuses: this.selectedStatus || [],
            ...query,
            includeUserDetails: true,
          },
        },
      })
        .then(({ data }) => {
          this.imports = data.items;
          this.totalRows = data.totalCount;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    calculateDuration(start, end) {
      const timeDifference = new Date(end).getTime() - new Date(start).getTime();
      const duration = calcDuration(1, Math.round(timeDifference / 1000) + 1);
      if (timeDifference < 10) return `0,00${timeDifference} s`;
      if (timeDifference < 100) return `0,0${timeDifference} s`;
      if (timeDifference < 1000) return `0,${timeDifference} s`;
      return duration;
    },
    setSelected(event) {
      this.selected = event;
      this.form = true;
    },
    typeProperties(type) {
      return this.importTypes?.find(x => x.type?.toUpperCase() === type?.toUpperCase())
      || {
        type: 'UNKNOWN',
        icon: 'fas fa-exclamation-triangle',
        text: 'UNKNOWN',
        color: '#ff7448',
      };
    },
    triggerProperties(trigger) {
      return this.triggerTypes?.find(x => x.id?.toUpperCase() === trigger?.toUpperCase())
      || {
        type: 'UNKNOWN',
        icon: 'fas fa-exclamation-triangle',
        text: 'UNKNOWN',
        color: '#ff7448',
      };
    },
    statusProperties(status) {
      return this.importStatuses?.map(x => ({
        ...x,
        backgroundColor: tinycolor(x.color).setAlpha(0.2).toString(),
      }))
        ?.find(x => x.status?.toUpperCase() === status?.toUpperCase());
    },
  },
  created() {
    this.importsRequest();
  },
};
</script>

<style lang="scss" scoped>
  .table::v-deep .list-content {
    font-size: 14px;
  }

  .table-container {
    max-height: 100vh;
    height: 100vh;
    margin-top: -65px;
    padding-top: 60px;
  }

  .box-style {
    padding: 20px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
    background-color: white;
  }

  .custom-picker::v-deep .field .field-input {
    background-color: #F2F2F2 !important;
    cursor: pointer;
    width: 125px;
    min-height: 26px !important;
    height: 26px;
    font-size: 12px;
    font-weight: 625;
    border: 0px;
    padding: 4px 16px;
  }

  .custom-picker::v-deep .field .custom-button {
    background-color: #808080 !important;
    width: 12px;
    height: 12px;
    opacity: 0.9;
  }

  .custom-picker::v-deep .field .custom-button .custom-button-content {
    font-size: 5.5px !important;
    color: white !important;
    font-weight: 900 !important;
  }

  .title-style {
    margin-bottom: 0px;
    font-size: 0.8em;
    font-weight: 500;
    opacity: 0.8;
  }

  .table {
    margin-top: -50px;
    padding-top: 50px;
    height: 100%;
  }

  .row-text {
    font-size: 0.8em;
    color: #595959;
  }

</style>
