<template>
  <div
    class="bar-chart"
  >
    <div
      v-for="el in normalised"
      :key="el.id"
      v-tippy="{
        arrow: true,
        trigger: tooltipFn ? 'mouseenter' : 'manual'
      }"
      :style="{
        width: `${el.value}%`,
        left: `${el.left}%`,
        'background-color': el.color,
      }"
      class="chart-block"
      :content="tooltipFn ? tooltipFn(el) : null"
    >
      <div>
        <span
          v-if="el.value > 5 && !hideLabels"
          class="label"
        >
          {{ el.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    hideLabels: Boolean,
    tooltipFn: Function,
  },
  computed: {
    normalised() {
      const sum = this.list.reduce((acc, curr) => acc + (curr.value || 0), 0);
      return this.list
        .reduce((acc, curr) => {
          const value = (curr.value / sum) * 100;
          acc.list.push({
            ...curr,
            value,
            left: acc.soFar,
          });
          acc.soFar += value;
          return acc;
        }, { list: [], soFar: 0 }).list;
    },
  },
};
</script>

<style lang="scss" scoped>
  .bar-chart {
    position: relative;
    border-radius: 30px;
    overflow: hidden;

    .chart-block {
      position: absolute;
      top: 0;
      height: 100%;
      line-height: 17px;
      font-size: 10px;
      font-weight: normal;
      color: white;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      > div {
        padding-left: 8px;
      }
    }
  }

</style>
