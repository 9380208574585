<template>
  <div>
    <div class="row justify-content-between mx-0">
      <div
        style="font-size: 1.6em; font-weight: 650"
        class="mb-0"
      >
        <i class="fas fa-clipboard-list pr-2" />
        {{ $t('sidebar.planning') }}
      </div>
      <div
        class="pr-2"
      >
        <button
          class="btn btn-primary btn-block btn-sm"
          type="submit"
          @click="importForm = true"
        >
          <i class="fas fa-upload pr-2" />
          {{ $t('users.import') }}
        </button>
      </div>
    </div>
    <div
      class="d-flex mr-3"
      style="min-height: 70vh"
    >
      <div
        class="col-3 d-flex flex-column my-4 px-4 py-3 mr-3 ekp-box-style"
        style="max-height: 80vh"
      >
        <div>
          <EkpSelector
            class="flex-grow-1 mt-1"
            icon="fas fa-folder-open"
            :value.sync="selectedEkpId"
            :single="true"
            :searchable="['id']"
            :selected-order="selectedOrder"
            placement="bottom"
            @update:value="selectedEkpId = $event"
            @update:selected="selectedEkp = $event"
          >
            <slot>
              <span
                class="title"
                style="cursor: pointer"
              >
                <i class="fas fa-folder-open" />
                <span>
                  eKP {{ selectedEkp ? selectedEkpId : '' }}
                </span>
                <i class="fas fa-chevron-down" />
              </span>
            </slot>
          </EkpSelector>
        </div>
        <div
          v-if="selectedEkp"
          class="nice-scroll py-1"
          style="overflow-y: auto"
        >
          <hr>
          <EkpInfo
            :selected-ekp="selectedEkp"
            class="transition-item"
            @bomNumber="bomNumber = $event"
            @msnCode="msnCode = $event"
          />
        </div>
        <div
          v-else
          class="py-1 mt-1"
          style="overflow-y: auto"
        >
          <hr>
          <div class="mt-4 info-text">
            {{ $t('form.chooseOrSkanEkp') }}:
          </div>
        </div>
      </div>
      <div
        class="col-9 d-flex flex-column px-4 py-3 my-4 orders-box-style"
        style="max-height: 90vh"
      >
        <div>
          <div class="title pb-2">
            <i class="fas fa-caret-square-right pr-1" />
            {{ $t('form.wo') }} - {{ $t('form.workingOrders') }}
            <button
              class="btn actions-button btn-primary btn-sm icon-btn ml-2"
              style="text-align: center"
              @click="newWorkOrderForm = true"
            >
              <i
                class="ion ion-md-add"
              />
            </button>
          </div>
          <hr>
          <div class="d-flex">
            <div class="col-12 box-style">
              <div class="d-flex justify-content-between row">
                <div class="row ml-1">
                  <div class="pr-3 my-3">
                    <div class="title-style">
                      {{ $t('general.search') }}
                    </div>
                    <BFormInput
                      ref="search"
                      v-model="search"
                      size="sm"
                      style="min-width: 225px; height: 26px; font-size: 0.8em"
                      :placeholder="`${$t('form.searchByOrderNumber')}...`"
                      type="search"
                    />
                  </div>
                  <div
                    class="divide-line mr-3"
                  />
                  <AreaFilterSelector
                    :box-height="'26px'"
                  />
                  <LineFilterSelector
                    :box-height="'26px'"
                  />
                </div>
                <div
                  class="my-3"
                  style="margin-left: -10px;"
                >
                  <DatePicker
                    ref="dateItemPicker"
                    :start-date="dates.startDate"
                    :end-date="dates.endDate"
                    :custom-height="'26px'"
                    @setDates="dates = $event"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div
              class="box-style pb-5"
              :class="selectedOrder ? 'col-9' : 'col-12'"
            >
              <Loader
                v-if="pendingOrders"
                color="goodin"
              />
              <div>
                <div v-if="!pendingOrders">
                  <div
                    v-if="selectedOrder"
                    class="orders-list"
                    style="max-height: 50vh"
                  >
                    <WorkOrder
                      v-for="o in orders"
                      :key="o.orderNumber"
                      :order="o"
                      class="hoverable"
                      :class="o.orderNumber === selected ? 'selected' : ''"
                      @click.native="selected === o.orderNumber
                        ? selected = null : (selected = o.orderNumber)"
                    />
                  </div>
                  <div
                    v-else
                    class="orders-list"
                    style="max-height: 50vh"
                  >
                    <TransitionGroup
                      v-if="orders.length"
                      name="full-order-list"
                    >
                      <WorkOrder
                        v-for="o in orders"
                        :key="o.orderNumber"
                        :order="o"
                        class="hoverable"
                        @click.native="selected === o.orderNumber ? selected = null : (selected = o.orderNumber)"
                      />
                    </TransitionGroup>
                    <div
                      v-else
                      class="mt-5 info-text"
                      style="height: 50vh; padding-block: 20vh"
                    >
                      {{ $t('form.noData') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination-align">
              <BPagination
                v-if="totalOrdersRows"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalOrdersRows"
                size="sm"
                pills
                limit="5"
                first-number
              />
            </div>
            <div
              v-if="selectedOrder"
              class="col-3"
            >
              <div class="action-style">
                <span class="title">
                  <i class="fas fa-list pb-1" />
                  {{ $t('order.actions') }}
                </span>
                <hr>
                <div class="d-flex">
                  <button
                    class="actions-button btn btn-primary btn-sm"
                    type="submit"
                    :disabled="!msnCode || !selectedEkp || !isValidToCreate
                      || isProductionOrderAlreadyCreated
                      || pendingUfi || pendingExistingProductionOrders"
                    @click="orderForm = true"
                  >
                    <BSpinner
                      v-if="pendingCreate || pendingUfi || pendingExistingProductionOrders"
                      variant="light"
                      small
                      class="mr-1"
                    />
                    <i
                      v-else
                      class="fas fa-rocket pr-2"
                    />
                    {{ $t('actions.create') }}
                  </button>
                  <i
                    v-if="createOrderTooltipMessage"
                    v-tippy="{ arrow: true }"
                    :content="createOrderTooltipMessage"
                    class="fas fa-info-circle mx-2"
                    style="font-size: 12px"
                  />
                </div>
                <div class="d-flex">
                  <button
                    class="actions-button btn btn-outline-secondary btn-sm"
                    type="button"
                    @click="detailsForm = true"
                  >
                    <i class="fas fa-info-circle pr-2" />
                    {{ $t('actions.details') }}
                  </button>
                </div>
                <Loader
                  v-if="pendingOrders"
                  color="goodin"
                />
                <div
                  style="min-height: 20vh; overflow-y: auto"
                />
              </div>
            </div>
          </div>
          <LModal
            size="md"
            class="p-5"
            :show.sync="importForm"
          >
            <ImportModal
              @close="importForm = false"
              @refreshOrders="requestOrders"
            />
          </LModal>
          <LModal
            size="lg"
            class="p-5"
            :show.sync="orderForm"
          >
            <OrderEditAndCreate
              :order="selectedOrder"
              :bom-number="bomNumber"
              :msn-code="msnCode"
              :ufi-code="ufi?.ufiCode"
              :order-bom-data="selectedEkp?.bomOverview"
              @lineId="lineId = $event"
              @checkList="checkList = $event"
              @close="orderForm = false"
              @create="createOrder()"
            />
          </LModal>
          <LModal
            size="lg"
            class="p-5"
            :show.sync="newWorkOrderForm"
          >
            <WorkOrderForm
              @successMsg="showSuccessMsg($event)"
              @failedMsg="showFailedMsg($event)"
              @close="newWorkOrderForm = false"
            />
          </LModal>
          <LModal
            size="lg"
            class="p-5"
            :show.sync="detailsForm"
          >
            <WorkOrderDetails
              v-if="selectedOrder"
              :order="selectedOrder"
              :bom-number="bomNumber"
              :msn-code="msnCode"
            />
          </LModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import EkpSelector from '@/components/selectors/EkpSelector';
import WorkOrder from '@/components/order/WorkOrder';
import EkpInfo from '@/components/bom/EkpInfo';
import ImportModal from '@/components/bom/ImportModal';
import OrderEditAndCreate from '@/components/order/OrderEditAndCreate';
import DatePicker from '@/components/selectors/DatePicker';
import WorkOrderDetails from '@/components/order/WorkOrderDetails';
import WorkOrderForm from '@/components/order/WorkOrderForm';
import AreaFilterSelector from '@/components/selectors/AreaFilterSelector';
import LineFilterSelector from '@/components/selectors/LineFilterSelector';

export default {
  data: () => ({
    lineId: null,
    pendingOrders: false,
    selected: null,
    search: null,
    orderForm: false,
    importForm: false,
    detailsForm: false,
    workOrders: null,
    currentPage: 1,
    perPage: 30,
    totalOrdersRows: 0,
    selectedEkp: null,
    bomNumber: null,
    msnCode: null,
    selectedEkpId: null,
    ufi: null,
    pendingUfi: false,
    existingProductionOrders: [],
    pendingExistingProductionOrders: false,
    pendingCreate: false,
    checkList: null,
    newWorkOrderForm: false,
    defaultStartDateInPlantTimezone: moment.utc().subtract(1, 'days').startOf('day').format(),
    dates: {},
  }),
  components: {
    WorkOrder,
    EkpInfo,
    EkpSelector,
    OrderEditAndCreate,
    ImportModal,
    WorkOrderForm,
    WorkOrderDetails,
    DatePicker,
    AreaFilterSelector,
    LineFilterSelector,
  },
  computed: {
    ...mapGetters([
      'settings',
      'screenWidth',
      'lines',
      'lineName',
      'linesFromArea',
      'fromPlantTimeToUtc',
      'selectedFilterAreaId',
      'selectedFilterLineId',
    ]),
    filters() {
      return {
        selectedFilterAreaId: this.selectedFilterAreaId,
        selectedFilterLineId: this.selectedFilterLineId,
        dates: this.dates,
        search: this.search,
        perPage: this.perPage,
        currentPage: this.currentPage,
      };
    },
    filteredLines() {
      const selectedLines = this.linesFromArea(this.selectedFilterAreaId);
      return selectedLines.length ? selectedLines : this.lines;
    },
    orders() {
      if (!this.filteredLines || !this.workOrders) return [];

      return this.workOrders.map(order => {
        const line = this.filteredLines.find(l => l.id === order.lineId);

        return {
          ...order,
          lineName: line ? line.name : '',
          plannedExecutionStart: order.plannedExecutionStart ? this.showRightTime(order.plannedExecutionStart) : '',
          plannedExecutionEnd: order.plannedExecutionEnd ? this.showRightTime(order.plannedExecutionEnd) : '',
        };
      });
    },
    selectedOrder() {
      return this.orders?.find(x => x.orderNumber === this.selected);
    },
    isValidToCreate() {
      return (this.selectedOrder?.isValid && this.selectedEkp?.bomOverview?.isValid) || false;
    },
    isProductionOrderAlreadyCreated() {
      if (!this.selected || !this.selectedEkp?.bomOverview?.bomNumber) { return false; }
      return this.existingProductionOrders.some(po => po.workOrderNumber === this.selected
        && po.bomNumber === this.selectedEkp.bomOverview.bomNumber);
    },
    createOrderTooltipMessage() {
      if (this.isProductionOrderAlreadyCreated) return this.$t('validation.productionOrderAlreadyExists');
      if (!this.selectedEkp) return this.$t('form.chooseEkpToCreateOrder');
      if (!this.msnCode) return this.$t('form.chooseBackLabelToCreateOrder');
      if (!this.isValidToCreate) return this.$t('validation.validationErrorInfo');
      return null;
    },
  },
  watch: {
    selected() {
      this.requestProductionOrdersForSelectedWorkingOrder();
    },
    selectedEkp() {
      this.requestOrders();
      this.requestUfiForSelectedBom();
    },
    filters() {
      this.requestOrders();
    },
  },
  methods: {
    ...mapActions([
      'getWorkingOrders',
      'createProdOrder',
      'getUfiForBom',
      'getProductionOrdersFromWorkingOrder',
    ]),
    showRightTime(date) {
      const plantUtcOffset = moment.tz(this.settings?.plantTimeZone).utcOffset();

      return moment.utc(date).add(plantUtcOffset, 'minutes').format('ll LTS');
    },
    showSuccessMsg(msg) {
      this.$bvToast.toast(msg || this.$t('general.success'), {
        title: this.$t('general.success'),
        variant: 'success',
        autoHideDelay: 2000,
      });
    },
    showFailedMsg(msg) {
      this.$bvToast.toast(msg || this.$t('error.requestFailed'), {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: 4000,
      });
    },
    valueOfACheck(name) {
      return this.checkList.find(c => c.name === name)?.value;
    },
    createOrder() {
      this.pendingCreate = true;
      this.createProdOrder({
        data: {
          workOrderNumber: this.selected,
          bomNumber: this.bomNumber,
          lineCode: this.lineName(this.lineId),
          ufiCode: this.ufi?.ufiCode,
          qualityCheckList: {
            printBatchCode: this.valueOfACheck('printBatchCode'),
            validateBatchCode: this.valueOfACheck('validateBatchCode'),
            printUfi: this.valueOfACheck('printUfi'),
            validateUfi: this.valueOfACheck('validateUfi'),
            validatePrePrintedUfi: this.valueOfACheck('validatePrePrintedUfi'),
            validateEan: this.valueOfACheck('validateEan'),
            validateCap: this.valueOfACheck('validateCap'),
            validateTrigger: this.valueOfACheck('validateTrigger'),
            validateFrontLabel: this.valueOfACheck('validateFrontLabel'),
            validateBackLabel: this.valueOfACheck('validateBackLabel'),
            validateProductRotation: this.valueOfACheck('validateProductRotation'),
          },
        },
      })
        .then(({ data }) => {
          this.existingProductionOrders.push(data);
          this.orderForm = false;
          this.showSuccessMsg(this.$t('order.orderCreated'));
        })
        .catch(({ response: { data } }) => {
          this.showFailedMsg(data.detail);
        })
        .finally(() => {
          this.pendingCreate = false;
        });
    },
    requestOrders() {
      this.pendingOrders = true;
      const lines = this.selectedFilterAreaId && !this.selectedFilterLineId
        ? this.filteredLines.map(x => [x.name])
        : this.lineName(this.selectedFilterLineId);
      const query = {};

      if (this.dates?.startDate) {
        this.$set(query, 'from', this.dates.startDate);
      }

      if (this.dates?.endDate) {
        this.$set(query, 'to', this.dates.endDate);
      }

      this.getWorkingOrders({
        params: {
          query: {
            searchOrderNumber: this.search,
            page: this.currentPage - 1,
            count: this.perPage,
            lineCodes: lines || [],
            areaIds: this.selectedFilterAreaId || [],
            skuCodes: this.selectedEkp?.bomOverview?.skuCode || [],
            ...query,
          },
        },
      })
        .then(({ data }) => {
          this.workOrders = data?.items;
          this.totalOrdersRows = data.totalCount;
        })
        .finally(() => {
          this.pendingOrders = false;
        });
    },
    requestUfiForSelectedBom() {
      this.ufi = null;

      if (!this.selectedEkp) {
        return;
      }
      this.pendingUfi = true;
      this.getUfiForBom({
        params: {
          bomNumber: this.selectedEkp.bomOverview.bomNumber,
        },
      }).then(({ data }) => {
        this.ufi = data;
      }).finally(() => {
        this.pendingUfi = false;
      });
    },
    requestProductionOrdersForSelectedWorkingOrder() {
      this.existingProductionOrders = [];

      if (!this.selected) {
        return;
      }

      this.pendingExistingProductionOrders = true;

      this.getProductionOrdersFromWorkingOrder({
        params: {
          workingOrderNumber: this.selected,
        },
      })
        .then(({ data }) => {
          this.existingProductionOrders = data;
        }).finally(() => {
          this.pendingUfi = false;
          this.pendingExistingProductionOrders = false;
        });
    },
  },
  created() {
    this.dates = {
      startDate: this.fromPlantTimeToUtc(this.defaultStartDateInPlantTimezone),
      endDate: null,
    };
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .title {
    font-size: 1.3em;
    font-weight: 500;
  }

  .transition-item {
    transition: 300ms all;
    width: 100%;
    left: 0;
  }

  .nice-scroll::-webkit-scrollbar {
    display: none;
  }

  .pagination-align {
    position: absolute;
    bottom: 0px;
  }

  .box-style {
    padding-inline: 12px;
  }

  .orders-box-style {
    margin-top: 10px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .ekp-box-style {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .action-style {
    padding: 20px;
    margin-top: 10px;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .info-text {
    font-size: 1.1em;
    font-weight: 450;
    opacity: 0.7;
    text-align: center;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  .actions-button {
    text-align: left;
    margin: 5px 0px;
    flex-grow: 1;
    font-size: 1em;
    font-weight: 500;
  }

  .title-style {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #848484;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .divide-line {
    border-right: 1px solid gray;
    height: 26px;
    margin-top: 28px
  }

  .orders-list {
    padding-inline: 2px;
    overflow-y: auto;
  }

  .date-selector {
    margin-top: 15px;
    margin-bottom: 8px;
    margin-left: -15px;
  }

  .hoverable {
    cursor: pointer;
    margin-block: 10px;

    &:hover {
      background-color: rgba(200, 200, 200, 0.1);
    }

    &.selected {
      background-color: #EBEBEB;
      border-color: #333;
    }
  }
</style>
