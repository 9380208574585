<template>
  <div class="d-flex flex-column">
    <div>
      <div class="modal-title">
        <span>
          {{ $t('form.qualityChecklist') }}:
        </span>
      </div>
      <div
        class="mb-1 mt-3"
      >
        <div
          v-for="l in editedCheckList"
          :key="l.name"
          class="my-3"
        >
          <BFormCheckbox
            v-model="l.value"
            :disabled="disabled"
          >
            {{ l.name }}
          </BFormCheckbox>
        </div>
        <Loader
          v-if="!editedCheckList.length || pendingOrders"
          color="goodin"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    order: Object,
    bomNumber: String,
    msnCode: String,
    pendingOrders: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: false,
    isListChanged: null,
    checkList: {},
    editedCheckList: null,
    defaultCheckList: null,
  }),
  watch: {
    order() {
      this.rightList();
    },
    editedCheckList: {
      deep: true,
      handler(v) {
        this.isListChanged = JSON.stringify(this.editedCheckList) === JSON.stringify(this.defaultCheckList);
        this.$emit('listChanged', !this.isListChanged);
        this.$emit('checkList', v);
      },
    },
  },
  methods: {
    ...mapActions([
      'getDefaultChecklist',
    ]),
    listWithChecks(checkList) {
      const toArray = Object.entries(checkList || {});
      return toArray.map(arr => ({
        name: arr[0],
        value: arr[1],
      })).filter(x => x.value != null);
    },
    getChecklist() {
      this.getDefaultChecklist({
        params: {
          bomNumber: this.bomNumber,
          msnCode: this.msnCode,
        },
      })
        .then(({ data }) => {
          this.checkList = data;
          this.defaultCheckList = this.listWithChecks(this.checkList);
          this.editedCheckList = this.listWithChecks(this.checkList);
        });
    },
    rightList() {
      if (this.disabled && !this.showEdit) {
        this.getChecklist();
      } else {
        this.checkList = {
          printBatchCode: this.order.printBatchCode,
          validateBatchCode: this.order.validateBatchCode,
          printUfi: this.order.printUfi,
          validateUfi: this.order.validateUfi,
          validatePrePrintedUfi: this.order.validatePrePrintedUfi,
          validateEan: this.order.validateEan,
          validateCap: this.order.validateCap,
          validateTrigger: this.order.validateTrigger,
          validateFrontLabel: this.order.validateFrontLabel,
          validateBackLabel: this.order.validateBackLabel,
          validateProductRotation: this.order.validateProductRotation,
        };
        this.editedCheckList = this.listWithChecks(this.checkList);
      }
    },
  },
  created() {
    this.rightList();
    this.defaultCheckList = this.listWithChecks(this.checkList);
    this.editedCheckList = this.listWithChecks(this.checkList);
  },
};
</script>

<style lang="scss" scoped>
  .title {
    font-size: 1.2em;
    font-weight: 500;
  }

  .modal-title {
    font-size: 1.2em;
    font-weight: 500;
  }

</style>
