<template>
  <Tippy
    ref="popup"
    theme="light"
    interactive
    trigger="click"
    :distance="10"
    arrow
    placement="bottom-end"
    sticky
  >
    <template #trigger>
      <div class="text-uppercase text-white">
        {{ currentLanguage }}
      </div>
    </template>

    <div style="margin: -0.3125rem -0.5625rem">
      <div
        v-for="el in languages"
        :key="el.lang"
        class="dropdown-row clickable d-flex py-2"
        :class="{ selected: el.lang === currentLanguage }"
        @click="setLanguage(el.lang); $refs.popup.tippy().hide()"
      >
        <div class="icon mr-2 flag">
          <span
            :class="`flag-icon-${el.flag}`"
            class="flag-icon flag-icon-squared"
          />
        </div>
        <div class="flex-grow-1 text-left">
          {{ el.name }}
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  data: () => ({
    setSiteLanguage: true,
    currentSetLanguage: null,
  }),
  computed: {
    ...mapGetters(['locale', 'languages']),
    currentLanguage() {
      if (this.setSiteLanguage) {
        return this.$i18n.locale || this.locale;
      }
      return this.currentSetLanguage;
    },
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang;
      this.setSiteLanguage = false;
      this.currentSetLanguage = lang;
      localStorage.setItem('lang', lang);
      moment.locale(lang || 'en');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./dropdowns/style.scss";

  .flag-button .flag-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    line-height: 35px;
    font-size: 22px;
  }

  .flag-button .flag-icon:before {
    content: none;
  }

  .flag {
    $size: 25px;
    cursor: pointer;

    .flag-icon {
      display: block;
      float: left;
      border-radius: 50%;
      height: $size;
      width: $size;
      box-shadow: 0 0 4px rgba(100, 100, 100, 0.7);

      &:hover {
        box-shadow: 0 0 8px rgba(100, 100, 100, 0.9);
      }
    }

    &.not-active {
      opacity: 0.5;
    }
    .arrow {
      position: absolute;
      bottom: -8px;
      width: 100%;
      text-align: center;
      left: 0;
    }
  }

</style>
