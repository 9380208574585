<template>
  <div>
    <div
      style="font-size: 1.6em; font-weight: 650"
      class="mb-0"
    >
      <i class="fas fa-archive pr-2" />
      {{ $t('sidebar.archive') }}
    </div>
    <div
      class="d-flex mr-3"
      style="min-height: 70vh"
    >
      <div class="col-9 d-flex flex-column px-4 py-3 my-4 orders-box-style">
        <div>
          <div class="title pb-2">
            <i class="fas fa-caret-square-right pr-1" />
            {{ $t('form.po') }} - {{ $t('form.completedOrders') }}
          </div>
          <hr>
          <div class="d-flex">
            <div class="col-12 box-style">
              <div class="d-flex justify-content-between row">
                <div class="row ml-1">
                  <div class="pr-3 my-3">
                    <div class="title-style">
                      {{ $t('general.search') }}
                    </div>
                    <BFormInput
                      ref="search"
                      v-model="search"
                      size="sm"
                      style="min-width: 225px; height: 26px; font-size: 0.8em"
                      :placeholder="`${$t('form.searchByOrderNumber')}...`"
                      type="search"
                    />
                  </div>
                  <div
                    class="divide-line mr-3"
                  />
                  <AreaFilterSelector
                    :box-height="'26px'"
                  />
                  <LineFilterSelector
                    :box-height="'26px'"
                  />
                  <ProSelect
                    v-model="bomCode"
                    :options="bomsOptions"
                    show-all-option
                    show-search
                    :label="$t('selectors.ekpNumber')"
                    class="pr-3 my-3"
                    :box-height="'26px'"
                    icon-class="fas fa-layer-group"
                    icon-color-class="app-color"
                  />
                </div>
                <div
                  class="my-3"
                  style="margin-left: -10px;"
                >
                  <DatePicker
                    ref="dateItemPicker"
                    :start-date="dates.startDate"
                    :end-date="dates.endDate"
                    :custom-height="'26px'"
                    @setDates="dates = $event"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div
              class="box-style pb-5"
              :class="selectedOrder ? 'col-9' : 'col-12'"
            >
              <Loader
                v-if="pendingOrders"
                color="goodin"
              />
              <div v-if="!pendingOrders">
                <div
                  v-if="selectedOrder"
                  class="orders-list"
                  style="height: 50vh"
                >
                  <WorkOrder
                    v-for="o in orders"
                    :key="o.productionOrderNumber"
                    :order="o"
                    class="hoverable"
                    :class="o.productionOrderNumber === selected ? 'selected' : ''"
                    @click.native="selected === o.productionOrderNumber
                      ? selected = null : (selected = o.productionOrderNumber)"
                  />
                </div>
                <div
                  v-else
                  class="orders-list"
                  style="max-height: 50vh"
                >
                  <TransitionGroup
                    v-if="orders.length"
                    name="full-order-list"
                  >
                    <WorkOrder
                      v-for="o in orders"
                      :key="o.productionOrderNumber"
                      :order="o"
                      class="hoverable"
                      :class="o.productionOrderNumber === selected ? 'selected' : ''"
                      @click.native="selected === o.productionOrderNumber
                        ? selected = null : (selected = o.productionOrderNumber)"
                    />
                  </TransitionGroup>
                  <div
                    v-else
                    class="mt-5 info-text"
                  >
                    {{ $t('form.noData') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pagination-align">
              <BPagination
                v-if="totalOrdersRows"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalOrdersRows"
                size="sm"
                pills
                limit="5"
                first-number
              />
            </div>
            <div
              v-if="selectedOrder"
              class="col-3"
            >
              <div class="action-style">
                <span class="title">
                  <i class="fas fa-list pb-1" />
                  {{ $t('order.actions') }}
                </span>
                <hr>
                <div class="d-flex">
                  <button
                    class="actions-button btn btn-outline-secondary btn-sm"
                    type="button"
                    @click="getReport()"
                  >
                    <BSpinner
                      v-if="pendingReport"
                      variant="dark"
                      small
                      class="mr-1"
                    />
                    <i
                      v-else
                      class="fas fa-clipboard-check pr-2"
                    />
                    {{ $t('actions.reviewReport') }}
                  </button>
                </div>
                <hr>
                <div
                  style="height: 25vh; overflow-y: auto"
                >
                  <CheckList
                    :show-edit="true"
                    :order="selectedOrder"
                    :pending-orders="pendingOrders"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-3 d-flex flex-column my-4 px-4 py-3 ml-3 wo-box-style"
        style="max-height: 80vh"
      >
        <div class="title mt-1">
          <i class="fas fa-info-circle pr-1" />
          {{ $t('form.po') }} - {{ $t('general.details') }}
        </div>
        <div
          v-if="selectedOrder"
          class="py-1"
          style="overflow-y: auto"
        >
          <ProductionOrdersDetails
            :order="selectedOrder"
            :order-bom-data="orderBomData"
            class="transition-item"
          />
        </div>
        <div
          v-else
          class="py-1 mt-1"
          style="overflow-y: auto"
        >
          <hr>
          <div class="mt-4 info-text">
            {{ $t('form.selectOrderToSeeTheDetails') }}
          </div>
        </div>
      </div>
    </div>
    <LModal
      v-if="showReport"
      :show.sync="showReport"
      size="lg"
    >
      <SummaryReport
        v-bind.sync="reportData"
        :order="selectedOrder"
      />
    </LModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import SummaryReport from '@/components/reports/SummaryReport';
import WorkOrder from '@/components/order/WorkOrder';
import CheckList from '@/components/selectors/CheckList';
import ProductionOrdersDetails from '@/components/order/ProductionOrdersDetails';
import DatePicker from '@/components/selectors/DatePicker';
import AreaFilterSelector from '@/components/selectors/AreaFilterSelector';
import LineFilterSelector from '@/components/selectors/LineFilterSelector';
import { orderStatuses, sortDirections } from '@/utils/dictionary';

export default {
  data: () => ({
    lineId: Number(localStorage.lineId),
    pending: false,
    selected: null,
    selectedForm: null,
    search: '',
    showReport: false,
    pendingOrders: false,
    currentPage: 1,
    perPage: 20,
    totalOrdersRows: 0,
    completedOrders: [],
    dates: {
      startDate: null,
      endDate: null,
    },
    bomCode: null,
    boms: [],
    orderBomData: {},
    reportData: null,
    pendingReport: false,
    orderStatuses,
  }),
  components: {
    WorkOrder,
    ProductionOrdersDetails,
    CheckList,
    SummaryReport,
    DatePicker,
    AreaFilterSelector,
    LineFilterSelector,
  },
  computed: {
    ...mapGetters([
      'skus',
      'lines',
      'lineName',
      'linesFromArea',
      'settings',
      'timeOfNotification',
      'fromPlantTimeToUtc',
      'selectedFilterAreaId',
      'selectedFilterLineId',
    ]),
    filteredLines() {
      const selectedLines = this.linesFromArea(this.selectedFilterAreaId);
      return selectedLines.length ? selectedLines : this.lines;
    },
    bomsOptions() {
      return this.boms
        ?.map(b => ({
          ...b,
          label: b.name,
          value: b.id,
          iconClass: 'fas fa-layer-group app-color',
        })) || [];
    },
    selectedOrder() {
      return this.orders.find(x => x.productionOrderNumber === this.selected);
    },
    orders() {
      if (!this.filteredLines || !this.completedOrders) return [];

      return this.completedOrders.map(order => {
        const line = this.filteredLines.find(l => l.id === order.lineId);

        return {
          ...order,
          lineName: line ? line.name : '',
          created: order.created ? this.showRightTime(order.created) : '',
          updated: order.updated ? this.showRightTime(order.updated) : '',
          lastStatusUpdate: order.lastStatusUpdate ? this.showRightTime(order.lastStatusUpdate) : '',
          plannedExecutionStart: order.plannedExecutionStart ? this.showRightTime(order.plannedExecutionStart) : '',
          plannedExecutionEnd: order.plannedExecutionEnd ? this.showRightTime(order.plannedExecutionEnd) : '',
        };
      });
    },
    filters() {
      return {
        selectedFilterAreaId: this.selectedFilterAreaId,
        selectedFilterLineId: this.selectedFilterLineId,
        dates: this.dates,
        search: this.search,
        bomCode: this.bomCode,
        perPage: this.perPage,
        currentPage: this.currentPage,
      };
    },
  },
  watch: {
    selectedOrder() {
      this.getData();
    },
    filters() {
      this.requestOrders();
    },
  },
  methods: {
    ...mapActions([
      'getProductionOrders',
      'getBomsForProdOrders',
      'getOrderReport',
      'getQualityIssues',
      'getBom',
    ]),
    getData() {
      if (this.selectedOrder?.bomNumber) {
        this.getBom({
          params: {
            bomNumber: this.selectedOrder.bomNumber,
          },
        })
          .then(({ data }) => {
            this.orderBomData = data;
          });
      }
    },
    getReport() {
      this.pendingReport = true;
      this.getOrderReport({
        params: {
          orderNumber: this.selectedOrder.productionOrderNumber,
        },
      })
        .then(({ data }) => {
          this.reportData = data;
          this.showReport = true;
        })
        .catch(({ response: { data } }) => {
          this.$bvToast.toast(data.detail || this.$t('report.reportGenerationError'), {
            title: this.$t('general.error'),
            variant: 'danger',
            autoHideDelay: 4000,
          });
        })
        .finally(() => {
          this.pendingReport = false;
        });
    },
    showRightTime(date) {
      const plantUtcOffset = moment.tz(this.settings?.plantTimeZone).utcOffset();
      return moment.utc(date).add(plantUtcOffset, 'minutes').format('ll LTS');
    },
    setSelected(event) {
      this.selectedForm = event;
    },
    requestBoms() {
      this.getBomsForProdOrders({
        params: {
          query: {
            orderStatuses: [
              orderStatuses.completed,
            ],
          },
        },
      })
        .then(({ data }) => {
          this.boms = data.map(x => ({ id: x, name: x })) || [];
        });
    },
    requestOrders() {
      this.pendingOrders = true;
      const lines = this.selectedFilterAreaId && !this.selectedFilterLineId
        ? this.filteredLines.map(x => [x.name])
        : this.lineName(this.selectedFilterLineId);
      const query = {};

      if (this.dates?.startDate) {
        this.$set(query, 'from', this.dates.startDate);
      }

      if (this.dates?.endDate) {
        this.$set(query, 'to', this.dates.endDate);
      }

      this.getProductionOrders({
        params: {
          query: {
            searchOrderNumber: this.search,
            page: this.currentPage - 1,
            count: this.perPage,
            sortDirection: sortDirections.descending,
            lineCodes: lines || [],
            areaIds: this.selectedFilterAreaId || [],
            statuses: orderStatuses.completed,
            bomCodes: this.bomCode || [],
            ...query,
          },
        },
      })
        .then(({ data }) => {
          this.completedOrders = data?.items;
          this.totalOrdersRows = data.totalCount;
        })
        .finally(() => {
          this.pendingOrders = false;
        });
    },
  },
  created() {
    this.requestOrders();
    this.requestBoms();
    this.getQualityIssues();
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .title {
    font-size: 1.2em;
    font-weight: 500;
  }

  .transition-item {
    transition: 300ms all;
    width: 100%;
    left: 0;
  }

  .box-style {
    padding-inline: 12px;
  }

  .orders-box-style {
    margin-top: 10px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .wo-box-style {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .action-style {
    padding: 20px;
    margin-top: 10px;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .info-text {
    font-size: 1.1em;
    font-weight: 450;
    opacity: 0.7;
    text-align: center;
  }

  .title-style {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #848484;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .divide-line {
    border-right: 1px solid gray;
    height: 26px;
    margin-top: 28px
  }

  .date-selector {
    margin-top: 15px;
    margin-bottom: 8px;
    margin-left: -15px;
  }

  .pagination-align {
    position: absolute;
    bottom: 0px;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  .actions-button {
    text-align: left;
    margin: 5px 0px;
    flex-grow: 1;
    font-size: 1em;
    font-weight: 500;
  }

  .orders-list {
    padding-inline: 2px;
    overflow-y: auto;
  }

  .hoverable {
    cursor: pointer;
    margin-block: 10px;

    &:hover {
      background-color: rgba(200, 200, 200, 0.1);
    }

    &.selected {
      background-color: #EBEBEB;
      border-color: #333;
    }
  }
</style>
